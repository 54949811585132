export class UserNotificationDeletedReadModel {
  static getAttributeTypeMap() {
    return UserNotificationDeletedReadModel.attributeTypeMap;
  }
  constructor(e, t, a, i, s, r, d, m, n, o, y, l, p) {
    this.id = e, this.userSub = t, this.message = a, this.messageType = i, this.relatedEntityId = s, this.relatedEntityType = r, this.senderSub = d, this.isRead = m, this.isArchived = n, this.isFavorite = o, this.deletedAt = y, this.createdAt = l, this.additionalDetails = p;
  }
}
UserNotificationDeletedReadModel.discriminator = void 0, UserNotificationDeletedReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "message",
  baseName: "message",
  type: "string",
  format: ""
}, {
  name: "messageType",
  baseName: "messageType",
  type: "string",
  format: ""
}, {
  name: "relatedEntityId",
  baseName: "relatedEntityId",
  type: "string",
  format: ""
}, {
  name: "relatedEntityType",
  baseName: "relatedEntityType",
  type: "string",
  format: ""
}, {
  name: "senderSub",
  baseName: "senderSub",
  type: "string",
  format: ""
}, {
  name: "isRead",
  baseName: "isRead",
  type: "boolean",
  format: ""
}, {
  name: "isArchived",
  baseName: "isArchived",
  type: "boolean",
  format: ""
}, {
  name: "isFavorite",
  baseName: "isFavorite",
  type: "boolean",
  format: ""
}, {
  name: "deletedAt",
  baseName: "deletedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}, {
  name: "additionalDetails",
  baseName: "additionalDetails",
  type: "{ [key: string]: string; }",
  format: ""
}];