export class DeleteShipmentDirectory {
  static getAttributeTypeMap() {
    return DeleteShipmentDirectory.attributeTypeMap;
  }
  constructor(e) {
    this.shipmentId = e;
  }
}
DeleteShipmentDirectory.discriminator = void 0, DeleteShipmentDirectory.attributeTypeMap = [{
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}];