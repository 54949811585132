export class CreateTenant {
  static getAttributeTypeMap() {
    return CreateTenant.attributeTypeMap;
  }
  constructor(t, e) {
    this.id = t, this.name = e;
  }
}
CreateTenant.discriminator = void 0, CreateTenant.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}];