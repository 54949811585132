export class CommandAcceptedResult {
  static getAttributeTypeMap() {
    return CommandAcceptedResult.attributeTypeMap;
  }
  constructor(t) {
    this.entityId = t;
  }
}
CommandAcceptedResult.discriminator = void 0, CommandAcceptedResult.attributeTypeMap = [{
  name: "entityId",
  baseName: "entityId",
  type: "string",
  format: ""
}];