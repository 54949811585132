export class UpdateMyProfile {
  static getAttributeTypeMap() {
    return UpdateMyProfile.attributeTypeMap;
  }
  constructor(e, a, t, i, n, r, m, s, o, p, d, f, y, l, b, u, h) {
    this.firstname = e, this.lastname = a, this.salutation = t, this.jobTitle = i, this.email = n, this.mobilePhone = r, this.officePhone = m, this.primaryWorkLocation = s, this.linkedIn = o, this.timeZone = p, this.language = d, this.birthday = f, this.about = y, this.primaryTWMUserId = l, this.secondaryTWMUserId = b, this.defaultApplicationTenantId = u, this.defaultCustomerTenantId = h;
  }
}
UpdateMyProfile.discriminator = void 0, UpdateMyProfile.attributeTypeMap = [{
  name: "firstname",
  baseName: "firstname",
  type: "string",
  format: ""
}, {
  name: "lastname",
  baseName: "lastname",
  type: "string",
  format: ""
}, {
  name: "salutation",
  baseName: "salutation",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "mobilePhone",
  baseName: "mobilePhone",
  type: "string",
  format: ""
}, {
  name: "officePhone",
  baseName: "officePhone",
  type: "string",
  format: ""
}, {
  name: "primaryWorkLocation",
  baseName: "primaryWorkLocation",
  type: "string",
  format: ""
}, {
  name: "linkedIn",
  baseName: "linkedIn",
  type: "string",
  format: ""
}, {
  name: "timeZone",
  baseName: "timeZone",
  type: "string",
  format: ""
}, {
  name: "language",
  baseName: "language",
  type: "string",
  format: ""
}, {
  name: "birthday",
  baseName: "birthday",
  type: "Date",
  format: "date-time"
}, {
  name: "about",
  baseName: "about",
  type: "string",
  format: ""
}, {
  name: "primaryTWMUserId",
  baseName: "primaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "secondaryTWMUserId",
  baseName: "secondaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "defaultApplicationTenantId",
  baseName: "defaultApplicationTenantId",
  type: "string",
  format: "uuid"
}, {
  name: "defaultCustomerTenantId",
  baseName: "defaultCustomerTenantId",
  type: "string",
  format: "uuid"
}];