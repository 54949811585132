export class MarkChatAsUnread {
  static getAttributeTypeMap() {
    return MarkChatAsUnread.attributeTypeMap;
  }
  constructor(a) {
    this.channelId = a;
  }
}
MarkChatAsUnread.discriminator = void 0, MarkChatAsUnread.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}];