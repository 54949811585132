export class FileName {
  static getAttributeTypeMap() {
    return FileName.attributeTypeMap;
  }
  constructor(e, t) {
    this.name = e, this.tags = t;
  }
}
FileName.discriminator = void 0, FileName.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}];