export class RegisterExternalInvoice {
  static getAttributeTypeMap() {
    return RegisterExternalInvoice.attributeTypeMap;
  }
  constructor(e, t, a, i, n, s, r, m, o, c, u, b) {
    this.customerId = e, this.invoiceId = t, this.business = a, this.costAllocation = i, this.customerName = n, this.fileNumber = s, this.invoiceDate = r, this.dueDate = m, this.period = o, this.currency = c, this.invoiceBalance = u, this.outstandingBalance = b;
  }
}
RegisterExternalInvoice.discriminator = void 0, RegisterExternalInvoice.attributeTypeMap = [{
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}, {
  name: "invoiceId",
  baseName: "invoiceId",
  type: "string",
  format: ""
}, {
  name: "business",
  baseName: "business",
  type: "string",
  format: ""
}, {
  name: "costAllocation",
  baseName: "costAllocation",
  type: "string",
  format: ""
}, {
  name: "customerName",
  baseName: "customerName",
  type: "string",
  format: ""
}, {
  name: "fileNumber",
  baseName: "fileNumber",
  type: "string",
  format: ""
}, {
  name: "invoiceDate",
  baseName: "invoiceDate",
  type: "Date",
  format: "date-time"
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "period",
  baseName: "period",
  type: "string",
  format: ""
}, {
  name: "currency",
  baseName: "currency",
  type: "string",
  format: ""
}, {
  name: "invoiceBalance",
  baseName: "invoiceBalance",
  type: "number",
  format: "double"
}, {
  name: "outstandingBalance",
  baseName: "outstandingBalance",
  type: "number",
  format: "double"
}];