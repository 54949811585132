export class UnarchiveChatChannel {
  static getAttributeTypeMap() {
    return UnarchiveChatChannel.attributeTypeMap;
  }
  constructor(a) {
    this.channelId = a;
  }
}
UnarchiveChatChannel.discriminator = void 0, UnarchiveChatChannel.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}];