export class MakeAddressPublic {
  static getAttributeTypeMap() {
    return MakeAddressPublic.attributeTypeMap;
  }
  constructor(e) {
    this.id = e;
  }
}
MakeAddressPublic.discriminator = void 0, MakeAddressPublic.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];