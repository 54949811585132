export class SetShipmentImportance {
  static getAttributeTypeMap() {
    return SetShipmentImportance.attributeTypeMap;
  }
  constructor(t, e) {
    this.nvxId = t, this.importance = e;
  }
}
SetShipmentImportance.discriminator = void 0, SetShipmentImportance.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "importance",
  baseName: "importance",
  type: "string",
  format: ""
}];