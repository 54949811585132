export class MakeOrganizationPublic {
  static getAttributeTypeMap() {
    return MakeOrganizationPublic.attributeTypeMap;
  }
  constructor(i) {
    this.id = i;
  }
}
MakeOrganizationPublic.discriminator = void 0, MakeOrganizationPublic.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];