export class UserProfileReadModel {
  static getAttributeTypeMap() {
    return UserProfileReadModel.attributeTypeMap;
  }
  constructor(e, a, t, i, r, s, n, m, o, d, f, p, l, b, u, y, h, g, c, N, I) {
    this.id = e, this.userSub = a, this.firstname = t, this.lastname = i, this.salutation = r, this.jobTitle = s, this.email = n, this.mobilePhone = m, this.officePhone = o, this.primaryWorkLocation = d, this.linkedIn = f, this.timeZone = p, this.language = l, this.birthday = b, this.about = u, this.primaryTWMUserId = y, this.secondaryTWMUserId = h, this.profilePictureId = g, this.defaultApplicationTenantId = c, this.defaultCustomerTenantId = N, this.createdAt = I;
  }
}
UserProfileReadModel.discriminator = void 0, UserProfileReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "firstname",
  baseName: "firstname",
  type: "string",
  format: ""
}, {
  name: "lastname",
  baseName: "lastname",
  type: "string",
  format: ""
}, {
  name: "salutation",
  baseName: "salutation",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "mobilePhone",
  baseName: "mobilePhone",
  type: "string",
  format: ""
}, {
  name: "officePhone",
  baseName: "officePhone",
  type: "string",
  format: ""
}, {
  name: "primaryWorkLocation",
  baseName: "primaryWorkLocation",
  type: "string",
  format: ""
}, {
  name: "linkedIn",
  baseName: "linkedIn",
  type: "string",
  format: ""
}, {
  name: "timeZone",
  baseName: "timeZone",
  type: "string",
  format: ""
}, {
  name: "language",
  baseName: "language",
  type: "string",
  format: ""
}, {
  name: "birthday",
  baseName: "birthday",
  type: "Date",
  format: "date-time"
}, {
  name: "about",
  baseName: "about",
  type: "string",
  format: ""
}, {
  name: "primaryTWMUserId",
  baseName: "primaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "secondaryTWMUserId",
  baseName: "secondaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "profilePictureId",
  baseName: "profilePictureId",
  type: "string",
  format: "uuid"
}, {
  name: "defaultApplicationTenantId",
  baseName: "defaultApplicationTenantId",
  type: "string",
  format: "uuid"
}, {
  name: "defaultCustomerTenantId",
  baseName: "defaultCustomerTenantId",
  type: "string",
  format: "uuid"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];