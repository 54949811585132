export class RemoveContactPersonFromShipment {
  static getAttributeTypeMap() {
    return RemoveContactPersonFromShipment.attributeTypeMap;
  }
  constructor(t, e, a, n, o, i) {
    this.nvxId = t, this.type = e, this.organizationId = a, this.locationId = n, this.addressId = o, this.contactId = i;
  }
}
RemoveContactPersonFromShipment.discriminator = void 0, RemoveContactPersonFromShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "type",
  baseName: "type",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "contactId",
  baseName: "contactId",
  type: "string",
  format: "uuid"
}];