export * from "../models/ActiveRoadShipmentDraft";
export * from "../models/ActiveRoadShipmentDraftPageResult";
export * from "../models/AddAddressToLocation";
export * from "../models/AddCustomerReferenceToShipment";
export * from "../models/AddCustomerToTenant";
export * from "../models/AddExternalSystemReferenceToDirectoryLocation";
export * from "../models/AddLocationExternalSystemReferences";
export * from "../models/AddOrganizationByEmail";
export * from "../models/AddToTenant";
export * from "../models/AddTypeToOrganization";
export * from "../models/AddUserToChannel";
export * from "../models/AdditionalRolesReadModel";
export * from "../models/AdditionalRolesReadModelPageResult";
export * from "../models/AdminAddressReadModel";
export * from "../models/AdminAddressReadModelPageResult";
export * from "../models/AdminOrganizationReadModel";
export * from "../models/AdminOrganizationReadModelPageResult";
export * from "../models/ArchiveChatChannel";
export * from "../models/AssignApplicationRole";
export * from "../models/AssignContactPersonToOrganization";
export * from "../models/AssignContactPersonToShipment";
export * from "../models/AssignDocumentToShipment";
export * from "../models/AssignLoadingLocationToShipment";
export * from "../models/AssignTenantRole";
export * from "../models/AssignUnloadingLocationToShipment";
export * from "../models/AssignUserToContactPerson";
export * from "../models/AssignUserToShipment";
export * from "../models/AssignedUserReference";
export * from "../models/AttachedFile";
export * from "../models/BillOfLading";
export * from "../models/BillOfLadingBookmark";
export * from "../models/CarrierContractReadModel";
export * from "../models/CarrierContractReadModelPageResult";
export * from "../models/CarrierOrganizationReadModel";
export * from "../models/CarrierOrganizationReadModelPageResult";
export * from "../models/ChangeScheduleStatus";
export * from "../models/ChatChannel";
export * from "../models/ChatChannelMessage";
export * from "../models/ChatChannelReadModel";
export * from "../models/ChatChannelReadModelPageResult";
export * from "../models/Co2Emissions";
export * from "../models/CommandAcceptedResult";
export * from "../models/CompleteRoadShipmentDraft";
export * from "../models/CompleteShipmentDraft";
export * from "../models/ContactPersonReadModel";
export * from "../models/ContactPersonReadModelPageResult";
export * from "../models/ContactPersonWithAssignedUserReadModel";
export * from "../models/ContactPersonWithAssignedUserReadModelPageResult";
export * from "../models/ContainerEvent";
export * from "../models/ContainerJourneyReadModel";
export * from "../models/ContainerJourneyReadModelPageResult";
export * from "../models/ContainerRate";
export * from "../models/ContainerTypeReference";
export * from "../models/ContractOwner";
export * from "../models/CreateAdditionalRole";
export * from "../models/CreateAddress";
export * from "../models/CreateCarrierContract";
export * from "../models/CreateChatChannel";
export * from "../models/CreateContactPerson";
export * from "../models/CreateLocation";
export * from "../models/CreateMilestone";
export * from "../models/CreateOrganization";
export * from "../models/CreateOrganizationDirectory";
export * from "../models/CreateSchedule";
export * from "../models/CreateShipment";
export * from "../models/CreateShipmentDirectory";
export * from "../models/CreateShipmentGoods";
export * from "../models/CreateTenant";
export * from "../models/CreateTransshipment";
export * from "../models/CustomerReadModel";
export * from "../models/CustomerReadModelPageResult";
export * from "../models/CustomersReadModel";
export * from "../models/CustomersReadModelPageResult";
export * from "../models/DeleteAddress";
export * from "../models/DeleteCarrierContract";
export * from "../models/DeleteChatMessage";
export * from "../models/DeleteContactPerson";
export * from "../models/DeleteLocation";
export * from "../models/DeleteMilestone";
export * from "../models/DeleteOrganization";
export * from "../models/DeleteOrganizationDirectory";
export * from "../models/DeleteSchedule";
export * from "../models/DeleteShipmentDirectory";
export * from "../models/DeleteShipmentDocument";
export * from "../models/DeleteTransshipment";
export * from "../models/DisableTenant";
export * from "../models/EditChatMessage";
export * from "../models/EnableTenant";
export * from "../models/EnterInviteUser";
export * from "../models/EnterShipmentPriority";
export * from "../models/ErrorResponse";
export * from "../models/ExternalInvoiceReadModel";
export * from "../models/ExternalInvoiceReadModelPageResult";
export * from "../models/ExternalSystemReference";
export * from "../models/FailedNvxUpdateReadModel";
export * from "../models/FailedNvxUpdateReadModelPageResult";
export * from "../models/FileName";
export * from "../models/InitiateCustomerFullSync";
export * from "../models/JourneyEvent";
export * from "../models/JourneyEventDisposition";
export * from "../models/JourneyEventLocationType";
export * from "../models/LeaveChatChannel";
export * from "../models/LocationExternalSystemReferences";
export * from "../models/LocationReadModel";
export * from "../models/LocationReadModelPageResult";
export * from "../models/MBLPaymentTerm";
export * from "../models/MakeAddressPrivate";
export * from "../models/MakeAddressPublic";
export * from "../models/MakeLocationPrivate";
export * from "../models/MakeLocationPublic";
export * from "../models/MakeOrganizationPrivate";
export * from "../models/MakeOrganizationPublic";
export * from "../models/MarkChatAsRead";
export * from "../models/MarkChatAsUnread";
export * from "../models/MilestoneReadModel";
export * from "../models/MilestoneReadModelPageResult";
export * from "../models/MyUserProfileReadModel";
export * from "../models/MyUserProfileReadModelPageResult";
export * from "../models/NVXShipmentJuncture";
export * from "../models/NVXShipmentJuncturePortTypeEnum";
export * from "../models/NVXShipmentJunctureSourceEnum";
export * from "../models/NVXShipmentJunctureStageEnum";
export * from "../models/NVXShipmentJunctureStatusEnum";
export * from "../models/NVXShipmentJunctureTypeEnum";
export * from "../models/NotificationArchive";
export * from "../models/NotificationDelete";
export * from "../models/NotificationMarkAsFavorite";
export * from "../models/NotificationMarkAsRead";
export * from "../models/NotificationMarkAsUnfavorite";
export * from "../models/NotificationMarkAsUnread";
export * from "../models/NotificationRestore";
export * from "../models/NvxReceivedProcessedReadModel";
export * from "../models/NvxReceivedProcessedReadModelPageResult";
export * from "../models/NvxUpdate";
export * from "../models/NvxUpdateAdditionalInformation";
export * from "../models/NvxUpdateAdditionalInformationLog";
export * from "../models/OrganizationContactPerson";
export * from "../models/OrganizationDirectoryReadModel";
export * from "../models/OrganizationDirectoryReadModelPageResult";
export * from "../models/OrganizationReadModel";
export * from "../models/OrganizationReadModelPageResult";
export * from "../models/Position";
export * from "../models/PublicAddressReadModel";
export * from "../models/PublicAddressReadModelPageResult";
export * from "../models/PublicLocationReadModel";
export * from "../models/PublicLocationReadModelPageResult";
export * from "../models/PublicUserProfileReadModel";
export * from "../models/PublicUserProfileReadModelPageResult";
export * from "../models/ReadModelResetResult";
export * from "../models/ReadModelShipmentDocument";
export * from "../models/ReceiveNewSignup";
export * from "../models/RegisterContainerJourney";
export * from "../models/RegisterExternalInvoice";
export * from "../models/RejectAddress";
export * from "../models/RemoveAdditionalRole";
export * from "../models/RemoveContactPersonFromShipment";
export * from "../models/RemoveCustomerFromTenant";
export * from "../models/RemoveCustomerReferenceFromShipment";
export * from "../models/RemoveExternalSystemReferenceFromDirectoryLocation";
export * from "../models/RemoveFromTenant";
export * from "../models/RemoveGoodsFromShipment";
export * from "../models/RemoveLocationExternalSystemReferences";
export * from "../models/RemoveShipmentLoadingLocation";
export * from "../models/RemoveShipmentUnloadingLocation";
export * from "../models/RemoveTypeFromOrganization";
export * from "../models/RemoveUserFromShipment";
export * from "../models/RemoveValidationRule";
export * from "../models/RenameTenant";
export * from "../models/RestoreAddress";
export * from "../models/RestoreCarrierContract";
export * from "../models/RestoreLocation";
export * from "../models/RestoreOrganization";
export * from "../models/RestoreOrganizationDirectory";
export * from "../models/RestoreShipmentDirectory";
export * from "../models/RevokeApplicationRole";
export * from "../models/RevokeTenantRole";
export * from "../models/RoadShipmentDraftSubscribe";
export * from "../models/RoadShipmentReadModel";
export * from "../models/RoadShipmentReadModelPageResult";
export * from "../models/RoadShipmentSubscribe";
export * from "../models/SaveRoadShipmentDraft";
export * from "../models/ScheduleChangeEntry";
export * from "../models/ScheduleReadModel";
export * from "../models/ScheduleReadModelPageResult";
export * from "../models/ScheduleStatus";
export * from "../models/SeaShipmentReadModel";
export * from "../models/SeaShipmentReadModelPageResult";
export * from "../models/SendChatMessage";
export * from "../models/SetNotificationSetting";
export * from "../models/SetShipmentCargoReadyDate";
export * from "../models/SetShipmentDestination";
export * from "../models/SetShipmentImportance";
export * from "../models/SetShipmentOrigin";
export * from "../models/SetShipmentOriginEta";
export * from "../models/SetShipmentPlaceOfDelivery";
export * from "../models/SetShipmentPlaceOfReceipt";
export * from "../models/SetShipmentRemarks";
export * from "../models/SetShipmentStackability";
export * from "../models/SetShipmentStatus";
export * from "../models/SetValidationRule";
export * from "../models/ShipmentAddress";
export * from "../models/ShipmentAssignedUser";
export * from "../models/ShipmentContact";
export * from "../models/ShipmentContactPerson";
export * from "../models/ShipmentContainer";
export * from "../models/ShipmentDirectoryReadModel";
export * from "../models/ShipmentDirectoryReadModelPageResult";
export * from "../models/ShipmentDocument";
export * from "../models/ShipmentGoods";
export * from "../models/ShipmentGoodsLeg";
export * from "../models/ShipmentLocation";
export * from "../models/ShipmentNotificationSettingsEntry";
export * from "../models/ShipmentNotificationSettingsEntryDefinition";
export * from "../models/ShipmentNotificationSettingsGroups";
export * from "../models/ShipmentOrganization";
export * from "../models/ShipmentOverviewAddress";
export * from "../models/ShipmentOverviewReadModel";
export * from "../models/ShipmentOverviewReadModelPageResult";
export * from "../models/ShipmentPriorityNoteHistoryEntry";
export * from "../models/ShipmentPriorityReadModel";
export * from "../models/ShipmentPriorityReadModelPageResult";
export * from "../models/ShipmentReadModel";
export * from "../models/ShipmentReadModelPageResult";
export * from "../models/ShipmentWarehouseProduct";
export * from "../models/StatusInfo";
export * from "../models/StoreUserTableSort";
export * from "../models/SuggestedAddress";
export * from "../models/SystemGeneratedCarrierOrganizationReadModel";
export * from "../models/SystemGeneratedCarrierOrganizationReadModelPageResult";
export * from "../models/TenantDetails";
export * from "../models/TenantReadModel";
export * from "../models/TenantReadModelPageResult";
export * from "../models/TenantUserReadModel";
export * from "../models/TenantUserReadModelPageResult";
export * from "../models/TenantWithCustomersReadModel";
export * from "../models/TenantWithCustomersReadModelPageResult";
export * from "../models/TerminalDetails";
export * from "../models/TransportMode";
export * from "../models/TransshipmentReadModel";
export * from "../models/TransshipmentReadModelPageResult";
export * from "../models/UnarchiveChatChannel";
export * from "../models/UpdateAddress";
export * from "../models/UpdateCarrierContract";
export * from "../models/UpdateContactPerson";
export * from "../models/UpdateContainerRate";
export * from "../models/UpdateLocation";
export * from "../models/UpdateMilestone";
export * from "../models/UpdateMilestoneDueDate";
export * from "../models/UpdateMilestoneStatus";
export * from "../models/UpdateMyProfile";
export * from "../models/UpdateMyProfilePicture";
export * from "../models/UpdateOrganization";
export * from "../models/UpdateSchedule";
export * from "../models/UpdateShipmentDetails";
export * from "../models/UpdateShipmentGoods";
export * from "../models/UpdateShipmentPriority";
export * from "../models/UpdateTransshipment";
export * from "../models/UpdateUserProfile";
export * from "../models/UpdateUserProfilePicture";
export * from "../models/UpdatedField";
export * from "../models/UserBoundAddressReadModel";
export * from "../models/UserBoundAddressReadModelPageResult";
export * from "../models/UserNotificationDeletedReadModel";
export * from "../models/UserNotificationDeletedReadModelPageResult";
export * from "../models/UserNotificationReadModel";
export * from "../models/UserNotificationReadModelPageResult";
export * from "../models/UserNotificationSettingsReadModel";
export * from "../models/UserNotificationSettingsReadModelPageResult";
export * from "../models/UserOrganizationReadModel";
export * from "../models/UserOrganizationReadModelPageResult";
export * from "../models/UserProfileReadModel";
export * from "../models/UserProfileReadModelPageResult";
export * from "../models/UserSecurity";
export * from "../models/UserSecurityReadModel";
export * from "../models/UserSecurityReadModelPageResult";
export * from "../models/UserTableSortColumn";
export * from "../models/UserTableSortReadModel";
export * from "../models/UserTableSortReadModelPageResult";
export * from "../models/UserValidAddressReadModel";
export * from "../models/UserValidAddressReadModelPageResult";
export * from "../models/UserWithRoleReadModel";
export * from "../models/UserWithRoleReadModelPageResult";
export * from "../models/UserWithTenantRoleReadModel";
export * from "../models/UserWithTenantRoleReadModelPageResult";
export * from "../models/ValidateAddress";
export * from "../models/ValidationDetails";
export * from "../models/ValidationRuleReadModel";
export * from "../models/ValidationRuleReadModelPageResult";
import { ActiveRoadShipmentDraft } from "../models/ActiveRoadShipmentDraft";
import { ActiveRoadShipmentDraftPageResult } from "../models/ActiveRoadShipmentDraftPageResult";
import { AddAddressToLocation } from "../models/AddAddressToLocation";
import { AddCustomerReferenceToShipment } from "../models/AddCustomerReferenceToShipment";
import { AddCustomerToTenant } from "../models/AddCustomerToTenant";
import { AddExternalSystemReferenceToDirectoryLocation } from "../models/AddExternalSystemReferenceToDirectoryLocation";
import { AddLocationExternalSystemReferences } from "../models/AddLocationExternalSystemReferences";
import { AddOrganizationByEmail } from "../models/AddOrganizationByEmail";
import { AddToTenant } from "../models/AddToTenant";
import { AddTypeToOrganization } from "../models/AddTypeToOrganization";
import { AddUserToChannel } from "../models/AddUserToChannel";
import { AdditionalRolesReadModel } from "../models/AdditionalRolesReadModel";
import { AdditionalRolesReadModelPageResult } from "../models/AdditionalRolesReadModelPageResult";
import { AdminAddressReadModel } from "../models/AdminAddressReadModel";
import { AdminAddressReadModelPageResult } from "../models/AdminAddressReadModelPageResult";
import { AdminOrganizationReadModel } from "../models/AdminOrganizationReadModel";
import { AdminOrganizationReadModelPageResult } from "../models/AdminOrganizationReadModelPageResult";
import { ArchiveChatChannel } from "../models/ArchiveChatChannel";
import { AssignApplicationRole } from "../models/AssignApplicationRole";
import { AssignContactPersonToOrganization } from "../models/AssignContactPersonToOrganization";
import { AssignContactPersonToShipment } from "../models/AssignContactPersonToShipment";
import { AssignDocumentToShipment } from "../models/AssignDocumentToShipment";
import { AssignLoadingLocationToShipment } from "../models/AssignLoadingLocationToShipment";
import { AssignTenantRole } from "../models/AssignTenantRole";
import { AssignUnloadingLocationToShipment } from "../models/AssignUnloadingLocationToShipment";
import { AssignUserToContactPerson } from "../models/AssignUserToContactPerson";
import { AssignUserToShipment } from "../models/AssignUserToShipment";
import { AssignedUserReference } from "../models/AssignedUserReference";
import { AttachedFile } from "../models/AttachedFile";
import { BillOfLading } from "../models/BillOfLading";
import { BillOfLadingBookmark } from "../models/BillOfLadingBookmark";
import { CarrierContractReadModel } from "../models/CarrierContractReadModel";
import { CarrierContractReadModelPageResult } from "../models/CarrierContractReadModelPageResult";
import { CarrierOrganizationReadModel } from "../models/CarrierOrganizationReadModel";
import { CarrierOrganizationReadModelPageResult } from "../models/CarrierOrganizationReadModelPageResult";
import { ChangeScheduleStatus } from "../models/ChangeScheduleStatus";
import { ChatChannel } from "../models/ChatChannel";
import { ChatChannelMessage } from "../models/ChatChannelMessage";
import { ChatChannelReadModel } from "../models/ChatChannelReadModel";
import { ChatChannelReadModelPageResult } from "../models/ChatChannelReadModelPageResult";
import { Co2Emissions } from "../models/Co2Emissions";
import { CommandAcceptedResult } from "../models/CommandAcceptedResult";
import { CompleteRoadShipmentDraft } from "../models/CompleteRoadShipmentDraft";
import { CompleteShipmentDraft } from "../models/CompleteShipmentDraft";
import { ContactPersonReadModel } from "../models/ContactPersonReadModel";
import { ContactPersonReadModelPageResult } from "../models/ContactPersonReadModelPageResult";
import { ContactPersonWithAssignedUserReadModel } from "../models/ContactPersonWithAssignedUserReadModel";
import { ContactPersonWithAssignedUserReadModelPageResult } from "../models/ContactPersonWithAssignedUserReadModelPageResult";
import { ContainerEvent } from "../models/ContainerEvent";
import { ContainerJourneyReadModel } from "../models/ContainerJourneyReadModel";
import { ContainerJourneyReadModelPageResult } from "../models/ContainerJourneyReadModelPageResult";
import { ContainerRate } from "../models/ContainerRate";
import { ContainerTypeReference } from "../models/ContainerTypeReference";
import { CreateAdditionalRole } from "../models/CreateAdditionalRole";
import { CreateAddress } from "../models/CreateAddress";
import { CreateCarrierContract } from "../models/CreateCarrierContract";
import { CreateChatChannel } from "../models/CreateChatChannel";
import { CreateContactPerson } from "../models/CreateContactPerson";
import { CreateLocation } from "../models/CreateLocation";
import { CreateMilestone } from "../models/CreateMilestone";
import { CreateOrganization } from "../models/CreateOrganization";
import { CreateOrganizationDirectory } from "../models/CreateOrganizationDirectory";
import { CreateSchedule } from "../models/CreateSchedule";
import { CreateShipment } from "../models/CreateShipment";
import { CreateShipmentDirectory } from "../models/CreateShipmentDirectory";
import { CreateShipmentGoods } from "../models/CreateShipmentGoods";
import { CreateTenant } from "../models/CreateTenant";
import { CreateTransshipment } from "../models/CreateTransshipment";
import { CustomerReadModel } from "../models/CustomerReadModel";
import { CustomerReadModelPageResult } from "../models/CustomerReadModelPageResult";
import { CustomersReadModel } from "../models/CustomersReadModel";
import { CustomersReadModelPageResult } from "../models/CustomersReadModelPageResult";
import { DeleteAddress } from "../models/DeleteAddress";
import { DeleteCarrierContract } from "../models/DeleteCarrierContract";
import { DeleteChatMessage } from "../models/DeleteChatMessage";
import { DeleteContactPerson } from "../models/DeleteContactPerson";
import { DeleteLocation } from "../models/DeleteLocation";
import { DeleteMilestone } from "../models/DeleteMilestone";
import { DeleteOrganization } from "../models/DeleteOrganization";
import { DeleteOrganizationDirectory } from "../models/DeleteOrganizationDirectory";
import { DeleteSchedule } from "../models/DeleteSchedule";
import { DeleteShipmentDirectory } from "../models/DeleteShipmentDirectory";
import { DeleteShipmentDocument } from "../models/DeleteShipmentDocument";
import { DeleteTransshipment } from "../models/DeleteTransshipment";
import { DisableTenant } from "../models/DisableTenant";
import { EditChatMessage } from "../models/EditChatMessage";
import { EnableTenant } from "../models/EnableTenant";
import { EnterInviteUser } from "../models/EnterInviteUser";
import { EnterShipmentPriority } from "../models/EnterShipmentPriority";
import { ErrorResponse } from "../models/ErrorResponse";
import { ExternalInvoiceReadModel } from "../models/ExternalInvoiceReadModel";
import { ExternalInvoiceReadModelPageResult } from "../models/ExternalInvoiceReadModelPageResult";
import { ExternalSystemReference } from "../models/ExternalSystemReference";
import { FailedNvxUpdateReadModel } from "../models/FailedNvxUpdateReadModel";
import { FailedNvxUpdateReadModelPageResult } from "../models/FailedNvxUpdateReadModelPageResult";
import { FileName } from "../models/FileName";
import { InitiateCustomerFullSync } from "../models/InitiateCustomerFullSync";
import { JourneyEvent } from "../models/JourneyEvent";
import { LeaveChatChannel } from "../models/LeaveChatChannel";
import { LocationExternalSystemReferences } from "../models/LocationExternalSystemReferences";
import { LocationReadModel } from "../models/LocationReadModel";
import { LocationReadModelPageResult } from "../models/LocationReadModelPageResult";
import { MakeAddressPrivate } from "../models/MakeAddressPrivate";
import { MakeAddressPublic } from "../models/MakeAddressPublic";
import { MakeLocationPrivate } from "../models/MakeLocationPrivate";
import { MakeLocationPublic } from "../models/MakeLocationPublic";
import { MakeOrganizationPrivate } from "../models/MakeOrganizationPrivate";
import { MakeOrganizationPublic } from "../models/MakeOrganizationPublic";
import { MarkChatAsRead } from "../models/MarkChatAsRead";
import { MarkChatAsUnread } from "../models/MarkChatAsUnread";
import { MilestoneReadModel } from "../models/MilestoneReadModel";
import { MilestoneReadModelPageResult } from "../models/MilestoneReadModelPageResult";
import { MyUserProfileReadModel } from "../models/MyUserProfileReadModel";
import { MyUserProfileReadModelPageResult } from "../models/MyUserProfileReadModelPageResult";
import { NVXShipmentJuncture } from "../models/NVXShipmentJuncture";
import { NotificationArchive } from "../models/NotificationArchive";
import { NotificationDelete } from "../models/NotificationDelete";
import { NotificationMarkAsFavorite } from "../models/NotificationMarkAsFavorite";
import { NotificationMarkAsRead } from "../models/NotificationMarkAsRead";
import { NotificationMarkAsUnfavorite } from "../models/NotificationMarkAsUnfavorite";
import { NotificationMarkAsUnread } from "../models/NotificationMarkAsUnread";
import { NotificationRestore } from "../models/NotificationRestore";
import { NvxReceivedProcessedReadModel } from "../models/NvxReceivedProcessedReadModel";
import { NvxReceivedProcessedReadModelPageResult } from "../models/NvxReceivedProcessedReadModelPageResult";
import { NvxUpdate } from "../models/NvxUpdate";
import { NvxUpdateAdditionalInformation } from "../models/NvxUpdateAdditionalInformation";
import { NvxUpdateAdditionalInformationLog } from "../models/NvxUpdateAdditionalInformationLog";
import { OrganizationContactPerson } from "../models/OrganizationContactPerson";
import { OrganizationDirectoryReadModel } from "../models/OrganizationDirectoryReadModel";
import { OrganizationDirectoryReadModelPageResult } from "../models/OrganizationDirectoryReadModelPageResult";
import { OrganizationReadModel } from "../models/OrganizationReadModel";
import { OrganizationReadModelPageResult } from "../models/OrganizationReadModelPageResult";
import { Position } from "../models/Position";
import { PublicAddressReadModel } from "../models/PublicAddressReadModel";
import { PublicAddressReadModelPageResult } from "../models/PublicAddressReadModelPageResult";
import { PublicLocationReadModel } from "../models/PublicLocationReadModel";
import { PublicLocationReadModelPageResult } from "../models/PublicLocationReadModelPageResult";
import { PublicUserProfileReadModel } from "../models/PublicUserProfileReadModel";
import { PublicUserProfileReadModelPageResult } from "../models/PublicUserProfileReadModelPageResult";
import { ReadModelResetResult } from "../models/ReadModelResetResult";
import { ReadModelShipmentDocument } from "../models/ReadModelShipmentDocument";
import { ReceiveNewSignup } from "../models/ReceiveNewSignup";
import { RegisterContainerJourney } from "../models/RegisterContainerJourney";
import { RegisterExternalInvoice } from "../models/RegisterExternalInvoice";
import { RejectAddress } from "../models/RejectAddress";
import { RemoveAdditionalRole } from "../models/RemoveAdditionalRole";
import { RemoveContactPersonFromShipment } from "../models/RemoveContactPersonFromShipment";
import { RemoveCustomerFromTenant } from "../models/RemoveCustomerFromTenant";
import { RemoveCustomerReferenceFromShipment } from "../models/RemoveCustomerReferenceFromShipment";
import { RemoveExternalSystemReferenceFromDirectoryLocation } from "../models/RemoveExternalSystemReferenceFromDirectoryLocation";
import { RemoveFromTenant } from "../models/RemoveFromTenant";
import { RemoveGoodsFromShipment } from "../models/RemoveGoodsFromShipment";
import { RemoveLocationExternalSystemReferences } from "../models/RemoveLocationExternalSystemReferences";
import { RemoveShipmentLoadingLocation } from "../models/RemoveShipmentLoadingLocation";
import { RemoveShipmentUnloadingLocation } from "../models/RemoveShipmentUnloadingLocation";
import { RemoveTypeFromOrganization } from "../models/RemoveTypeFromOrganization";
import { RemoveUserFromShipment } from "../models/RemoveUserFromShipment";
import { RemoveValidationRule } from "../models/RemoveValidationRule";
import { RenameTenant } from "../models/RenameTenant";
import { RestoreAddress } from "../models/RestoreAddress";
import { RestoreCarrierContract } from "../models/RestoreCarrierContract";
import { RestoreLocation } from "../models/RestoreLocation";
import { RestoreOrganization } from "../models/RestoreOrganization";
import { RestoreOrganizationDirectory } from "../models/RestoreOrganizationDirectory";
import { RestoreShipmentDirectory } from "../models/RestoreShipmentDirectory";
import { RevokeApplicationRole } from "../models/RevokeApplicationRole";
import { RevokeTenantRole } from "../models/RevokeTenantRole";
import { RoadShipmentDraftSubscribe } from "../models/RoadShipmentDraftSubscribe";
import { RoadShipmentReadModel } from "../models/RoadShipmentReadModel";
import { RoadShipmentReadModelPageResult } from "../models/RoadShipmentReadModelPageResult";
import { RoadShipmentSubscribe } from "../models/RoadShipmentSubscribe";
import { SaveRoadShipmentDraft } from "../models/SaveRoadShipmentDraft";
import { ScheduleChangeEntry } from "../models/ScheduleChangeEntry";
import { ScheduleReadModel } from "../models/ScheduleReadModel";
import { ScheduleReadModelPageResult } from "../models/ScheduleReadModelPageResult";
import { SeaShipmentReadModel } from "../models/SeaShipmentReadModel";
import { SeaShipmentReadModelPageResult } from "../models/SeaShipmentReadModelPageResult";
import { SendChatMessage } from "../models/SendChatMessage";
import { SetNotificationSetting } from "../models/SetNotificationSetting";
import { SetShipmentCargoReadyDate } from "../models/SetShipmentCargoReadyDate";
import { SetShipmentDestination } from "../models/SetShipmentDestination";
import { SetShipmentImportance } from "../models/SetShipmentImportance";
import { SetShipmentOrigin } from "../models/SetShipmentOrigin";
import { SetShipmentOriginEta } from "../models/SetShipmentOriginEta";
import { SetShipmentPlaceOfDelivery } from "../models/SetShipmentPlaceOfDelivery";
import { SetShipmentPlaceOfReceipt } from "../models/SetShipmentPlaceOfReceipt";
import { SetShipmentRemarks } from "../models/SetShipmentRemarks";
import { SetShipmentStackability } from "../models/SetShipmentStackability";
import { SetShipmentStatus } from "../models/SetShipmentStatus";
import { SetValidationRule } from "../models/SetValidationRule";
import { ShipmentAddress } from "../models/ShipmentAddress";
import { ShipmentAssignedUser } from "../models/ShipmentAssignedUser";
import { ShipmentContact } from "../models/ShipmentContact";
import { ShipmentContactPerson } from "../models/ShipmentContactPerson";
import { ShipmentContainer } from "../models/ShipmentContainer";
import { ShipmentDirectoryReadModel } from "../models/ShipmentDirectoryReadModel";
import { ShipmentDirectoryReadModelPageResult } from "../models/ShipmentDirectoryReadModelPageResult";
import { ShipmentDocument } from "../models/ShipmentDocument";
import { ShipmentGoods } from "../models/ShipmentGoods";
import { ShipmentGoodsLeg } from "../models/ShipmentGoodsLeg";
import { ShipmentLocation } from "../models/ShipmentLocation";
import { ShipmentNotificationSettingsEntry } from "../models/ShipmentNotificationSettingsEntry";
import { ShipmentNotificationSettingsEntryDefinition } from "../models/ShipmentNotificationSettingsEntryDefinition";
import { ShipmentNotificationSettingsGroups } from "../models/ShipmentNotificationSettingsGroups";
import { ShipmentOrganization } from "../models/ShipmentOrganization";
import { ShipmentOverviewAddress } from "../models/ShipmentOverviewAddress";
import { ShipmentOverviewReadModel } from "../models/ShipmentOverviewReadModel";
import { ShipmentOverviewReadModelPageResult } from "../models/ShipmentOverviewReadModelPageResult";
import { ShipmentPriorityNoteHistoryEntry } from "../models/ShipmentPriorityNoteHistoryEntry";
import { ShipmentPriorityReadModel } from "../models/ShipmentPriorityReadModel";
import { ShipmentPriorityReadModelPageResult } from "../models/ShipmentPriorityReadModelPageResult";
import { ShipmentReadModel } from "../models/ShipmentReadModel";
import { ShipmentReadModelPageResult } from "../models/ShipmentReadModelPageResult";
import { ShipmentWarehouseProduct } from "../models/ShipmentWarehouseProduct";
import { StatusInfo } from "../models/StatusInfo";
import { StoreUserTableSort } from "../models/StoreUserTableSort";
import { SuggestedAddress } from "../models/SuggestedAddress";
import { SystemGeneratedCarrierOrganizationReadModel } from "../models/SystemGeneratedCarrierOrganizationReadModel";
import { SystemGeneratedCarrierOrganizationReadModelPageResult } from "../models/SystemGeneratedCarrierOrganizationReadModelPageResult";
import { TenantDetails } from "../models/TenantDetails";
import { TenantReadModel } from "../models/TenantReadModel";
import { TenantReadModelPageResult } from "../models/TenantReadModelPageResult";
import { TenantUserReadModel } from "../models/TenantUserReadModel";
import { TenantUserReadModelPageResult } from "../models/TenantUserReadModelPageResult";
import { TenantWithCustomersReadModel } from "../models/TenantWithCustomersReadModel";
import { TenantWithCustomersReadModelPageResult } from "../models/TenantWithCustomersReadModelPageResult";
import { TerminalDetails } from "../models/TerminalDetails";
import { TransshipmentReadModel } from "../models/TransshipmentReadModel";
import { TransshipmentReadModelPageResult } from "../models/TransshipmentReadModelPageResult";
import { UnarchiveChatChannel } from "../models/UnarchiveChatChannel";
import { UpdateAddress } from "../models/UpdateAddress";
import { UpdateCarrierContract } from "../models/UpdateCarrierContract";
import { UpdateContactPerson } from "../models/UpdateContactPerson";
import { UpdateContainerRate } from "../models/UpdateContainerRate";
import { UpdateLocation } from "../models/UpdateLocation";
import { UpdateMilestone } from "../models/UpdateMilestone";
import { UpdateMilestoneDueDate } from "../models/UpdateMilestoneDueDate";
import { UpdateMilestoneStatus } from "../models/UpdateMilestoneStatus";
import { UpdateMyProfile } from "../models/UpdateMyProfile";
import { UpdateMyProfilePicture } from "../models/UpdateMyProfilePicture";
import { UpdateOrganization } from "../models/UpdateOrganization";
import { UpdateSchedule } from "../models/UpdateSchedule";
import { UpdateShipmentDetails } from "../models/UpdateShipmentDetails";
import { UpdateShipmentGoods } from "../models/UpdateShipmentGoods";
import { UpdateShipmentPriority } from "../models/UpdateShipmentPriority";
import { UpdateTransshipment } from "../models/UpdateTransshipment";
import { UpdateUserProfile } from "../models/UpdateUserProfile";
import { UpdateUserProfilePicture } from "../models/UpdateUserProfilePicture";
import { UpdatedField } from "../models/UpdatedField";
import { UserBoundAddressReadModel } from "../models/UserBoundAddressReadModel";
import { UserBoundAddressReadModelPageResult } from "../models/UserBoundAddressReadModelPageResult";
import { UserNotificationDeletedReadModel } from "../models/UserNotificationDeletedReadModel";
import { UserNotificationDeletedReadModelPageResult } from "../models/UserNotificationDeletedReadModelPageResult";
import { UserNotificationReadModel } from "../models/UserNotificationReadModel";
import { UserNotificationReadModelPageResult } from "../models/UserNotificationReadModelPageResult";
import { UserNotificationSettingsReadModel } from "../models/UserNotificationSettingsReadModel";
import { UserNotificationSettingsReadModelPageResult } from "../models/UserNotificationSettingsReadModelPageResult";
import { UserOrganizationReadModel } from "../models/UserOrganizationReadModel";
import { UserOrganizationReadModelPageResult } from "../models/UserOrganizationReadModelPageResult";
import { UserProfileReadModel } from "../models/UserProfileReadModel";
import { UserProfileReadModelPageResult } from "../models/UserProfileReadModelPageResult";
import { UserSecurity } from "../models/UserSecurity";
import { UserSecurityReadModel } from "../models/UserSecurityReadModel";
import { UserSecurityReadModelPageResult } from "../models/UserSecurityReadModelPageResult";
import { UserTableSortColumn } from "../models/UserTableSortColumn";
import { UserTableSortReadModel } from "../models/UserTableSortReadModel";
import { UserTableSortReadModelPageResult } from "../models/UserTableSortReadModelPageResult";
import { UserValidAddressReadModel } from "../models/UserValidAddressReadModel";
import { UserValidAddressReadModelPageResult } from "../models/UserValidAddressReadModelPageResult";
import { UserWithRoleReadModel } from "../models/UserWithRoleReadModel";
import { UserWithRoleReadModelPageResult } from "../models/UserWithRoleReadModelPageResult";
import { UserWithTenantRoleReadModel } from "../models/UserWithTenantRoleReadModel";
import { UserWithTenantRoleReadModelPageResult } from "../models/UserWithTenantRoleReadModelPageResult";
import { ValidateAddress } from "../models/ValidateAddress";
import { ValidationDetails } from "../models/ValidationDetails";
import { ValidationRuleReadModel } from "../models/ValidationRuleReadModel";
import { ValidationRuleReadModelPageResult } from "../models/ValidationRuleReadModelPageResult";
let primitives = ["string", "boolean", "double", "integer", "long", "float", "number", "any"],
  enumsMap = new Set(["ContractOwner", "JourneyEventDisposition", "JourneyEventLocationType", "MBLPaymentTerm", "NVXShipmentJuncturePortTypeEnum", "NVXShipmentJunctureSourceEnum", "NVXShipmentJunctureStageEnum", "NVXShipmentJunctureStatusEnum", "NVXShipmentJunctureTypeEnum", "ScheduleStatus", "TransportMode"]),
  typeMap = {
    ActiveRoadShipmentDraft: ActiveRoadShipmentDraft,
    ActiveRoadShipmentDraftPageResult: ActiveRoadShipmentDraftPageResult,
    AddAddressToLocation: AddAddressToLocation,
    AddCustomerReferenceToShipment: AddCustomerReferenceToShipment,
    AddCustomerToTenant: AddCustomerToTenant,
    AddExternalSystemReferenceToDirectoryLocation: AddExternalSystemReferenceToDirectoryLocation,
    AddLocationExternalSystemReferences: AddLocationExternalSystemReferences,
    AddOrganizationByEmail: AddOrganizationByEmail,
    AddToTenant: AddToTenant,
    AddTypeToOrganization: AddTypeToOrganization,
    AddUserToChannel: AddUserToChannel,
    AdditionalRolesReadModel: AdditionalRolesReadModel,
    AdditionalRolesReadModelPageResult: AdditionalRolesReadModelPageResult,
    AdminAddressReadModel: AdminAddressReadModel,
    AdminAddressReadModelPageResult: AdminAddressReadModelPageResult,
    AdminOrganizationReadModel: AdminOrganizationReadModel,
    AdminOrganizationReadModelPageResult: AdminOrganizationReadModelPageResult,
    ArchiveChatChannel: ArchiveChatChannel,
    AssignApplicationRole: AssignApplicationRole,
    AssignContactPersonToOrganization: AssignContactPersonToOrganization,
    AssignContactPersonToShipment: AssignContactPersonToShipment,
    AssignDocumentToShipment: AssignDocumentToShipment,
    AssignLoadingLocationToShipment: AssignLoadingLocationToShipment,
    AssignTenantRole: AssignTenantRole,
    AssignUnloadingLocationToShipment: AssignUnloadingLocationToShipment,
    AssignUserToContactPerson: AssignUserToContactPerson,
    AssignUserToShipment: AssignUserToShipment,
    AssignedUserReference: AssignedUserReference,
    AttachedFile: AttachedFile,
    BillOfLading: BillOfLading,
    BillOfLadingBookmark: BillOfLadingBookmark,
    CarrierContractReadModel: CarrierContractReadModel,
    CarrierContractReadModelPageResult: CarrierContractReadModelPageResult,
    CarrierOrganizationReadModel: CarrierOrganizationReadModel,
    CarrierOrganizationReadModelPageResult: CarrierOrganizationReadModelPageResult,
    ChangeScheduleStatus: ChangeScheduleStatus,
    ChatChannel: ChatChannel,
    ChatChannelMessage: ChatChannelMessage,
    ChatChannelReadModel: ChatChannelReadModel,
    ChatChannelReadModelPageResult: ChatChannelReadModelPageResult,
    Co2Emissions: Co2Emissions,
    CommandAcceptedResult: CommandAcceptedResult,
    CompleteRoadShipmentDraft: CompleteRoadShipmentDraft,
    CompleteShipmentDraft: CompleteShipmentDraft,
    ContactPersonReadModel: ContactPersonReadModel,
    ContactPersonReadModelPageResult: ContactPersonReadModelPageResult,
    ContactPersonWithAssignedUserReadModel: ContactPersonWithAssignedUserReadModel,
    ContactPersonWithAssignedUserReadModelPageResult: ContactPersonWithAssignedUserReadModelPageResult,
    ContainerEvent: ContainerEvent,
    ContainerJourneyReadModel: ContainerJourneyReadModel,
    ContainerJourneyReadModelPageResult: ContainerJourneyReadModelPageResult,
    ContainerRate: ContainerRate,
    ContainerTypeReference: ContainerTypeReference,
    CreateAdditionalRole: CreateAdditionalRole,
    CreateAddress: CreateAddress,
    CreateCarrierContract: CreateCarrierContract,
    CreateChatChannel: CreateChatChannel,
    CreateContactPerson: CreateContactPerson,
    CreateLocation: CreateLocation,
    CreateMilestone: CreateMilestone,
    CreateOrganization: CreateOrganization,
    CreateOrganizationDirectory: CreateOrganizationDirectory,
    CreateSchedule: CreateSchedule,
    CreateShipment: CreateShipment,
    CreateShipmentDirectory: CreateShipmentDirectory,
    CreateShipmentGoods: CreateShipmentGoods,
    CreateTenant: CreateTenant,
    CreateTransshipment: CreateTransshipment,
    CustomerReadModel: CustomerReadModel,
    CustomerReadModelPageResult: CustomerReadModelPageResult,
    CustomersReadModel: CustomersReadModel,
    CustomersReadModelPageResult: CustomersReadModelPageResult,
    DeleteAddress: DeleteAddress,
    DeleteCarrierContract: DeleteCarrierContract,
    DeleteChatMessage: DeleteChatMessage,
    DeleteContactPerson: DeleteContactPerson,
    DeleteLocation: DeleteLocation,
    DeleteMilestone: DeleteMilestone,
    DeleteOrganization: DeleteOrganization,
    DeleteOrganizationDirectory: DeleteOrganizationDirectory,
    DeleteSchedule: DeleteSchedule,
    DeleteShipmentDirectory: DeleteShipmentDirectory,
    DeleteShipmentDocument: DeleteShipmentDocument,
    DeleteTransshipment: DeleteTransshipment,
    DisableTenant: DisableTenant,
    EditChatMessage: EditChatMessage,
    EnableTenant: EnableTenant,
    EnterInviteUser: EnterInviteUser,
    EnterShipmentPriority: EnterShipmentPriority,
    ErrorResponse: ErrorResponse,
    ExternalInvoiceReadModel: ExternalInvoiceReadModel,
    ExternalInvoiceReadModelPageResult: ExternalInvoiceReadModelPageResult,
    ExternalSystemReference: ExternalSystemReference,
    FailedNvxUpdateReadModel: FailedNvxUpdateReadModel,
    FailedNvxUpdateReadModelPageResult: FailedNvxUpdateReadModelPageResult,
    FileName: FileName,
    InitiateCustomerFullSync: InitiateCustomerFullSync,
    JourneyEvent: JourneyEvent,
    LeaveChatChannel: LeaveChatChannel,
    LocationExternalSystemReferences: LocationExternalSystemReferences,
    LocationReadModel: LocationReadModel,
    LocationReadModelPageResult: LocationReadModelPageResult,
    MakeAddressPrivate: MakeAddressPrivate,
    MakeAddressPublic: MakeAddressPublic,
    MakeLocationPrivate: MakeLocationPrivate,
    MakeLocationPublic: MakeLocationPublic,
    MakeOrganizationPrivate: MakeOrganizationPrivate,
    MakeOrganizationPublic: MakeOrganizationPublic,
    MarkChatAsRead: MarkChatAsRead,
    MarkChatAsUnread: MarkChatAsUnread,
    MilestoneReadModel: MilestoneReadModel,
    MilestoneReadModelPageResult: MilestoneReadModelPageResult,
    MyUserProfileReadModel: MyUserProfileReadModel,
    MyUserProfileReadModelPageResult: MyUserProfileReadModelPageResult,
    NVXShipmentJuncture: NVXShipmentJuncture,
    NotificationArchive: NotificationArchive,
    NotificationDelete: NotificationDelete,
    NotificationMarkAsFavorite: NotificationMarkAsFavorite,
    NotificationMarkAsRead: NotificationMarkAsRead,
    NotificationMarkAsUnfavorite: NotificationMarkAsUnfavorite,
    NotificationMarkAsUnread: NotificationMarkAsUnread,
    NotificationRestore: NotificationRestore,
    NvxReceivedProcessedReadModel: NvxReceivedProcessedReadModel,
    NvxReceivedProcessedReadModelPageResult: NvxReceivedProcessedReadModelPageResult,
    NvxUpdate: NvxUpdate,
    NvxUpdateAdditionalInformation: NvxUpdateAdditionalInformation,
    NvxUpdateAdditionalInformationLog: NvxUpdateAdditionalInformationLog,
    OrganizationContactPerson: OrganizationContactPerson,
    OrganizationDirectoryReadModel: OrganizationDirectoryReadModel,
    OrganizationDirectoryReadModelPageResult: OrganizationDirectoryReadModelPageResult,
    OrganizationReadModel: OrganizationReadModel,
    OrganizationReadModelPageResult: OrganizationReadModelPageResult,
    Position: Position,
    PublicAddressReadModel: PublicAddressReadModel,
    PublicAddressReadModelPageResult: PublicAddressReadModelPageResult,
    PublicLocationReadModel: PublicLocationReadModel,
    PublicLocationReadModelPageResult: PublicLocationReadModelPageResult,
    PublicUserProfileReadModel: PublicUserProfileReadModel,
    PublicUserProfileReadModelPageResult: PublicUserProfileReadModelPageResult,
    ReadModelResetResult: ReadModelResetResult,
    ReadModelShipmentDocument: ReadModelShipmentDocument,
    ReceiveNewSignup: ReceiveNewSignup,
    RegisterContainerJourney: RegisterContainerJourney,
    RegisterExternalInvoice: RegisterExternalInvoice,
    RejectAddress: RejectAddress,
    RemoveAdditionalRole: RemoveAdditionalRole,
    RemoveContactPersonFromShipment: RemoveContactPersonFromShipment,
    RemoveCustomerFromTenant: RemoveCustomerFromTenant,
    RemoveCustomerReferenceFromShipment: RemoveCustomerReferenceFromShipment,
    RemoveExternalSystemReferenceFromDirectoryLocation: RemoveExternalSystemReferenceFromDirectoryLocation,
    RemoveFromTenant: RemoveFromTenant,
    RemoveGoodsFromShipment: RemoveGoodsFromShipment,
    RemoveLocationExternalSystemReferences: RemoveLocationExternalSystemReferences,
    RemoveShipmentLoadingLocation: RemoveShipmentLoadingLocation,
    RemoveShipmentUnloadingLocation: RemoveShipmentUnloadingLocation,
    RemoveTypeFromOrganization: RemoveTypeFromOrganization,
    RemoveUserFromShipment: RemoveUserFromShipment,
    RemoveValidationRule: RemoveValidationRule,
    RenameTenant: RenameTenant,
    RestoreAddress: RestoreAddress,
    RestoreCarrierContract: RestoreCarrierContract,
    RestoreLocation: RestoreLocation,
    RestoreOrganization: RestoreOrganization,
    RestoreOrganizationDirectory: RestoreOrganizationDirectory,
    RestoreShipmentDirectory: RestoreShipmentDirectory,
    RevokeApplicationRole: RevokeApplicationRole,
    RevokeTenantRole: RevokeTenantRole,
    RoadShipmentDraftSubscribe: RoadShipmentDraftSubscribe,
    RoadShipmentReadModel: RoadShipmentReadModel,
    RoadShipmentReadModelPageResult: RoadShipmentReadModelPageResult,
    RoadShipmentSubscribe: RoadShipmentSubscribe,
    SaveRoadShipmentDraft: SaveRoadShipmentDraft,
    ScheduleChangeEntry: ScheduleChangeEntry,
    ScheduleReadModel: ScheduleReadModel,
    ScheduleReadModelPageResult: ScheduleReadModelPageResult,
    SeaShipmentReadModel: SeaShipmentReadModel,
    SeaShipmentReadModelPageResult: SeaShipmentReadModelPageResult,
    SendChatMessage: SendChatMessage,
    SetNotificationSetting: SetNotificationSetting,
    SetShipmentCargoReadyDate: SetShipmentCargoReadyDate,
    SetShipmentDestination: SetShipmentDestination,
    SetShipmentImportance: SetShipmentImportance,
    SetShipmentOrigin: SetShipmentOrigin,
    SetShipmentOriginEta: SetShipmentOriginEta,
    SetShipmentPlaceOfDelivery: SetShipmentPlaceOfDelivery,
    SetShipmentPlaceOfReceipt: SetShipmentPlaceOfReceipt,
    SetShipmentRemarks: SetShipmentRemarks,
    SetShipmentStackability: SetShipmentStackability,
    SetShipmentStatus: SetShipmentStatus,
    SetValidationRule: SetValidationRule,
    ShipmentAddress: ShipmentAddress,
    ShipmentAssignedUser: ShipmentAssignedUser,
    ShipmentContact: ShipmentContact,
    ShipmentContactPerson: ShipmentContactPerson,
    ShipmentContainer: ShipmentContainer,
    ShipmentDirectoryReadModel: ShipmentDirectoryReadModel,
    ShipmentDirectoryReadModelPageResult: ShipmentDirectoryReadModelPageResult,
    ShipmentDocument: ShipmentDocument,
    ShipmentGoods: ShipmentGoods,
    ShipmentGoodsLeg: ShipmentGoodsLeg,
    ShipmentLocation: ShipmentLocation,
    ShipmentNotificationSettingsEntry: ShipmentNotificationSettingsEntry,
    ShipmentNotificationSettingsEntryDefinition: ShipmentNotificationSettingsEntryDefinition,
    ShipmentNotificationSettingsGroups: ShipmentNotificationSettingsGroups,
    ShipmentOrganization: ShipmentOrganization,
    ShipmentOverviewAddress: ShipmentOverviewAddress,
    ShipmentOverviewReadModel: ShipmentOverviewReadModel,
    ShipmentOverviewReadModelPageResult: ShipmentOverviewReadModelPageResult,
    ShipmentPriorityNoteHistoryEntry: ShipmentPriorityNoteHistoryEntry,
    ShipmentPriorityReadModel: ShipmentPriorityReadModel,
    ShipmentPriorityReadModelPageResult: ShipmentPriorityReadModelPageResult,
    ShipmentReadModel: ShipmentReadModel,
    ShipmentReadModelPageResult: ShipmentReadModelPageResult,
    ShipmentWarehouseProduct: ShipmentWarehouseProduct,
    StatusInfo: StatusInfo,
    StoreUserTableSort: StoreUserTableSort,
    SuggestedAddress: SuggestedAddress,
    SystemGeneratedCarrierOrganizationReadModel: SystemGeneratedCarrierOrganizationReadModel,
    SystemGeneratedCarrierOrganizationReadModelPageResult: SystemGeneratedCarrierOrganizationReadModelPageResult,
    TenantDetails: TenantDetails,
    TenantReadModel: TenantReadModel,
    TenantReadModelPageResult: TenantReadModelPageResult,
    TenantUserReadModel: TenantUserReadModel,
    TenantUserReadModelPageResult: TenantUserReadModelPageResult,
    TenantWithCustomersReadModel: TenantWithCustomersReadModel,
    TenantWithCustomersReadModelPageResult: TenantWithCustomersReadModelPageResult,
    TerminalDetails: TerminalDetails,
    TransshipmentReadModel: TransshipmentReadModel,
    TransshipmentReadModelPageResult: TransshipmentReadModelPageResult,
    UnarchiveChatChannel: UnarchiveChatChannel,
    UpdateAddress: UpdateAddress,
    UpdateCarrierContract: UpdateCarrierContract,
    UpdateContactPerson: UpdateContactPerson,
    UpdateContainerRate: UpdateContainerRate,
    UpdateLocation: UpdateLocation,
    UpdateMilestone: UpdateMilestone,
    UpdateMilestoneDueDate: UpdateMilestoneDueDate,
    UpdateMilestoneStatus: UpdateMilestoneStatus,
    UpdateMyProfile: UpdateMyProfile,
    UpdateMyProfilePicture: UpdateMyProfilePicture,
    UpdateOrganization: UpdateOrganization,
    UpdateSchedule: UpdateSchedule,
    UpdateShipmentDetails: UpdateShipmentDetails,
    UpdateShipmentGoods: UpdateShipmentGoods,
    UpdateShipmentPriority: UpdateShipmentPriority,
    UpdateTransshipment: UpdateTransshipment,
    UpdateUserProfile: UpdateUserProfile,
    UpdateUserProfilePicture: UpdateUserProfilePicture,
    UpdatedField: UpdatedField,
    UserBoundAddressReadModel: UserBoundAddressReadModel,
    UserBoundAddressReadModelPageResult: UserBoundAddressReadModelPageResult,
    UserNotificationDeletedReadModel: UserNotificationDeletedReadModel,
    UserNotificationDeletedReadModelPageResult: UserNotificationDeletedReadModelPageResult,
    UserNotificationReadModel: UserNotificationReadModel,
    UserNotificationReadModelPageResult: UserNotificationReadModelPageResult,
    UserNotificationSettingsReadModel: UserNotificationSettingsReadModel,
    UserNotificationSettingsReadModelPageResult: UserNotificationSettingsReadModelPageResult,
    UserOrganizationReadModel: UserOrganizationReadModel,
    UserOrganizationReadModelPageResult: UserOrganizationReadModelPageResult,
    UserProfileReadModel: UserProfileReadModel,
    UserProfileReadModelPageResult: UserProfileReadModelPageResult,
    UserSecurity: UserSecurity,
    UserSecurityReadModel: UserSecurityReadModel,
    UserSecurityReadModelPageResult: UserSecurityReadModelPageResult,
    UserTableSortColumn: UserTableSortColumn,
    UserTableSortReadModel: UserTableSortReadModel,
    UserTableSortReadModelPageResult: UserTableSortReadModelPageResult,
    UserValidAddressReadModel: UserValidAddressReadModel,
    UserValidAddressReadModelPageResult: UserValidAddressReadModelPageResult,
    UserWithRoleReadModel: UserWithRoleReadModel,
    UserWithRoleReadModelPageResult: UserWithRoleReadModelPageResult,
    UserWithTenantRoleReadModel: UserWithTenantRoleReadModel,
    UserWithTenantRoleReadModelPageResult: UserWithTenantRoleReadModelPageResult,
    ValidateAddress: ValidateAddress,
    ValidationDetails: ValidationDetails,
    ValidationRuleReadModel: ValidationRuleReadModel,
    ValidationRuleReadModelPageResult: ValidationRuleReadModelPageResult
  };
const parseMimeType = e => {
    const [o, t] = e.split("/");
    return {
      type: o,
      subtype: t,
      subtypeTokens: t.split("+")
    };
  },
  mimeTypePredicateFactory = e => o => e(parseMimeType(o)),
  mimeTypeSimplePredicateFactory = (e, o) => mimeTypePredicateFactory(t => t.type === e && (null == o || t.subtype === o)),
  isTextLikeMimeType = mimeTypeSimplePredicateFactory("text"),
  isJsonMimeType = mimeTypeSimplePredicateFactory("application", "json"),
  isJsonLikeMimeType = mimeTypePredicateFactory(e => "application" === e.type && e.subtypeTokens.some(e => "json" === e)),
  isOctetStreamMimeType = mimeTypeSimplePredicateFactory("application", "octet-stream"),
  isFormUrlencodedMimeType = mimeTypeSimplePredicateFactory("application", "x-www-form-urlencoded"),
  supportedMimeTypePredicatesWithPriority = [isJsonMimeType, isJsonLikeMimeType, isTextLikeMimeType, isOctetStreamMimeType, isFormUrlencodedMimeType];
export class ObjectSerializer {
  static findCorrectType(e, o) {
    if (void 0 == e) return o;
    if (-1 !== primitives.indexOf(o.toLowerCase())) return o;
    if ("Date" === o) return o;
    {
      if (enumsMap.has(o)) return o;
      if (!typeMap[o]) return o;
      let r = typeMap[o].discriminator;
      if (null == r) return o;
      if (e[r]) {
        var t = e[r];
        return typeMap[t] ? t : o;
      }
      return o;
    }
  }
  static serialize(e, o, t) {
    if (void 0 == e) return e;
    if (-1 !== primitives.indexOf(o.toLowerCase())) return e;
    if (0 === o.lastIndexOf("Array<", 0)) {
      let r = o.replace("Array<", "");
      r = r.substring(0, r.length - 1);
      let a = [];
      for (let o of e) a.push(ObjectSerializer.serialize(o, r, t));
      return a;
    }
    if ("Date" === o) {
      if ("date" == t) {
        let o = e.getMonth() + 1;
        o = o < 10 ? "0" + o.toString() : o.toString();
        let t = e.getDate();
        return t = t < 10 ? "0" + t.toString() : t.toString(), e.getFullYear() + "-" + o + "-" + t;
      }
      return e.toISOString();
    }
    {
      if (enumsMap.has(o)) return e;
      if (!typeMap[o]) return e;
      o = this.findCorrectType(e, o);
      let t = typeMap[o].getAttributeTypeMap(),
        r = {};
      for (let o of t) r[o.baseName] = ObjectSerializer.serialize(e[o.name], o.type, o.format);
      return r;
    }
  }
  static deserialize(e, o, t) {
    if (o = ObjectSerializer.findCorrectType(e, o), void 0 == e) return e;
    if (-1 !== primitives.indexOf(o.toLowerCase())) return e;
    if (0 === o.lastIndexOf("Array<", 0)) {
      let r = o.replace("Array<", "");
      r = r.substring(0, r.length - 1);
      let a = [];
      for (let o of e) a.push(ObjectSerializer.deserialize(o, r, t));
      return a;
    }
    if ("Date" === o) return new Date(e);
    {
      if (enumsMap.has(o)) return e;
      if (!typeMap[o]) return e;
      let t = new typeMap[o](),
        r = typeMap[o].getAttributeTypeMap();
      for (let o of r) {
        let r = ObjectSerializer.deserialize(e[o.baseName], o.type, o.format);
        void 0 !== r && (t[o.name] = r);
      }
      return t;
    }
  }
  static normalizeMediaType(e) {
    if (void 0 !== e) return e.split(";")[0].trim().toLowerCase();
  }
  static getPreferredMediaType(e) {
    if (0 === e.length) return "application/json";
    const o = e.map(this.normalizeMediaType);
    for (const t of supportedMimeTypePredicatesWithPriority) for (const e of o) if (null != e && t(e)) return e;
    throw new Error("None of the given media types are supported: " + e.join(", "));
  }
  static stringify(e, o) {
    if (isTextLikeMimeType(o)) return String(e);
    if (isJsonLikeMimeType(o)) return JSON.stringify(e);
    throw new Error("The mediaType " + o + " is not supported by ObjectSerializer.stringify.");
  }
  static parse(e, o) {
    if (void 0 === o) throw new Error("Cannot parse content. No Content-Type defined.");
    if (isTextLikeMimeType(o)) return e;
    if (isJsonLikeMimeType(o)) return JSON.parse(e);
    throw new Error("The mediaType " + o + " is not supported by ObjectSerializer.parse.");
  }
}