export class UpdateShipmentGoods {
  static getAttributeTypeMap() {
    return UpdateShipmentGoods.attributeTypeMap;
  }
  constructor(e, t, a, m, s, r, n, o, i, b, p, d, u, g, h, N) {
    this.id = e, this.nvxId = t, this.orderIndex = a, this.containerNumber = m, this.containerType = s, this.grossWeight = r, this.commodity = n, this.poNumbers = o, this.sealNumber = i, this.netWeight = b, this.hsCodes = p, this.numberOfPackages = d, this.packageCode = u, this.cbm = g, this.dangerousGoodsUnNumber = h, this.size = N;
  }
}
UpdateShipmentGoods.discriminator = void 0, UpdateShipmentGoods.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "orderIndex",
  baseName: "orderIndex",
  type: "number",
  format: "int32"
}, {
  name: "containerNumber",
  baseName: "containerNumber",
  type: "string",
  format: ""
}, {
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}, {
  name: "grossWeight",
  baseName: "grossWeight",
  type: "number",
  format: "float"
}, {
  name: "commodity",
  baseName: "commodity",
  type: "string",
  format: ""
}, {
  name: "poNumbers",
  baseName: "poNumbers",
  type: "Array<string>",
  format: ""
}, {
  name: "sealNumber",
  baseName: "sealNumber",
  type: "string",
  format: ""
}, {
  name: "netWeight",
  baseName: "netWeight",
  type: "number",
  format: "float"
}, {
  name: "hsCodes",
  baseName: "hsCodes",
  type: "string",
  format: ""
}, {
  name: "numberOfPackages",
  baseName: "numberOfPackages",
  type: "number",
  format: "int32"
}, {
  name: "packageCode",
  baseName: "packageCode",
  type: "string",
  format: ""
}, {
  name: "cbm",
  baseName: "cbm",
  type: "number",
  format: "int32"
}, {
  name: "dangerousGoodsUnNumber",
  baseName: "dangerousGoodsUnNumber",
  type: "string",
  format: ""
}, {
  name: "size",
  baseName: "size",
  type: "number",
  format: "int32"
}];