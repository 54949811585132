export class ShipmentReadModel {
  static getAttributeTypeMap() {
    return ShipmentReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, n, m, i, r, o, d, p, h, l, u, b, c, y, g, N, f, S, A, D, v, L, T, M, E, R, B, I, O, P, V, k, C, J, U, X, j, x, G, q, w, z, F, H, K, Q, W, Y, Z, $) {
    this.id = e, this.tenantId = t, this.loadingLocation = a, this.loadingAddress = s, this.unloadingLocation = n, this.unloadingAddress = m, this.destination = i, this.origin = r, this.placeOfReceipt = o, this.placeOfDelivery = d, this.contacts = p, this.goods = h, this.documents = l, this.loadingDateStart = u, this.loadingDateEnd = b, this.unloadingDateStart = c, this.unloadingDateEnd = y, this.createdAt = g, this.cargoReadyAt = N, this.originEta = f, this.incoterm = S, this.customerReferences = A, this.remarks = D, this.originTms = v, this.destinationTms = L, this.isStackable = T, this.importance = M, this.status = E, this.assignedUsers = R, this.masterBLNumber = B, this.houseBLNumber = I, this.houseBLIssuedAs = O, this.earliestArrival = P, this.earliestSailing = V, this.latestSailing = k, this.latestArrival = C, this.eta = J, this.etd = U, this.ata = X, this.atd = j, this.mostAdvancedScheduleStatus = x, this.scheduleNumber = G, this.carrier = q, this.transportMode = w, this.vessel = z, this.imoNumber = F, this.journeyEvents = H, this.transshipmentPort = K, this.etaTranshipmentPort = Q, this.etdTranshipmentPort = W, this.transshipmentVessel = Y, this.transshipmentIMONumber = Z, this.chatChannels = $;
  }
}
ShipmentReadModel.discriminator = void 0, ShipmentReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "loadingLocation",
  baseName: "loadingLocation",
  type: "ShipmentLocation",
  format: ""
}, {
  name: "loadingAddress",
  baseName: "loadingAddress",
  type: "ShipmentAddress",
  format: ""
}, {
  name: "unloadingLocation",
  baseName: "unloadingLocation",
  type: "ShipmentLocation",
  format: ""
}, {
  name: "unloadingAddress",
  baseName: "unloadingAddress",
  type: "ShipmentAddress",
  format: ""
}, {
  name: "destination",
  baseName: "destination",
  type: "string",
  format: ""
}, {
  name: "origin",
  baseName: "origin",
  type: "string",
  format: ""
}, {
  name: "placeOfReceipt",
  baseName: "placeOfReceipt",
  type: "string",
  format: ""
}, {
  name: "placeOfDelivery",
  baseName: "placeOfDelivery",
  type: "string",
  format: ""
}, {
  name: "contacts",
  baseName: "contacts",
  type: "Array<ShipmentContact>",
  format: ""
}, {
  name: "goods",
  baseName: "goods",
  type: "Array<ShipmentGoods>",
  format: ""
}, {
  name: "documents",
  baseName: "documents",
  type: "Array<ShipmentDocument>",
  format: ""
}, {
  name: "loadingDateStart",
  baseName: "loadingDateStart",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingDateEnd",
  baseName: "loadingDateEnd",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingDateStart",
  baseName: "unloadingDateStart",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingDateEnd",
  baseName: "unloadingDateEnd",
  type: "Date",
  format: "date-time"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}, {
  name: "cargoReadyAt",
  baseName: "cargoReadyAt",
  type: "Date",
  format: "date-time"
}, {
  name: "originEta",
  baseName: "originEta",
  type: "Date",
  format: "date-time"
}, {
  name: "incoterm",
  baseName: "incoterm",
  type: "string",
  format: ""
}, {
  name: "customerReferences",
  baseName: "customerReferences",
  type: "Array<string>",
  format: ""
}, {
  name: "remarks",
  baseName: "remarks",
  type: "string",
  format: ""
}, {
  name: "originTms",
  baseName: "originTms",
  type: "string",
  format: ""
}, {
  name: "destinationTms",
  baseName: "destinationTms",
  type: "string",
  format: ""
}, {
  name: "isStackable",
  baseName: "isStackable",
  type: "boolean",
  format: ""
}, {
  name: "importance",
  baseName: "importance",
  type: "string",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "assignedUsers",
  baseName: "assignedUsers",
  type: "Array<ShipmentAssignedUser>",
  format: ""
}, {
  name: "masterBLNumber",
  baseName: "masterBLNumber",
  type: "string",
  format: ""
}, {
  name: "houseBLNumber",
  baseName: "houseBLNumber",
  type: "string",
  format: ""
}, {
  name: "houseBLIssuedAs",
  baseName: "houseBLIssuedAs",
  type: "string",
  format: ""
}, {
  name: "earliestArrival",
  baseName: "earliestArrival",
  type: "Date",
  format: "date-time"
}, {
  name: "earliestSailing",
  baseName: "earliestSailing",
  type: "Date",
  format: "date-time"
}, {
  name: "latestSailing",
  baseName: "latestSailing",
  type: "Date",
  format: "date-time"
}, {
  name: "latestArrival",
  baseName: "latestArrival",
  type: "Date",
  format: "date-time"
}, {
  name: "eta",
  baseName: "eta",
  type: "NVXShipmentJuncture",
  format: ""
}, {
  name: "etd",
  baseName: "etd",
  type: "NVXShipmentJuncture",
  format: ""
}, {
  name: "ata",
  baseName: "ata",
  type: "NVXShipmentJuncture",
  format: ""
}, {
  name: "atd",
  baseName: "atd",
  type: "NVXShipmentJuncture",
  format: ""
}, {
  name: "mostAdvancedScheduleStatus",
  baseName: "mostAdvancedScheduleStatus",
  type: "ScheduleStatus",
  format: ""
}, {
  name: "scheduleNumber",
  baseName: "scheduleNumber",
  type: "number",
  format: "int32"
}, {
  name: "carrier",
  baseName: "carrier",
  type: "string",
  format: ""
}, {
  name: "transportMode",
  baseName: "transportMode",
  type: "TransportMode",
  format: ""
}, {
  name: "vessel",
  baseName: "vessel",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "journeyEvents",
  baseName: "journeyEvents",
  type: "Array<JourneyEvent>",
  format: ""
}, {
  name: "transshipmentPort",
  baseName: "transshipmentPort",
  type: "string",
  format: ""
}, {
  name: "etaTranshipmentPort",
  baseName: "etaTranshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etdTranshipmentPort",
  baseName: "etdTranshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "transshipmentVessel",
  baseName: "transshipmentVessel",
  type: "string",
  format: ""
}, {
  name: "transshipmentIMONumber",
  baseName: "transshipmentIMONumber",
  type: "string",
  format: ""
}, {
  name: "chatChannels",
  baseName: "chatChannels",
  type: "Array<ChatChannel>",
  format: ""
}];