export class AddOrganizationByEmail {
  static getAttributeTypeMap() {
    return AddOrganizationByEmail.attributeTypeMap;
  }
  constructor(a) {
    this.email = a;
  }
}
AddOrganizationByEmail.discriminator = void 0, AddOrganizationByEmail.attributeTypeMap = [{
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}];