export class TenantReadModel {
  static getAttributeTypeMap() {
    return TenantReadModel.attributeTypeMap;
  }
  constructor(e, a, t) {
    this.id = e, this.name = a, this.enabled = t;
  }
}
TenantReadModel.discriminator = void 0, TenantReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "enabled",
  baseName: "enabled",
  type: "boolean",
  format: ""
}];