export class PublicAddressReadModel {
  static getAttributeTypeMap() {
    return PublicAddressReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i, d, m, n, o, b, c, p, y) {
    this.id = e, this.addressId = t, this.address1 = a, this.address2 = s, this.zip = r, this.city = i, this.area = d, this.country = m, this.state = n, this.notes = o, this.label = b, this.coordinates = c, this.tmsReference = p, this.createdAt = y;
  }
}
PublicAddressReadModel.discriminator = void 0, PublicAddressReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "address1",
  baseName: "address1",
  type: "string",
  format: ""
}, {
  name: "address2",
  baseName: "address2",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "area",
  baseName: "area",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}, {
  name: "label",
  baseName: "label",
  type: "string",
  format: ""
}, {
  name: "coordinates",
  baseName: "coordinates",
  type: "Position",
  format: ""
}, {
  name: "tmsReference",
  baseName: "tmsReference",
  type: "string",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];