export class MakeLocationPrivate {
  static getAttributeTypeMap() {
    return MakeLocationPrivate.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
MakeLocationPrivate.discriminator = void 0, MakeLocationPrivate.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];