export class ShipmentOrganization {
  static getAttributeTypeMap() {
    return ShipmentOrganization.attributeTypeMap;
  }
  constructor(t, i) {
    this.id = t, this.name = i;
  }
}
ShipmentOrganization.discriminator = void 0, ShipmentOrganization.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}];