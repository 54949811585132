export class UpdateTransshipment {
  static getAttributeTypeMap() {
    return UpdateTransshipment.attributeTypeMap;
  }
  constructor(t, e, a, s, r, m, n, i, p) {
    this.id = t, this.scheduleId = e, this.etaTransshipmentPort = a, this.ataTransshipmentPort = s, this.etdTransshipmentPort = r, this.atdTransshipmentPort = m, this.vesselName = n, this.imoNumber = i, this.transshipmentPortLocationId = p;
  }
}
UpdateTransshipment.discriminator = void 0, UpdateTransshipment.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "scheduleId",
  baseName: "scheduleId",
  type: "string",
  format: "uuid"
}, {
  name: "etaTransshipmentPort",
  baseName: "etaTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataTransshipmentPort",
  baseName: "ataTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etdTransshipmentPort",
  baseName: "etdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdTransshipmentPort",
  baseName: "atdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "transshipmentPortLocationId",
  baseName: "transshipmentPortLocationId",
  type: "string",
  format: "uuid"
}];