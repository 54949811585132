export class UserWithRoleReadModelPageResult {
  static getAttributeTypeMap() {
    return UserWithRoleReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
UserWithRoleReadModelPageResult.discriminator = void 0, UserWithRoleReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<UserWithRoleReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];