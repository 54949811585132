export class ContainerRate {
  static getAttributeTypeMap() {
    return ContainerRate.attributeTypeMap;
  }
  constructor(t, e) {
    this.containerType = t, this.rate = e;
  }
}
ContainerRate.discriminator = void 0, ContainerRate.attributeTypeMap = [{
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}, {
  name: "rate",
  baseName: "rate",
  type: "number",
  format: "double"
}];