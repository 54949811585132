export class ExternalInvoiceReadModel {
  static getAttributeTypeMap() {
    return ExternalInvoiceReadModel.attributeTypeMap;
  }
  constructor(e, t, a, i, n, s, m, o, r, c, d, u, l, b, p) {
    this.id = e, this.customerId = t, this.invoiceId = a, this.isCompleted = i, this.business = n, this.costAllocation = s, this.customerName = m, this.fileNumber = o, this.invoiceDate = r, this.dueDate = c, this.period = d, this.currency = u, this.invoiceBalance = l, this.outstandingBalance = b, this.tenantId = p;
  }
}
ExternalInvoiceReadModel.discriminator = void 0, ExternalInvoiceReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}, {
  name: "invoiceId",
  baseName: "invoiceId",
  type: "string",
  format: ""
}, {
  name: "isCompleted",
  baseName: "isCompleted",
  type: "boolean",
  format: ""
}, {
  name: "business",
  baseName: "business",
  type: "string",
  format: ""
}, {
  name: "costAllocation",
  baseName: "costAllocation",
  type: "string",
  format: ""
}, {
  name: "customerName",
  baseName: "customerName",
  type: "string",
  format: ""
}, {
  name: "fileNumber",
  baseName: "fileNumber",
  type: "string",
  format: ""
}, {
  name: "invoiceDate",
  baseName: "invoiceDate",
  type: "Date",
  format: "date-time"
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "period",
  baseName: "period",
  type: "string",
  format: ""
}, {
  name: "currency",
  baseName: "currency",
  type: "string",
  format: ""
}, {
  name: "invoiceBalance",
  baseName: "invoiceBalance",
  type: "number",
  format: "double"
}, {
  name: "outstandingBalance",
  baseName: "outstandingBalance",
  type: "number",
  format: "double"
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];