import { RequestContext } from "./http/http";
export class ServerConfiguration {
  constructor(t, e) {
    this.url = t, this.variableConfiguration = e;
  }
  setVariables(t) {
    Object.assign(this.variableConfiguration, t);
  }
  getConfiguration() {
    return this.variableConfiguration;
  }
  getUrl() {
    let t = this.url;
    for (const r in this.variableConfiguration) {
      var e = new RegExp("{" + r + "}", "g");
      t = t.replace(e, this.variableConfiguration[r]);
    }
    return t;
  }
  makeRequestContext(t, e) {
    return new RequestContext(this.getUrl() + t, e);
  }
}
export const server1 = new ServerConfiguration("", {});
export const servers = [server1];