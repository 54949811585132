export class CarrierContractReadModel {
  static getAttributeTypeMap() {
    return CarrierContractReadModel.attributeTypeMap;
  }
  constructor(t, a, e, r, i, m, n, o, d, s, c) {
    this.id = t, this.carrierContractId = a, this.carrierOrganizationId = e, this.contractNumber = r, this.validFrom = i, this.validTo = m, this.priority = n, this.comment = o, this.createdAt = d, this.updatedAt = s, this.tenantId = c;
  }
}
CarrierContractReadModel.discriminator = void 0, CarrierContractReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "carrierContractId",
  baseName: "carrierContractId",
  type: "string",
  format: "uuid"
}, {
  name: "carrierOrganizationId",
  baseName: "carrierOrganizationId",
  type: "string",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "validFrom",
  baseName: "validFrom",
  type: "Date",
  format: "date-time"
}, {
  name: "validTo",
  baseName: "validTo",
  type: "Date",
  format: "date-time"
}, {
  name: "priority",
  baseName: "priority",
  type: "number",
  format: "int32"
}, {
  name: "comment",
  baseName: "comment",
  type: "string",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}, {
  name: "updatedAt",
  baseName: "updatedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];