export function isCodeInRange(t, r) {
  if ("0" === t) return !0;
  if (t == r.toString()) return !0;
  {
    const n = r.toString();
    if (n.length != t.length) return !1;
    for (let r = 0; r < n.length; r++) if ("X" != t.charAt(r) && t.charAt(r) != n.charAt(r)) return !1;
    return !0;
  }
}
export function canConsumeForm(t) {
  return -1 !== t.indexOf("multipart/form-data");
}