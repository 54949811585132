export class UserSecurityReadModel {
  static getAttributeTypeMap() {
    return UserSecurityReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, i, r, n) {
    this.id = e, this.sub = t, this.email = a, this.fullName = s, this.applicationRoles = i, this.tenantRoles = r, this.tenants = n;
  }
}
UserSecurityReadModel.discriminator = void 0, UserSecurityReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "fullName",
  baseName: "fullName",
  type: "string",
  format: ""
}, {
  name: "applicationRoles",
  baseName: "applicationRoles",
  type: "Array<string>",
  format: ""
}, {
  name: "tenantRoles",
  baseName: "tenantRoles",
  type: "{ [key: string]: Array<string>; }",
  format: ""
}, {
  name: "tenants",
  baseName: "tenants",
  type: "Array<TenantDetails>",
  format: ""
}];