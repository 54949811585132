export class NotificationDelete {
  static getAttributeTypeMap() {
    return NotificationDelete.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
NotificationDelete.discriminator = void 0, NotificationDelete.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];