export class InitiateCustomerFullSync {
  static getAttributeTypeMap() {
    return InitiateCustomerFullSync.attributeTypeMap;
  }
  constructor(t) {
    this.customerId = t;
  }
}
InitiateCustomerFullSync.discriminator = void 0, InitiateCustomerFullSync.attributeTypeMap = [{
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}];