var __awaiter = this && this.__awaiter || function (e, t, i, r) {
  function o(e) {
    return e instanceof i ? e : new i(function (t) {
      t(e);
    });
  }
  return new (i || (i = Promise))(function (i, n) {
    function a(e) {
      try {
        s(r.next(e));
      } catch (t) {
        n(t);
      }
    }
    function d(e) {
      try {
        s(r["throw"](e));
      } catch (t) {
        n(t);
      }
    }
    function s(e) {
      e.done ? i(e.value) : o(e.value).then(a, d);
    }
    s((r = r.apply(e, t || [])).next());
  });
};
import { BaseAPIRequestFactory, RequiredError } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { isCodeInRange } from "../util";
export class CurrentUserApiRequestFactory extends BaseAPIRequestFactory {
  currentUserGet(e) {
    var t, i, r;
    return __awaiter(this, void 0, void 0, function* () {
      let o = e || this.configuration;
      const n = "/current-user",
        a = o.baseServer.makeRequestContext(n, HttpMethod.GET);
      let d;
      a.setHeaderParam("Accept", "application/json, */*;q=0.8"), d = o.authMethods["Bearer"], (null === d || void 0 === d ? void 0 : d.applySecurityAuthentication) && (yield null === d || void 0 === d ? void 0 : d.applySecurityAuthentication(a));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (r = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(a)), a;
    });
  }
  currentUserRolesGet(e) {
    var t, i, r;
    return __awaiter(this, void 0, void 0, function* () {
      let o = e || this.configuration;
      const n = "/current-user/roles",
        a = o.baseServer.makeRequestContext(n, HttpMethod.GET);
      let d;
      a.setHeaderParam("Accept", "application/json, */*;q=0.8"), d = o.authMethods["Bearer"], (null === d || void 0 === d ? void 0 : d.applySecurityAuthentication) && (yield null === d || void 0 === d ? void 0 : d.applySecurityAuthentication(a));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (r = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(a)), a;
    });
  }
  tenantTenantIdCurrentUserRolesGet(e, t) {
    var i, r, o;
    return __awaiter(this, void 0, void 0, function* () {
      let n = t || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("CurrentUserApi", "tenantTenantIdCurrentUserRolesGet", "tenantId");
      const a = "/tenant/{tenantId}/current-user/roles".replace("{tenantId}", encodeURIComponent(String(e))),
        d = n.baseServer.makeRequestContext(a, HttpMethod.GET);
      let s;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), s = n.authMethods["Bearer"], (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d));
      const u = (null === (i = null === t || void 0 === t ? void 0 : t.authMethods) || void 0 === i ? void 0 : i.default) || (null === (o = null === (r = this.configuration) || void 0 === r ? void 0 : r.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(d)), d;
    });
  }
}
export class CurrentUserApiResponseProcessor {
  currentUserGetWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "UserSecurity", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "UserSecurity", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  currentUserRolesGetWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "Array<string>", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "Array<string>", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  tenantTenantIdCurrentUserRolesGetWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "Array<string>", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "Array<string>", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
}