export class BillOfLadingBookmark {
  static getAttributeTypeMap() {
    return BillOfLadingBookmark.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i, m, o, d, n, p) {
    this.blNo = e, this.carrierNo = t, this.cntrNo = a, this.created = s, this.deleted = r, this.id = i, this.orgId = m, this.status = o, this.statusCode = d, this.systemDeleted = n, this.updated = p;
  }
}
BillOfLadingBookmark.discriminator = void 0, BillOfLadingBookmark.attributeTypeMap = [{
  name: "blNo",
  baseName: "blNo",
  type: "string",
  format: ""
}, {
  name: "carrierNo",
  baseName: "carrierNo",
  type: "string",
  format: ""
}, {
  name: "cntrNo",
  baseName: "cntrNo",
  type: "string",
  format: ""
}, {
  name: "created",
  baseName: "created",
  type: "Date",
  format: "date-time"
}, {
  name: "deleted",
  baseName: "deleted",
  type: "boolean",
  format: ""
}, {
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "orgId",
  baseName: "orgId",
  type: "string",
  format: "uuid"
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "statusCode",
  baseName: "statusCode",
  type: "string",
  format: ""
}, {
  name: "systemDeleted",
  baseName: "systemDeleted",
  type: "boolean",
  format: ""
}, {
  name: "updated",
  baseName: "updated",
  type: "Date",
  format: "date-time"
}];