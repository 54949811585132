export class UpdateMilestoneStatus {
  static getAttributeTypeMap() {
    return UpdateMilestoneStatus.attributeTypeMap;
  }
  constructor(t, e) {
    this.id = t, this.status = e;
  }
}
UpdateMilestoneStatus.discriminator = void 0, UpdateMilestoneStatus.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}];