export class DeleteContactPerson {
  static getAttributeTypeMap() {
    return DeleteContactPerson.attributeTypeMap;
  }
  constructor(t) {
    this.personId = t;
  }
}
DeleteContactPerson.discriminator = void 0, DeleteContactPerson.attributeTypeMap = [{
  name: "personId",
  baseName: "personId",
  type: "string",
  format: "uuid"
}];