export class UpdateMilestone {
  static getAttributeTypeMap() {
    return UpdateMilestone.attributeTypeMap;
  }
  constructor(e, t, a, s, i) {
    this.id = e, this.name = t, this.dueDate = a, this.status = s, this.userId = i;
  }
}
UpdateMilestone.discriminator = void 0, UpdateMilestone.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "userId",
  baseName: "userId",
  type: "string",
  format: ""
}];