export class SetShipmentStatus {
  static getAttributeTypeMap() {
    return SetShipmentStatus.attributeTypeMap;
  }
  constructor(t, e) {
    this.nvxId = t, this.status = e;
  }
}
SetShipmentStatus.discriminator = void 0, SetShipmentStatus.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}];