export class UpdateMyProfilePicture {
  static getAttributeTypeMap() {
    return UpdateMyProfilePicture.attributeTypeMap;
  }
  constructor(e) {
    this.file = e;
  }
}
UpdateMyProfilePicture.discriminator = void 0, UpdateMyProfilePicture.attributeTypeMap = [{
  name: "file",
  baseName: "file",
  type: "AttachedFile",
  format: ""
}];