export class SaveRoadShipmentDraft {
  static getAttributeTypeMap() {
    return SaveRoadShipmentDraft.attributeTypeMap;
  }
  constructor(e, t, a, n, i, s, o, m, r, d, g, u, c, p, l, h, f, y, b, N, D, T, S, I, R, v, A, F, k, M, x, q, w, L) {
    this.nvxId = e, this.bookingMode = t, this.warehouse = a, this.freightType = n, this.shipmentId = i, this.poNumber = s, this.contract = o, this.todaysDate = m, this.loadingDateSelection = r, this.loadingDate = d, this.unloadingDateSelection = g, this.unloadingDate = u, this.loadingTimeSelection = c, this.loadingTimeFrom = p, this.loadingTimeTo = l, this.unloadingTimeSelection = h, this.unloadingTimeFrom = f, this.unloadingTimeTo = y, this.sender = b, this.receiver = N, this.loadingAddress = D, this.unloadingAddress = T, this.loadingReference = S, this.unloadingReference = I, this.loadingInstructions = R, this.unloadingInstructions = v, this.incoterm = A, this.customsRequired = F, this.customsDocumentsLocation = k, this.customsDocumentsInstructions = M, this.remarks = x, this.shipmentDocuments = q, this.products = w, this.despatch = L;
  }
}
SaveRoadShipmentDraft.discriminator = void 0, SaveRoadShipmentDraft.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "bookingMode",
  baseName: "bookingMode",
  type: "string",
  format: ""
}, {
  name: "warehouse",
  baseName: "warehouse",
  type: "string",
  format: ""
}, {
  name: "freightType",
  baseName: "freightType",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "poNumber",
  baseName: "poNumber",
  type: "string",
  format: ""
}, {
  name: "contract",
  baseName: "contract",
  type: "string",
  format: ""
}, {
  name: "todaysDate",
  baseName: "todaysDate",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingDateSelection",
  baseName: "loadingDateSelection",
  type: "string",
  format: ""
}, {
  name: "loadingDate",
  baseName: "loadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingDateSelection",
  baseName: "unloadingDateSelection",
  type: "string",
  format: ""
}, {
  name: "unloadingDate",
  baseName: "unloadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingTimeSelection",
  baseName: "loadingTimeSelection",
  type: "string",
  format: ""
}, {
  name: "loadingTimeFrom",
  baseName: "loadingTimeFrom",
  type: "string",
  format: ""
}, {
  name: "loadingTimeTo",
  baseName: "loadingTimeTo",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeSelection",
  baseName: "unloadingTimeSelection",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeFrom",
  baseName: "unloadingTimeFrom",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeTo",
  baseName: "unloadingTimeTo",
  type: "string",
  format: ""
}, {
  name: "sender",
  baseName: "sender",
  type: "string",
  format: "uuid"
}, {
  name: "receiver",
  baseName: "receiver",
  type: "string",
  format: "uuid"
}, {
  name: "loadingAddress",
  baseName: "loadingAddress",
  type: "string",
  format: "uuid"
}, {
  name: "unloadingAddress",
  baseName: "unloadingAddress",
  type: "string",
  format: "uuid"
}, {
  name: "loadingReference",
  baseName: "loadingReference",
  type: "string",
  format: ""
}, {
  name: "unloadingReference",
  baseName: "unloadingReference",
  type: "string",
  format: ""
}, {
  name: "loadingInstructions",
  baseName: "loadingInstructions",
  type: "string",
  format: ""
}, {
  name: "unloadingInstructions",
  baseName: "unloadingInstructions",
  type: "string",
  format: ""
}, {
  name: "incoterm",
  baseName: "incoterm",
  type: "string",
  format: ""
}, {
  name: "customsRequired",
  baseName: "customsRequired",
  type: "boolean",
  format: ""
}, {
  name: "customsDocumentsLocation",
  baseName: "customsDocumentsLocation",
  type: "string",
  format: ""
}, {
  name: "customsDocumentsInstructions",
  baseName: "customsDocumentsInstructions",
  type: "string",
  format: ""
}, {
  name: "remarks",
  baseName: "remarks",
  type: "string",
  format: ""
}, {
  name: "shipmentDocuments",
  baseName: "shipmentDocuments",
  type: "Array<AttachedFile>",
  format: ""
}, {
  name: "products",
  baseName: "products",
  type: "Array<ShipmentWarehouseProduct>",
  format: ""
}, {
  name: "despatch",
  baseName: "despatch",
  type: "string",
  format: "uuid"
}];