export class CreateShipmentDirectory {
  static getAttributeTypeMap() {
    return CreateShipmentDirectory.attributeTypeMap;
  }
  constructor(t, e, r) {
    this.shipmentId = t, this.role = e, this.note = r;
  }
}
CreateShipmentDirectory.discriminator = void 0, CreateShipmentDirectory.attributeTypeMap = [{
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}];