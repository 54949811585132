export class CreateOrganizationDirectory {
  static getAttributeTypeMap() {
    return CreateOrganizationDirectory.attributeTypeMap;
  }
  constructor(t, a, i) {
    this.organizationId = t, this.organizationAlias = a, this.note = i;
  }
}
CreateOrganizationDirectory.discriminator = void 0, CreateOrganizationDirectory.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}];