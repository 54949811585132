export class SeaShipmentReadModel {
  static getAttributeTypeMap() {
    return SeaShipmentReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i, n, m, o, p, d, h, y, c, g, b, f, u, N, l, S, A, v, T, I) {
    this.id = e, this.shipmentId = t, this.assignedTo = a, this.fileStatus = s, this.poNumbers = r, this.shipmentType = i, this.originAgent = n, this.destinationAgent = m, this.shipper = o, this.consignee = p, this.pol = d, this.pod = h, this.crd = y, this.arrival = c, this.departure = g, this.carrier = b, this.vessel = f, this.voyage = u, this.contractNumber = N, this.creationDate = l, this.containerType = S, this.rate = A, this.statusOfSchedule = v, this.mostAdvancedScheduleId = T, this.tenantId = I;
  }
}
SeaShipmentReadModel.discriminator = void 0, SeaShipmentReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "assignedTo",
  baseName: "assignedTo",
  type: "Array<AssignedUserReference>",
  format: ""
}, {
  name: "fileStatus",
  baseName: "fileStatus",
  type: "string",
  format: ""
}, {
  name: "poNumbers",
  baseName: "poNumbers",
  type: "Array<string>",
  format: ""
}, {
  name: "shipmentType",
  baseName: "shipmentType",
  type: "string",
  format: ""
}, {
  name: "originAgent",
  baseName: "originAgent",
  type: "string",
  format: ""
}, {
  name: "destinationAgent",
  baseName: "destinationAgent",
  type: "string",
  format: ""
}, {
  name: "shipper",
  baseName: "shipper",
  type: "string",
  format: ""
}, {
  name: "consignee",
  baseName: "consignee",
  type: "string",
  format: ""
}, {
  name: "pol",
  baseName: "pol",
  type: "string",
  format: ""
}, {
  name: "pod",
  baseName: "pod",
  type: "string",
  format: ""
}, {
  name: "crd",
  baseName: "crd",
  type: "Date",
  format: "date-time"
}, {
  name: "arrival",
  baseName: "arrival",
  type: "Date",
  format: "date-time"
}, {
  name: "departure",
  baseName: "departure",
  type: "Date",
  format: "date-time"
}, {
  name: "carrier",
  baseName: "carrier",
  type: "string",
  format: ""
}, {
  name: "vessel",
  baseName: "vessel",
  type: "string",
  format: ""
}, {
  name: "voyage",
  baseName: "voyage",
  type: "string",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "creationDate",
  baseName: "creationDate",
  type: "Date",
  format: "date-time"
}, {
  name: "containerType",
  baseName: "containerType",
  type: "Array<ContainerTypeReference>",
  format: ""
}, {
  name: "rate",
  baseName: "rate",
  type: "Array<ContainerRate>",
  format: ""
}, {
  name: "statusOfSchedule",
  baseName: "statusOfSchedule",
  type: "ScheduleStatus",
  format: ""
}, {
  name: "mostAdvancedScheduleId",
  baseName: "mostAdvancedScheduleId",
  type: "string",
  format: "uuid"
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];