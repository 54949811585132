export class ContainerJourneyReadModel {
  static getAttributeTypeMap() {
    return ContainerJourneyReadModel.attributeTypeMap;
  }
  constructor(e, a, t, o, i, n, s, r, m, l) {
    this.id = e, this.blNo = a, this.carrierNo = t, this.cntrNo = o, this.isCompleted = i, this.events = n, this.billOfLadingBookmark = s, this.billOfLading = r, this.statusInfo = m, this.co2Emissions = l;
  }
}
ContainerJourneyReadModel.discriminator = void 0, ContainerJourneyReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "blNo",
  baseName: "blNo",
  type: "string",
  format: ""
}, {
  name: "carrierNo",
  baseName: "carrierNo",
  type: "string",
  format: ""
}, {
  name: "cntrNo",
  baseName: "cntrNo",
  type: "string",
  format: ""
}, {
  name: "isCompleted",
  baseName: "isCompleted",
  type: "boolean",
  format: ""
}, {
  name: "events",
  baseName: "events",
  type: "Array<ContainerEvent>",
  format: ""
}, {
  name: "billOfLadingBookmark",
  baseName: "billOfLadingBookmark",
  type: "BillOfLadingBookmark",
  format: ""
}, {
  name: "billOfLading",
  baseName: "billOfLading",
  type: "BillOfLading",
  format: ""
}, {
  name: "statusInfo",
  baseName: "statusInfo",
  type: "StatusInfo",
  format: ""
}, {
  name: "co2Emissions",
  baseName: "co2Emissions",
  type: "Co2Emissions",
  format: ""
}];