export class RevokeApplicationRole {
  static getAttributeTypeMap() {
    return RevokeApplicationRole.attributeTypeMap;
  }
  constructor(e, t) {
    this.sub = e, this.role = t;
  }
}
RevokeApplicationRole.discriminator = void 0, RevokeApplicationRole.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];