export class UpdatedField {
  static getAttributeTypeMap() {
    return UpdatedField.attributeTypeMap;
  }
  constructor(e, a, t, i) {
    this.fieldName = e, this.initialValue = a, this.previousValue = t, this.currentValue = i;
  }
}
UpdatedField.discriminator = void 0, UpdatedField.attributeTypeMap = [{
  name: "fieldName",
  baseName: "fieldName",
  type: "string",
  format: ""
}, {
  name: "initialValue",
  baseName: "initialValue",
  type: "string",
  format: ""
}, {
  name: "previousValue",
  baseName: "previousValue",
  type: "string",
  format: ""
}, {
  name: "currentValue",
  baseName: "currentValue",
  type: "string",
  format: ""
}];