export class OrganizationContactPerson {
  static getAttributeTypeMap() {
    return OrganizationContactPerson.attributeTypeMap;
  }
  constructor(t, a, e, n, o, i, r) {
    this.contactId = t, this.firstName = a, this.lastName = e, this.jobTitle = n, this.email = o, this.phoneCountryCode = i, this.phone = r;
  }
}
OrganizationContactPerson.discriminator = void 0, OrganizationContactPerson.attributeTypeMap = [{
  name: "contactId",
  baseName: "contactId",
  type: "string",
  format: "uuid"
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}];