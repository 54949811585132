export class RoadShipmentReadModel {
  static getAttributeTypeMap() {
    return RoadShipmentReadModel.attributeTypeMap;
  }
  constructor(e, t, a, n, i, o, s, m, r, d, g, u, c, l, p, h, y, b, f, N, T, D, S, R, I, M, A, k, F, v, q, w, L, x) {
    this.id = e, this.bookingMode = t, this.warehouse = a, this.freightType = n, this.shipmentId = i, this.poNumber = o, this.contract = s, this.todaysDate = m, this.loadingDateSelection = r, this.loadingDate = d, this.unloadingDateSelection = g, this.unloadingDate = u, this.loadingTimeSelection = c, this.loadingTimeFrom = l, this.loadingTimeTo = p, this.unloadingTimeSelection = h, this.unloadingTimeFrom = y, this.unloadingTimeTo = b, this.sender = f, this.receiver = N, this.loadingAddress = T, this.unloadingAddress = D, this.loadingReference = S, this.unloadingReference = R, this.loadingInstructions = I, this.unloadingInstructions = M, this.incoterm = A, this.customsRequired = k, this.customsDocumentsLocation = F, this.customsDocumentsInstructions = v, this.remarks = q, this.tenantId = w, this.shipmentDocuments = L, this.products = x;
  }
}
RoadShipmentReadModel.discriminator = void 0, RoadShipmentReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "bookingMode",
  baseName: "bookingMode",
  type: "string",
  format: ""
}, {
  name: "warehouse",
  baseName: "warehouse",
  type: "string",
  format: ""
}, {
  name: "freightType",
  baseName: "freightType",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "poNumber",
  baseName: "poNumber",
  type: "string",
  format: ""
}, {
  name: "contract",
  baseName: "contract",
  type: "string",
  format: ""
}, {
  name: "todaysDate",
  baseName: "todaysDate",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingDateSelection",
  baseName: "loadingDateSelection",
  type: "string",
  format: ""
}, {
  name: "loadingDate",
  baseName: "loadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingDateSelection",
  baseName: "unloadingDateSelection",
  type: "string",
  format: ""
}, {
  name: "unloadingDate",
  baseName: "unloadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingTimeSelection",
  baseName: "loadingTimeSelection",
  type: "string",
  format: ""
}, {
  name: "loadingTimeFrom",
  baseName: "loadingTimeFrom",
  type: "string",
  format: ""
}, {
  name: "loadingTimeTo",
  baseName: "loadingTimeTo",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeSelection",
  baseName: "unloadingTimeSelection",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeFrom",
  baseName: "unloadingTimeFrom",
  type: "string",
  format: ""
}, {
  name: "unloadingTimeTo",
  baseName: "unloadingTimeTo",
  type: "string",
  format: ""
}, {
  name: "sender",
  baseName: "sender",
  type: "string",
  format: "uuid"
}, {
  name: "receiver",
  baseName: "receiver",
  type: "string",
  format: "uuid"
}, {
  name: "loadingAddress",
  baseName: "loadingAddress",
  type: "string",
  format: "uuid"
}, {
  name: "unloadingAddress",
  baseName: "unloadingAddress",
  type: "string",
  format: "uuid"
}, {
  name: "loadingReference",
  baseName: "loadingReference",
  type: "string",
  format: ""
}, {
  name: "unloadingReference",
  baseName: "unloadingReference",
  type: "string",
  format: ""
}, {
  name: "loadingInstructions",
  baseName: "loadingInstructions",
  type: "string",
  format: ""
}, {
  name: "unloadingInstructions",
  baseName: "unloadingInstructions",
  type: "string",
  format: ""
}, {
  name: "incoterm",
  baseName: "incoterm",
  type: "string",
  format: ""
}, {
  name: "customsRequired",
  baseName: "customsRequired",
  type: "boolean",
  format: ""
}, {
  name: "customsDocumentsLocation",
  baseName: "customsDocumentsLocation",
  type: "string",
  format: ""
}, {
  name: "customsDocumentsInstructions",
  baseName: "customsDocumentsInstructions",
  type: "string",
  format: ""
}, {
  name: "remarks",
  baseName: "remarks",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "shipmentDocuments",
  baseName: "shipmentDocuments",
  type: "Array<ShipmentDocument>",
  format: ""
}, {
  name: "products",
  baseName: "products",
  type: "Array<ShipmentWarehouseProduct>",
  format: ""
}];