export class MilestoneReadModel {
  static getAttributeTypeMap() {
    return MilestoneReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, m, i, n, d, r, o, p) {
    this.id = e, this.shipmentId = t, this.name = a, this.dueDate = s, this.originalDueDate = m, this.completedAt = i, this.userId = n, this.status = d, this.tenantId = r, this.autoGenerated = o, this.createdAt = p;
  }
}
MilestoneReadModel.discriminator = void 0, MilestoneReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "originalDueDate",
  baseName: "originalDueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "completedAt",
  baseName: "completedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "userId",
  baseName: "userId",
  type: "string",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "autoGenerated",
  baseName: "autoGenerated",
  type: "boolean",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];