export class CreateTransshipment {
  static getAttributeTypeMap() {
    return CreateTransshipment.attributeTypeMap;
  }
  constructor(t, e, a, s, r, m, n, i) {
    this.scheduleId = t, this.etaTransshipmentPort = e, this.ataTransshipmentPort = a, this.etdTransshipmentPort = s, this.atdTransshipmentPort = r, this.vesselName = m, this.imoNumber = n, this.transshipmentPortLocationId = i;
  }
}
CreateTransshipment.discriminator = void 0, CreateTransshipment.attributeTypeMap = [{
  name: "scheduleId",
  baseName: "scheduleId",
  type: "string",
  format: "uuid"
}, {
  name: "etaTransshipmentPort",
  baseName: "etaTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataTransshipmentPort",
  baseName: "ataTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etdTransshipmentPort",
  baseName: "etdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdTransshipmentPort",
  baseName: "atdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "transshipmentPortLocationId",
  baseName: "transshipmentPortLocationId",
  type: "string",
  format: "uuid"
}];