export class ErrorResponse {
  static getAttributeTypeMap() {
    return ErrorResponse.attributeTypeMap;
  }
  constructor(r, e) {
    this.message = r, this.errors = e;
  }
}
ErrorResponse.discriminator = void 0, ErrorResponse.attributeTypeMap = [{
  name: "message",
  baseName: "message",
  type: "string",
  format: ""
}, {
  name: "errors",
  baseName: "errors",
  type: "Array<string>",
  format: ""
}];