export class UserSecurity {
  static getAttributeTypeMap() {
    return UserSecurity.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i) {
    this.sub = e, this.email = t, this.fullName = a, this.applicationRoles = s, this.tenantRoles = r, this.tenants = i;
  }
}
UserSecurity.discriminator = void 0, UserSecurity.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "fullName",
  baseName: "fullName",
  type: "string",
  format: ""
}, {
  name: "applicationRoles",
  baseName: "applicationRoles",
  type: "Array<string>",
  format: ""
}, {
  name: "tenantRoles",
  baseName: "tenantRoles",
  type: "{ [key: string]: Array<string>; }",
  format: ""
}, {
  name: "tenants",
  baseName: "tenants",
  type: "Array<TenantDetails>",
  format: ""
}];