export class NotificationMarkAsFavorite {
  static getAttributeTypeMap() {
    return NotificationMarkAsFavorite.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
NotificationMarkAsFavorite.discriminator = void 0, NotificationMarkAsFavorite.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];