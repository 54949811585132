export class LocationReadModelPageResult {
  static getAttributeTypeMap() {
    return LocationReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
LocationReadModelPageResult.discriminator = void 0, LocationReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<LocationReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];