export class UpdateUserProfilePicture {
  static getAttributeTypeMap() {
    return UpdateUserProfilePicture.attributeTypeMap;
  }
  constructor(e, t) {
    this.userSub = e, this.file = t;
  }
}
UpdateUserProfilePicture.discriminator = void 0, UpdateUserProfilePicture.attributeTypeMap = [{
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "file",
  baseName: "file",
  type: "AttachedFile",
  format: ""
}];