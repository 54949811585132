export class CarrierOrganizationReadModel {
  static getAttributeTypeMap() {
    return CarrierOrganizationReadModel.attributeTypeMap;
  }
  constructor(a, e, t, i, n, r, s, o, m, b, g, p, y, c, d, h, f, l, N, u, A, z) {
    this.id = a, this.organizationId = e, this.name = t, this.organizationType = i, this.nameAlias = n, this.organizationAlias = r, this.email = s, this.eori = o, this.vatNumber = m, this.phone = b, this.fax = g, this.website = p, this.contactPhone = y, this.legalEntityType = c, this.note = d, this.tags = h, this.creatorSub = f, this.contactPersonId = l, this.tenantId = N, this.isPublic = u, this.isActive = A, this.createdAt = z;
  }
}
CarrierOrganizationReadModel.discriminator = void 0, CarrierOrganizationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "organizationType",
  baseName: "organizationType",
  type: "Array<string>",
  format: ""
}, {
  name: "nameAlias",
  baseName: "nameAlias",
  type: "string",
  format: ""
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "eori",
  baseName: "eori",
  type: "string",
  format: ""
}, {
  name: "vatNumber",
  baseName: "vatNumber",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "fax",
  baseName: "fax",
  type: "string",
  format: ""
}, {
  name: "website",
  baseName: "website",
  type: "string",
  format: ""
}, {
  name: "contactPhone",
  baseName: "contactPhone",
  type: "string",
  format: ""
}, {
  name: "legalEntityType",
  baseName: "legalEntityType",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}, {
  name: "creatorSub",
  baseName: "creatorSub",
  type: "string",
  format: ""
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "isPublic",
  baseName: "isPublic",
  type: "boolean",
  format: ""
}, {
  name: "isActive",
  baseName: "isActive",
  type: "boolean",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];