export class CreateContactPerson {
  static getAttributeTypeMap() {
    return CreateContactPerson.attributeTypeMap;
  }
  constructor(t, e, a, o, n, i, r, s) {
    this.organizationId = t, this.locationId = e, this.firstName = a, this.lastName = o, this.jobTitle = n, this.email = i, this.phoneCountryCode = r, this.phone = s;
  }
}
CreateContactPerson.discriminator = void 0, CreateContactPerson.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}];