export class SystemGeneratedCarrierOrganizationReadModel {
  static getAttributeTypeMap() {
    return SystemGeneratedCarrierOrganizationReadModel.attributeTypeMap;
  }
  constructor(e, a, t, i, n, r, s, m, o, y, b, g, p, d, c, h, l, f, N, u, A, z) {
    this.id = e, this.organizationId = a, this.name = t, this.organizationType = i, this.nameAlias = n, this.organizationAlias = r, this.email = s, this.eori = m, this.vatNumber = o, this.phone = y, this.fax = b, this.website = g, this.contactPhone = p, this.legalEntityType = d, this.note = c, this.tags = h, this.creatorSub = l, this.contactPersonId = f, this.isPublic = N, this.isActive = u, this.systemGenerated = A, this.createdAt = z;
  }
}
SystemGeneratedCarrierOrganizationReadModel.discriminator = void 0, SystemGeneratedCarrierOrganizationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "organizationType",
  baseName: "organizationType",
  type: "Array<string>",
  format: ""
}, {
  name: "nameAlias",
  baseName: "nameAlias",
  type: "string",
  format: ""
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "eori",
  baseName: "eori",
  type: "string",
  format: ""
}, {
  name: "vatNumber",
  baseName: "vatNumber",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "fax",
  baseName: "fax",
  type: "string",
  format: ""
}, {
  name: "website",
  baseName: "website",
  type: "string",
  format: ""
}, {
  name: "contactPhone",
  baseName: "contactPhone",
  type: "string",
  format: ""
}, {
  name: "legalEntityType",
  baseName: "legalEntityType",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}, {
  name: "creatorSub",
  baseName: "creatorSub",
  type: "string",
  format: ""
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}, {
  name: "isPublic",
  baseName: "isPublic",
  type: "boolean",
  format: ""
}, {
  name: "isActive",
  baseName: "isActive",
  type: "boolean",
  format: ""
}, {
  name: "systemGenerated",
  baseName: "systemGenerated",
  type: "boolean",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];