export class ChatChannelReadModel {
  static getAttributeTypeMap() {
    return ChatChannelReadModel.attributeTypeMap;
  }
  constructor(e, a, t, s, n, i, r, m) {
    this.id = e, this.channelId = a, this.name = t, this.hasUnreadMessages = s, this.isArchived = n, this.lastActivity = i, this.userSub = r, this.messages = m;
  }
}
ChatChannelReadModel.discriminator = void 0, ChatChannelReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "hasUnreadMessages",
  baseName: "hasUnreadMessages",
  type: "boolean",
  format: ""
}, {
  name: "isArchived",
  baseName: "isArchived",
  type: "boolean",
  format: ""
}, {
  name: "lastActivity",
  baseName: "lastActivity",
  type: "Date",
  format: "date-time"
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "messages",
  baseName: "messages",
  type: "Array<ChatChannelMessage>",
  format: ""
}];