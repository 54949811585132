export class NotificationMarkAsUnfavorite {
  static getAttributeTypeMap() {
    return NotificationMarkAsUnfavorite.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
NotificationMarkAsUnfavorite.discriminator = void 0, NotificationMarkAsUnfavorite.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];