export class ChangeScheduleStatus {
  static getAttributeTypeMap() {
    return ChangeScheduleStatus.attributeTypeMap;
  }
  constructor(t, a, e, s, i, u) {
    this.id = t, this.status = a, this.updateReason = e, this.updateExplanation = s, this.updateResponsibleParty = i, this.updateDetails = u;
  }
}
ChangeScheduleStatus.discriminator = void 0, ChangeScheduleStatus.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "status",
  baseName: "status",
  type: "ScheduleStatus",
  format: ""
}, {
  name: "updateReason",
  baseName: "updateReason",
  type: "string",
  format: ""
}, {
  name: "updateExplanation",
  baseName: "updateExplanation",
  type: "string",
  format: ""
}, {
  name: "updateResponsibleParty",
  baseName: "updateResponsibleParty",
  type: "string",
  format: ""
}, {
  name: "updateDetails",
  baseName: "updateDetails",
  type: "string",
  format: ""
}];