export class RestoreLocation {
  static getAttributeTypeMap() {
    return RestoreLocation.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
RestoreLocation.discriminator = void 0, RestoreLocation.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];