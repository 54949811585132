export class AdditionalRolesReadModel {
  static getAttributeTypeMap() {
    return AdditionalRolesReadModel.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.id = e, this.name = t, this.description = a;
  }
}
AdditionalRolesReadModel.discriminator = void 0, AdditionalRolesReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];