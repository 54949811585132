export class EditChatMessage {
  static getAttributeTypeMap() {
    return EditChatMessage.attributeTypeMap;
  }
  constructor(e, a, t) {
    this.channelId = e, this.messageId = a, this.message = t;
  }
}
EditChatMessage.discriminator = void 0, EditChatMessage.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "messageId",
  baseName: "messageId",
  type: "string",
  format: "uuid"
}, {
  name: "message",
  baseName: "message",
  type: "string",
  format: ""
}];