export class RemoveGoodsFromShipment {
  static getAttributeTypeMap() {
    return RemoveGoodsFromShipment.attributeTypeMap;
  }
  constructor(e, t) {
    this.id = e, this.nvxId = t;
  }
}
RemoveGoodsFromShipment.discriminator = void 0, RemoveGoodsFromShipment.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];