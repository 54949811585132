export class CreateCarrierContract {
  static getAttributeTypeMap() {
    return CreateCarrierContract.attributeTypeMap;
  }
  constructor(t, r, a, e, i, m) {
    this.carrierOrganizationId = t, this.contractNumber = r, this.validFrom = a, this.validTo = e, this.priority = i, this.comment = m;
  }
}
CreateCarrierContract.discriminator = void 0, CreateCarrierContract.attributeTypeMap = [{
  name: "carrierOrganizationId",
  baseName: "carrierOrganizationId",
  type: "string",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "validFrom",
  baseName: "validFrom",
  type: "Date",
  format: "date-time"
}, {
  name: "validTo",
  baseName: "validTo",
  type: "Date",
  format: "date-time"
}, {
  name: "priority",
  baseName: "priority",
  type: "number",
  format: "int32"
}, {
  name: "comment",
  baseName: "comment",
  type: "string",
  format: ""
}];