export class ShipmentOverviewReadModel {
  static getAttributeTypeMap() {
    return ShipmentOverviewReadModel.attributeTypeMap;
  }
  constructor(e, t, a, d, i, s, n, m, r, o, p, g, b, l, u, h, f) {
    this.id = e, this.shipmentId = t, this.poNumber = a, this.bookingMode = d, this.loadingDate = i, this.loadingAddressId = s, this.loadingAddress = n, this.unloadingDate = m, this.unloadingAddressId = r, this.unloadingAddress = o, this.isConfirmed = p, this.createdDate = g, this.modifiedDate = b, this.wmsId = l, this.completedPercentage = u, this.status = h, this.tenantId = f;
  }
}
ShipmentOverviewReadModel.discriminator = void 0, ShipmentOverviewReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "poNumber",
  baseName: "poNumber",
  type: "string",
  format: ""
}, {
  name: "bookingMode",
  baseName: "bookingMode",
  type: "string",
  format: ""
}, {
  name: "loadingDate",
  baseName: "loadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingAddressId",
  baseName: "loadingAddressId",
  type: "string",
  format: "uuid"
}, {
  name: "loadingAddress",
  baseName: "loadingAddress",
  type: "ShipmentOverviewAddress",
  format: ""
}, {
  name: "unloadingDate",
  baseName: "unloadingDate",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingAddressId",
  baseName: "unloadingAddressId",
  type: "string",
  format: "uuid"
}, {
  name: "unloadingAddress",
  baseName: "unloadingAddress",
  type: "ShipmentOverviewAddress",
  format: ""
}, {
  name: "isConfirmed",
  baseName: "isConfirmed",
  type: "boolean",
  format: ""
}, {
  name: "createdDate",
  baseName: "createdDate",
  type: "Date",
  format: "date-time"
}, {
  name: "modifiedDate",
  baseName: "modifiedDate",
  type: "Date",
  format: "date-time"
}, {
  name: "wmsId",
  baseName: "wmsId",
  type: "string",
  format: ""
}, {
  name: "completedPercentage",
  baseName: "completedPercentage",
  type: "number",
  format: "int32"
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];