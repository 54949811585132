var __awaiter = this && this.__awaiter || function (e, t, i, r) {
  function o(e) {
    return e instanceof i ? e : new i(function (t) {
      t(e);
    });
  }
  return new (i || (i = Promise))(function (i, a) {
    function n(e) {
      try {
        s(r.next(e));
      } catch (t) {
        a(t);
      }
    }
    function d(e) {
      try {
        s(r["throw"](e));
      } catch (t) {
        a(t);
      }
    }
    function s(e) {
      e.done ? i(e.value) : o(e.value).then(n, d);
    }
    s((r = r.apply(e, t || [])).next());
  });
};
import { BaseAPIRequestFactory, RequiredError } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { isCodeInRange } from "../util";
export class ValidationRulesManagementApiRequestFactory extends BaseAPIRequestFactory {
  getValidationRule(e, t) {
    var i, r, o;
    return __awaiter(this, void 0, void 0, function* () {
      let a = t || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("ValidationRulesManagementApi", "getValidationRule", "id");
      const n = "/read-models/validation-rule/{id}".replace("{id}", encodeURIComponent(String(e))),
        d = a.baseServer.makeRequestContext(n, HttpMethod.GET);
      let s;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), s = a.authMethods["Bearer"], (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d));
      const l = (null === (i = null === t || void 0 === t ? void 0 : t.authMethods) || void 0 === i ? void 0 : i.default) || (null === (o = null === (r = this.configuration) || void 0 === r ? void 0 : r.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === l || void 0 === l ? void 0 : l.applySecurityAuthentication) && (yield null === l || void 0 === l ? void 0 : l.applySecurityAuthentication(d)), d;
    });
  }
  listValidationRule(e, t, i, r, o, a, n, d, s, l) {
    var u, p, c;
    return __awaiter(this, void 0, void 0, function* () {
      let h = l || this.configuration;
      const S = "/read-models/validation-rule",
        y = h.baseServer.makeRequestContext(S, HttpMethod.GET);
      let z;
      y.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && y.setQueryParam("sortField", ObjectSerializer.serialize(t, "'Id' | 'CommandName'", "")), void 0 !== i && y.setQueryParam("sortDirection", ObjectSerializer.serialize(i, "'ascending' | 'descending'", "")), void 0 !== r && y.setQueryParam("pageNumber", ObjectSerializer.serialize(r, "number", "")), void 0 !== o && y.setQueryParam("pageSize", ObjectSerializer.serialize(o, "number", "")), void 0 !== a && y.setQueryParam("ts-Id", ObjectSerializer.serialize(a, "string", "")), void 0 !== n && y.setQueryParam("ts-CommandName", ObjectSerializer.serialize(n, "string", "")), void 0 !== d && y.setQueryParam("eq-Id", ObjectSerializer.serialize(d, "Array<string>", "")), void 0 !== s && y.setQueryParam("eq-CommandName", ObjectSerializer.serialize(s, "Array<string>", "")), void 0 !== e && y.setHeaderParam("If-None-Match", ObjectSerializer.serialize(e, "string", "")), z = h.authMethods["Bearer"], (null === z || void 0 === z ? void 0 : z.applySecurityAuthentication) && (yield null === z || void 0 === z ? void 0 : z.applySecurityAuthentication(y));
      const v = (null === (u = null === l || void 0 === l ? void 0 : l.authMethods) || void 0 === u ? void 0 : u.default) || (null === (c = null === (p = this.configuration) || void 0 === p ? void 0 : p.authMethods) || void 0 === c ? void 0 : c.default);
      return (null === v || void 0 === v ? void 0 : v.applySecurityAuthentication) && (yield null === v || void 0 === v ? void 0 : v.applySecurityAuthentication(y)), y;
    });
  }
  removeValidationRule(e, t, i) {
    var r, o, a;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("ValidationRulesManagementApi", "removeValidationRule", "removeValidationRule");
      const d = "/commands/remove-validation-rule",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const u = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "RemoveValidationRule", ""), l);
      let p;
      s.setBody(u), p = n.authMethods["Bearer"], (null === p || void 0 === p ? void 0 : p.applySecurityAuthentication) && (yield null === p || void 0 === p ? void 0 : p.applySecurityAuthentication(s));
      const c = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (a = null === (o = this.configuration) || void 0 === o ? void 0 : o.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s)), s;
    });
  }
  setValidationRule(e, t, i) {
    var r, o, a;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("ValidationRulesManagementApi", "setValidationRule", "setValidationRule");
      const d = "/commands/set-validation-rule",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const u = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "SetValidationRule", ""), l);
      let p;
      s.setBody(u), p = n.authMethods["Bearer"], (null === p || void 0 === p ? void 0 : p.applySecurityAuthentication) && (yield null === p || void 0 === p ? void 0 : p.applySecurityAuthentication(s));
      const c = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (a = null === (o = this.configuration) || void 0 === o ? void 0 : o.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s)), s;
    });
  }
}
export class ValidationRulesManagementApiResponseProcessor {
  getValidationRuleWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ValidationRuleReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("404", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Not Found", i, e.headers);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ValidationRuleReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  listValidationRuleWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ValidationRuleReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ValidationRuleReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  removeValidationRuleWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  setValidationRuleWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
}