export class DeleteMilestone {
  static getAttributeTypeMap() {
    return DeleteMilestone.attributeTypeMap;
  }
  constructor(e) {
    this.id = e;
  }
}
DeleteMilestone.discriminator = void 0, DeleteMilestone.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];