export class ValidationDetails {
  static getAttributeTypeMap() {
    return ValidationDetails.attributeTypeMap;
  }
  constructor(t, a) {
    this.reason = t, this.rejectionReasons = a;
  }
}
ValidationDetails.discriminator = void 0, ValidationDetails.attributeTypeMap = [{
  name: "reason",
  baseName: "reason",
  type: "string",
  format: ""
}, {
  name: "rejectionReasons",
  baseName: "rejectionReasons",
  type: "Array<string>",
  format: ""
}];