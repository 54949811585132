export class AssignUserToShipment {
  static getAttributeTypeMap() {
    return AssignUserToShipment.attributeTypeMap;
  }
  constructor(t, e, n, a) {
    this.nvxId = t, this.role = e, this.organizationId = n, this.contactPersonId = a;
  }
}
AssignUserToShipment.discriminator = void 0, AssignUserToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}];