export class ScheduleReadModel {
  static getAttributeTypeMap() {
    return ScheduleReadModel.attributeTypeMap;
  }
  constructor(e, t, a, r, m, i, s, n, o, d, b, u, f, N, y, c, p, h, g, l, D, O, P, C, M, T, v) {
    this.id = e, this.shipmentId = t, this.status = a, this.etdOriginPort = r, this.atdOriginPort = m, this.etaDestinationPort = i, this.ataDestinationPort = s, this.carrierBookingNumber = n, this.carrierSoNumber = o, this.carrierOrganizationId = d, this.transportMode = b, this.vesselName = u, this.imoNumber = f, this.voyageNumber = N, this.mblPaymentTerm = y, this.contractOwner = c, this.contractNumber = p, this.sideTerms = h, this.detDemDetails = g, this.detDetails = l, this.demDetails = D, this.vgmCutOff = O, this.cyCutOff = P, this.siCutOff = C, this.containerRates = M, this.tenantId = T, this.changeLog = v;
  }
}
ScheduleReadModel.discriminator = void 0, ScheduleReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "ScheduleStatus",
  format: ""
}, {
  name: "etdOriginPort",
  baseName: "etdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdOriginPort",
  baseName: "atdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etaDestinationPort",
  baseName: "etaDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataDestinationPort",
  baseName: "ataDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "carrierBookingNumber",
  baseName: "carrierBookingNumber",
  type: "string",
  format: ""
}, {
  name: "carrierSoNumber",
  baseName: "carrierSoNumber",
  type: "string",
  format: ""
}, {
  name: "carrierOrganizationId",
  baseName: "carrierOrganizationId",
  type: "string",
  format: "uuid"
}, {
  name: "transportMode",
  baseName: "transportMode",
  type: "TransportMode",
  format: ""
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "voyageNumber",
  baseName: "voyageNumber",
  type: "string",
  format: ""
}, {
  name: "mblPaymentTerm",
  baseName: "mblPaymentTerm",
  type: "MBLPaymentTerm",
  format: ""
}, {
  name: "contractOwner",
  baseName: "contractOwner",
  type: "ContractOwner",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "sideTerms",
  baseName: "sideTerms",
  type: "string",
  format: ""
}, {
  name: "detDemDetails",
  baseName: "detDemDetails",
  type: "number",
  format: "int32"
}, {
  name: "detDetails",
  baseName: "detDetails",
  type: "number",
  format: "int32"
}, {
  name: "demDetails",
  baseName: "demDetails",
  type: "number",
  format: "int32"
}, {
  name: "vgmCutOff",
  baseName: "vgmCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "cyCutOff",
  baseName: "cyCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "siCutOff",
  baseName: "siCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "containerRates",
  baseName: "containerRates",
  type: "Array<ContainerRate>",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "changeLog",
  baseName: "changeLog",
  type: "Array<ScheduleChangeEntry>",
  format: ""
}];