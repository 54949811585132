export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};
export class BaseAPIRequestFactory {
  constructor(r) {
    this.configuration = r;
  }
}
export class RequiredError extends Error {
  constructor(r, e, s) {
    super("Required parameter " + s + " was null or undefined when calling " + r + "." + e + "."), this.api = r, this.method = e, this.field = s, this.name = "RequiredError";
  }
}