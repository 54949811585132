export class UserNotificationSettingsReadModelPageResult {
  static getAttributeTypeMap() {
    return UserNotificationSettingsReadModelPageResult.attributeTypeMap;
  }
  constructor(t, e, i) {
    this.items = t, this.total = e, this.skipped = i;
  }
}
UserNotificationSettingsReadModelPageResult.discriminator = void 0, UserNotificationSettingsReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<UserNotificationSettingsReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];