export class ShipmentGoodsLeg {
  static getAttributeTypeMap() {
    return ShipmentGoodsLeg.attributeTypeMap;
  }
  constructor(e, t, a, s, r, d, i) {
    this.id = e, this.loadingAddress = t, this.unloadingAddress = a, this.scheduledDeparture = s, this.scheduledArrival = r, this.transportType = d, this.container = i;
  }
}
ShipmentGoodsLeg.discriminator = void 0, ShipmentGoodsLeg.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "loadingAddress",
  baseName: "loadingAddress",
  type: "ShipmentAddress",
  format: ""
}, {
  name: "unloadingAddress",
  baseName: "unloadingAddress",
  type: "ShipmentAddress",
  format: ""
}, {
  name: "scheduledDeparture",
  baseName: "scheduledDeparture",
  type: "Date",
  format: "date-time"
}, {
  name: "scheduledArrival",
  baseName: "scheduledArrival",
  type: "Date",
  format: "date-time"
}, {
  name: "transportType",
  baseName: "transportType",
  type: "string",
  format: ""
}, {
  name: "container",
  baseName: "container",
  type: "ShipmentContainer",
  format: ""
}];