export class AddLocationExternalSystemReferences {
  static getAttributeTypeMap() {
    return AddLocationExternalSystemReferences.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.organizationId = e, this.locationId = t, this.externalSystemReferences = a;
  }
}
AddLocationExternalSystemReferences.discriminator = void 0, AddLocationExternalSystemReferences.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "externalSystemReferences",
  baseName: "externalSystemReferences",
  type: "Array<ExternalSystemReference>",
  format: ""
}];