export class ContactPersonWithAssignedUserReadModel {
  static getAttributeTypeMap() {
    return ContactPersonWithAssignedUserReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, n, i, o, r, m, d, p, g, h) {
    this.id = e, this.contactId = t, this.organizationId = a, this.locationId = s, this.firstName = n, this.lastName = i, this.fullName = o, this.jobTitle = r, this.email = m, this.phoneCountryCode = d, this.phone = p, this.tenantId = g, this.assignedUserId = h;
  }
}
ContactPersonWithAssignedUserReadModel.discriminator = void 0, ContactPersonWithAssignedUserReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "contactId",
  baseName: "contactId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "fullName",
  baseName: "fullName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "assignedUserId",
  baseName: "assignedUserId",
  type: "string",
  format: ""
}];