export class CreateShipment {
  static getAttributeTypeMap() {
    return CreateShipment.attributeTypeMap;
  }
  constructor(e, t, a, n, i, r, m, s, d, o) {
    this.nvxId = e, this.loadingExpectedStart = t, this.loadingExpectedEnd = a, this.unloadingExpectedStart = n, this.unloadingExpectedEnd = i, this.customerReferences = r, this.remarks = m, this.incoterm = s, this.originTms = d, this.destinationTms = o;
  }
}
CreateShipment.discriminator = void 0, CreateShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "loadingExpectedStart",
  baseName: "loadingExpectedStart",
  type: "Date",
  format: "date-time"
}, {
  name: "loadingExpectedEnd",
  baseName: "loadingExpectedEnd",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingExpectedStart",
  baseName: "unloadingExpectedStart",
  type: "Date",
  format: "date-time"
}, {
  name: "unloadingExpectedEnd",
  baseName: "unloadingExpectedEnd",
  type: "Date",
  format: "date-time"
}, {
  name: "customerReferences",
  baseName: "customerReferences",
  type: "Array<string>",
  format: ""
}, {
  name: "remarks",
  baseName: "remarks",
  type: "string",
  format: ""
}, {
  name: "incoterm",
  baseName: "incoterm",
  type: "string",
  format: ""
}, {
  name: "originTms",
  baseName: "originTms",
  type: "string",
  format: ""
}, {
  name: "destinationTms",
  baseName: "destinationTms",
  type: "string",
  format: ""
}];