export class RemoveShipmentUnloadingLocation {
  static getAttributeTypeMap() {
    return RemoveShipmentUnloadingLocation.attributeTypeMap;
  }
  constructor(t) {
    this.nvxId = t;
  }
}
RemoveShipmentUnloadingLocation.discriminator = void 0, RemoveShipmentUnloadingLocation.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];