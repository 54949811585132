export class AssignUserToContactPerson {
  static getAttributeTypeMap() {
    return AssignUserToContactPerson.attributeTypeMap;
  }
  constructor(s, t) {
    this.contactPersonId = s, this.assignedUserId = t;
  }
}
AssignUserToContactPerson.discriminator = void 0, AssignUserToContactPerson.attributeTypeMap = [{
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}, {
  name: "assignedUserId",
  baseName: "assignedUserId",
  type: "string",
  format: ""
}];