export class ChatChannel {
  static getAttributeTypeMap() {
    return ChatChannel.attributeTypeMap;
  }
  constructor(a, t) {
    this.channelId = a, this.name = t;
  }
}
ChatChannel.discriminator = void 0, ChatChannel.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}];