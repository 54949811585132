export class ShipmentLocation {
  static getAttributeTypeMap() {
    return ShipmentLocation.attributeTypeMap;
  }
  constructor(t, e, a) {
    this.id = t, this.name = e, this.locationType = a;
  }
}
ShipmentLocation.discriminator = void 0, ShipmentLocation.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "locationType",
  baseName: "locationType",
  type: "string",
  format: ""
}];