var __awaiter = this && this.__awaiter || function (e, t, r, n) {
  function i(e) {
    return e instanceof r ? e : new r(function (t) {
      t(e);
    });
  }
  return new (r || (r = Promise))(function (r, o) {
    function a(e) {
      try {
        c(n.next(e));
      } catch (t) {
        o(t);
      }
    }
    function u(e) {
      try {
        c(n["throw"](e));
      } catch (t) {
        o(t);
      }
    }
    function c(e) {
      e.done ? r(e.value) : i(e.value).then(a, u);
    }
    c((n = n.apply(e, t || [])).next());
  });
};
export class BearerAuthentication {
  constructor(e) {
    this.tokenProvider = e;
  }
  getName() {
    return "Bearer";
  }
  applySecurityAuthentication(e) {
    return __awaiter(this, void 0, void 0, function* () {
      e.setHeaderParam("Authorization", "Bearer " + (yield this.tokenProvider.getToken()));
    });
  }
}
export function configureAuthMethods(e) {
  let t = {};
  return e ? (t["default"] = e["default"], e["Bearer"] && (t["Bearer"] = new BearerAuthentication(e["Bearer"]["tokenProvider"])), t) : t;
}