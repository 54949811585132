export class PublicLocationReadModel {
  static getAttributeTypeMap() {
    return PublicLocationReadModel.attributeTypeMap;
  }
  constructor(a, t, e, i, o, n) {
    this.id = a, this.locationId = t, this.organizationId = e, this.name = i, this.locationType = o, this.addressId = n;
  }
}
PublicLocationReadModel.discriminator = void 0, PublicLocationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "locationType",
  baseName: "locationType",
  type: "string",
  format: ""
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}];