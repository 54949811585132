export class CreateMilestone {
  static getAttributeTypeMap() {
    return CreateMilestone.attributeTypeMap;
  }
  constructor(e, t, a, s, r) {
    this.name = e, this.dueDate = t, this.shipmentId = a, this.status = s, this.userId = r;
  }
}
CreateMilestone.discriminator = void 0, CreateMilestone.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "string",
  format: ""
}, {
  name: "userId",
  baseName: "userId",
  type: "string",
  format: ""
}];