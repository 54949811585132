import { from } from "rxjs";
export class PromiseMiddlewareWrapper {
  constructor(r) {
    this.middleware = r;
  }
  pre(r) {
    return from(this.middleware.pre(r));
  }
  post(r) {
    return from(this.middleware.post(r));
  }
}