export class AddUserToChannel {
  static getAttributeTypeMap() {
    return AddUserToChannel.attributeTypeMap;
  }
  constructor(e, t) {
    this.channelId = e, this.userSub = t;
  }
}
AddUserToChannel.discriminator = void 0, AddUserToChannel.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}];