export class MilestoneReadModelPageResult {
  static getAttributeTypeMap() {
    return MilestoneReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
MilestoneReadModelPageResult.discriminator = void 0, MilestoneReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<MilestoneReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];