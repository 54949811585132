export class AttachedFile {
  static getAttributeTypeMap() {
    return AttachedFile.attributeTypeMap;
  }
  constructor(t, e) {
    this.id = t, this.tags = e;
  }
}
AttachedFile.discriminator = void 0, AttachedFile.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}];