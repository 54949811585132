export class ShipmentNotificationSettingsGroups {
  static getAttributeTypeMap() {
    return ShipmentNotificationSettingsGroups.attributeTypeMap;
  }
  constructor(t) {
    this.shipment = t;
  }
}
ShipmentNotificationSettingsGroups.discriminator = void 0, ShipmentNotificationSettingsGroups.attributeTypeMap = [{
  name: "shipment",
  baseName: "shipment",
  type: "Array<ShipmentNotificationSettingsEntryDefinition>",
  format: ""
}];