export class RoadShipmentSubscribe {
  static getAttributeTypeMap() {
    return RoadShipmentSubscribe.attributeTypeMap;
  }
  constructor(t) {
    this.nvxId = t;
  }
}
RoadShipmentSubscribe.discriminator = void 0, RoadShipmentSubscribe.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];