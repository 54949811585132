export class EnterShipmentPriority {
  static getAttributeTypeMap() {
    return EnterShipmentPriority.attributeTypeMap;
  }
  constructor(e, t, i, r) {
    this.shipmentReferenceId = e, this.shipmentType = t, this.priorityLevel = i, this.notes = r;
  }
}
EnterShipmentPriority.discriminator = void 0, EnterShipmentPriority.attributeTypeMap = [{
  name: "shipmentReferenceId",
  baseName: "shipmentReferenceId",
  type: "string",
  format: ""
}, {
  name: "shipmentType",
  baseName: "shipmentType",
  type: "string",
  format: ""
}, {
  name: "priorityLevel",
  baseName: "priorityLevel",
  type: "number",
  format: "int32"
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}];