export class BillOfLading {
  static getAttributeTypeMap() {
    return BillOfLading.attributeTypeMap;
  }
  constructor(e, t, a, r, m, i, d, p, l, o, c, s, u, h, n, L, N, A, D, f, y, b, S, g, v, F, O, P, T, R, B, M, x, j, k, q) {
    this.blNo = e, this.carrierNo = t, this.cntrNo = a, this.created = r, this.id = m, this.placeOfDelivery = i, this.placeOfDeliveryName = d, this.placeOfReceipt = p, this.placeOfReceiptName = l, this.pod = o, this.podActualArrivalLt = c, this.podActualArrivalLtFromAis = s, this.podActualDepartureLtFromAis = u, this.podActualDischargeLt = h, this.podName = n, this.podPredictedArrivalLt = L, this.podPredictedDepartureLt = N, this.podScheduledArrivalLt = A, this.podScheduledArrivalLtFromSchedule = D, this.podScheduledDepartureLtFromSchedule = f, this.podScheduledDischargeLt = y, this.podTerminalName = b, this.pol = S, this.polActualArrivalLtFromAis = g, this.polActualDepartureLt = v, this.polActualDepartureLtFromAis = F, this.polActualLoadingLt = O, this.polName = P, this.polPredictedArrivalLt = T, this.polPredictedDepartureLt = R, this.polScheduledArrivalLtFromSchedule = B, this.polScheduledDepartureLt = M, this.polScheduledDepartureLtFromSchedule = x, this.polScheduledLoadingLt = j, this.polTerminalName = k, this.updated = q;
  }
}
BillOfLading.discriminator = void 0, BillOfLading.attributeTypeMap = [{
  name: "blNo",
  baseName: "blNo",
  type: "string",
  format: ""
}, {
  name: "carrierNo",
  baseName: "carrierNo",
  type: "string",
  format: ""
}, {
  name: "cntrNo",
  baseName: "cntrNo",
  type: "string",
  format: ""
}, {
  name: "created",
  baseName: "created",
  type: "Date",
  format: "date-time"
}, {
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "placeOfDelivery",
  baseName: "placeOfDelivery",
  type: "string",
  format: ""
}, {
  name: "placeOfDeliveryName",
  baseName: "placeOfDeliveryName",
  type: "string",
  format: ""
}, {
  name: "placeOfReceipt",
  baseName: "placeOfReceipt",
  type: "string",
  format: ""
}, {
  name: "placeOfReceiptName",
  baseName: "placeOfReceiptName",
  type: "string",
  format: ""
}, {
  name: "pod",
  baseName: "pod",
  type: "string",
  format: ""
}, {
  name: "podActualArrivalLt",
  baseName: "podActualArrivalLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podActualArrivalLtFromAis",
  baseName: "podActualArrivalLtFromAis",
  type: "Date",
  format: "date-time"
}, {
  name: "podActualDepartureLtFromAis",
  baseName: "podActualDepartureLtFromAis",
  type: "Date",
  format: "date-time"
}, {
  name: "podActualDischargeLt",
  baseName: "podActualDischargeLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podName",
  baseName: "podName",
  type: "string",
  format: ""
}, {
  name: "podPredictedArrivalLt",
  baseName: "podPredictedArrivalLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podPredictedDepartureLt",
  baseName: "podPredictedDepartureLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podScheduledArrivalLt",
  baseName: "podScheduledArrivalLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podScheduledArrivalLtFromSchedule",
  baseName: "podScheduledArrivalLtFromSchedule",
  type: "Date",
  format: "date-time"
}, {
  name: "podScheduledDepartureLtFromSchedule",
  baseName: "podScheduledDepartureLtFromSchedule",
  type: "Date",
  format: "date-time"
}, {
  name: "podScheduledDischargeLt",
  baseName: "podScheduledDischargeLt",
  type: "Date",
  format: "date-time"
}, {
  name: "podTerminalName",
  baseName: "podTerminalName",
  type: "string",
  format: ""
}, {
  name: "pol",
  baseName: "pol",
  type: "string",
  format: ""
}, {
  name: "polActualArrivalLtFromAis",
  baseName: "polActualArrivalLtFromAis",
  type: "Date",
  format: "date-time"
}, {
  name: "polActualDepartureLt",
  baseName: "polActualDepartureLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polActualDepartureLtFromAis",
  baseName: "polActualDepartureLtFromAis",
  type: "Date",
  format: "date-time"
}, {
  name: "polActualLoadingLt",
  baseName: "polActualLoadingLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polName",
  baseName: "polName",
  type: "string",
  format: ""
}, {
  name: "polPredictedArrivalLt",
  baseName: "polPredictedArrivalLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polPredictedDepartureLt",
  baseName: "polPredictedDepartureLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polScheduledArrivalLtFromSchedule",
  baseName: "polScheduledArrivalLtFromSchedule",
  type: "Date",
  format: "date-time"
}, {
  name: "polScheduledDepartureLt",
  baseName: "polScheduledDepartureLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polScheduledDepartureLtFromSchedule",
  baseName: "polScheduledDepartureLtFromSchedule",
  type: "Date",
  format: "date-time"
}, {
  name: "polScheduledLoadingLt",
  baseName: "polScheduledLoadingLt",
  type: "Date",
  format: "date-time"
}, {
  name: "polTerminalName",
  baseName: "polTerminalName",
  type: "string",
  format: ""
}, {
  name: "updated",
  baseName: "updated",
  type: "Date",
  format: "date-time"
}];