export class RemoveUserFromShipment {
  static getAttributeTypeMap() {
    return RemoveUserFromShipment.attributeTypeMap;
  }
  constructor(e, t, r, a) {
    this.nvxId = e, this.role = t, this.organizationId = r, this.contactPersonId = a;
  }
}
RemoveUserFromShipment.discriminator = void 0, RemoveUserFromShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}];