export class ShipmentPriorityReadModelPageResult {
  static getAttributeTypeMap() {
    return ShipmentPriorityReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, i) {
    this.items = e, this.total = t, this.skipped = i;
  }
}
ShipmentPriorityReadModelPageResult.discriminator = void 0, ShipmentPriorityReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<ShipmentPriorityReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];