export class RoadShipmentReadModelPageResult {
  static getAttributeTypeMap() {
    return RoadShipmentReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
RoadShipmentReadModelPageResult.discriminator = void 0, RoadShipmentReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<RoadShipmentReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];