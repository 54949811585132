export class ArchiveChatChannel {
  static getAttributeTypeMap() {
    return ArchiveChatChannel.attributeTypeMap;
  }
  constructor(t) {
    this.channelId = t;
  }
}
ArchiveChatChannel.discriminator = void 0, ArchiveChatChannel.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}];