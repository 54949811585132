export class SetShipmentPlaceOfDelivery {
  static getAttributeTypeMap() {
    return SetShipmentPlaceOfDelivery.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.placeOfDelivery = t;
  }
}
SetShipmentPlaceOfDelivery.discriminator = void 0, SetShipmentPlaceOfDelivery.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "placeOfDelivery",
  baseName: "placeOfDelivery",
  type: "string",
  format: ""
}];