export class EnterInviteUser {
  static getAttributeTypeMap() {
    return EnterInviteUser.attributeTypeMap;
  }
  constructor(e, a, t, i, n, r) {
    this.nvxOrganizationName = e, this.inviteUserName = a, this.inviteOrganizationName = t, this.email = i, this.displayName = n, this.role = r;
  }
}
EnterInviteUser.discriminator = void 0, EnterInviteUser.attributeTypeMap = [{
  name: "nvxOrganizationName",
  baseName: "nvxOrganizationName",
  type: "string",
  format: ""
}, {
  name: "inviteUserName",
  baseName: "inviteUserName",
  type: "string",
  format: ""
}, {
  name: "inviteOrganizationName",
  baseName: "inviteOrganizationName",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "displayName",
  baseName: "displayName",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];