export class Position {
  static getAttributeTypeMap() {
    return Position.attributeTypeMap;
  }
  constructor(t, e) {
    this.latitude = t, this.longitude = e;
  }
}
Position.discriminator = void 0, Position.attributeTypeMap = [{
  name: "latitude",
  baseName: "latitude",
  type: "number",
  format: "double"
}, {
  name: "longitude",
  baseName: "longitude",
  type: "number",
  format: "double"
}];