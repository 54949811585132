export class ShipmentWarehouseProduct {
  static getAttributeTypeMap() {
    return ShipmentWarehouseProduct.attributeTypeMap;
  }
  constructor(t, e, a, r, i, o, n, s) {
    this.lot = t, this.palletId = e, this.productSku = a, this.quantity = r, this.packingGroup = i, this.warehouse = o, this.description = n, this.location = s;
  }
}
ShipmentWarehouseProduct.discriminator = void 0, ShipmentWarehouseProduct.attributeTypeMap = [{
  name: "lot",
  baseName: "lot",
  type: "string",
  format: ""
}, {
  name: "palletId",
  baseName: "palletId",
  type: "number",
  format: "int32"
}, {
  name: "productSku",
  baseName: "productSku",
  type: "string",
  format: ""
}, {
  name: "quantity",
  baseName: "quantity",
  type: "number",
  format: "int32"
}, {
  name: "packingGroup",
  baseName: "packingGroup",
  type: "string",
  format: ""
}, {
  name: "warehouse",
  baseName: "warehouse",
  type: "string",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}, {
  name: "location",
  baseName: "location",
  type: "string",
  format: ""
}];