export class DeleteCarrierContract {
  static getAttributeTypeMap() {
    return DeleteCarrierContract.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
DeleteCarrierContract.discriminator = void 0, DeleteCarrierContract.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];