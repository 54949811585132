export class TransshipmentReadModel {
  static getAttributeTypeMap() {
    return TransshipmentReadModel.attributeTypeMap;
  }
  constructor(t, e, a, s, n, m, r, i, o, d) {
    this.id = t, this.scheduleId = e, this.transshipmentPortLocationId = a, this.etaTransshipmentPort = s, this.ataTransshipmentPort = n, this.etdTransshipmentPort = m, this.atdTransshipmentPort = r, this.vesselName = i, this.imoNumber = o, this.tenantId = d;
  }
}
TransshipmentReadModel.discriminator = void 0, TransshipmentReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "scheduleId",
  baseName: "scheduleId",
  type: "string",
  format: "uuid"
}, {
  name: "transshipmentPortLocationId",
  baseName: "transshipmentPortLocationId",
  type: "string",
  format: "uuid"
}, {
  name: "etaTransshipmentPort",
  baseName: "etaTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataTransshipmentPort",
  baseName: "ataTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etdTransshipmentPort",
  baseName: "etdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdTransshipmentPort",
  baseName: "atdTransshipmentPort",
  type: "Date",
  format: "date-time"
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];