export class UpdateSchedule {
  static getAttributeTypeMap() {
    return UpdateSchedule.attributeTypeMap;
  }
  constructor(e, t, a, r, m, i, s, n, o, p, d, b, u, f, N, y, c, h, g, l, D, O, P, C, T, v, R, M) {
    this.id = e, this.shipmentId = t, this.etdOriginPort = a, this.atdOriginPort = r, this.etaDestinationPort = m, this.ataDestinationPort = i, this.carrierBookingNumber = s, this.carrierSoNumber = n, this.carrierOrganizationId = o, this.transportMode = p, this.vesselName = d, this.imoNumber = b, this.voyageNumber = u, this.mblPaymentTerm = f, this.contractOwner = N, this.contractNumber = y, this.sideTerms = c, this.detDemDetails = h, this.detDetails = g, this.demDetails = l, this.vgmCutOff = D, this.cyCutOff = O, this.siCutOff = P, this.containerRates = C, this.updateReason = T, this.updateExplanation = v, this.updateResponsibleParty = R, this.updateDetails = M;
  }
}
UpdateSchedule.discriminator = void 0, UpdateSchedule.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "etdOriginPort",
  baseName: "etdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdOriginPort",
  baseName: "atdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etaDestinationPort",
  baseName: "etaDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataDestinationPort",
  baseName: "ataDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "carrierBookingNumber",
  baseName: "carrierBookingNumber",
  type: "string",
  format: ""
}, {
  name: "carrierSoNumber",
  baseName: "carrierSoNumber",
  type: "string",
  format: ""
}, {
  name: "carrierOrganizationId",
  baseName: "carrierOrganizationId",
  type: "string",
  format: "uuid"
}, {
  name: "transportMode",
  baseName: "transportMode",
  type: "TransportMode",
  format: ""
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "voyageNumber",
  baseName: "voyageNumber",
  type: "string",
  format: ""
}, {
  name: "mblPaymentTerm",
  baseName: "mblPaymentTerm",
  type: "MBLPaymentTerm",
  format: ""
}, {
  name: "contractOwner",
  baseName: "contractOwner",
  type: "ContractOwner",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "sideTerms",
  baseName: "sideTerms",
  type: "string",
  format: ""
}, {
  name: "detDemDetails",
  baseName: "detDemDetails",
  type: "number",
  format: "int32"
}, {
  name: "detDetails",
  baseName: "detDetails",
  type: "number",
  format: "int32"
}, {
  name: "demDetails",
  baseName: "demDetails",
  type: "number",
  format: "int32"
}, {
  name: "vgmCutOff",
  baseName: "vgmCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "cyCutOff",
  baseName: "cyCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "siCutOff",
  baseName: "siCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "containerRates",
  baseName: "containerRates",
  type: "Array<ContainerRate>",
  format: ""
}, {
  name: "updateReason",
  baseName: "updateReason",
  type: "string",
  format: ""
}, {
  name: "updateExplanation",
  baseName: "updateExplanation",
  type: "string",
  format: ""
}, {
  name: "updateResponsibleParty",
  baseName: "updateResponsibleParty",
  type: "string",
  format: ""
}, {
  name: "updateDetails",
  baseName: "updateDetails",
  type: "string",
  format: ""
}];