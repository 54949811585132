export class MakeLocationPublic {
  static getAttributeTypeMap() {
    return MakeLocationPublic.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
MakeLocationPublic.discriminator = void 0, MakeLocationPublic.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];