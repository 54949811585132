export class ShipmentNotificationSettingsEntryDefinition {
  static getAttributeTypeMap() {
    return ShipmentNotificationSettingsEntryDefinition.attributeTypeMap;
  }
  constructor(t, i) {
    this.key = t, this.description = i;
  }
}
ShipmentNotificationSettingsEntryDefinition.discriminator = void 0, ShipmentNotificationSettingsEntryDefinition.attributeTypeMap = [{
  name: "key",
  baseName: "key",
  type: "string",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];