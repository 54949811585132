export class CreateLocation {
  static getAttributeTypeMap() {
    return CreateLocation.attributeTypeMap;
  }
  constructor(t, a, e) {
    this.organizationId = t, this.name = a, this.locationType = e;
  }
}
CreateLocation.discriminator = void 0, CreateLocation.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "locationType",
  baseName: "locationType",
  type: "string",
  format: ""
}];