export class RestoreShipmentDirectory {
  static getAttributeTypeMap() {
    return RestoreShipmentDirectory.attributeTypeMap;
  }
  constructor(t) {
    this.shipmentId = t;
  }
}
RestoreShipmentDirectory.discriminator = void 0, RestoreShipmentDirectory.attributeTypeMap = [{
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}];