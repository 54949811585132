export class AssignUnloadingLocationToShipment {
  static getAttributeTypeMap() {
    return AssignUnloadingLocationToShipment.attributeTypeMap;
  }
  constructor(t, n) {
    this.nvxId = t, this.locationId = n;
  }
}
AssignUnloadingLocationToShipment.discriminator = void 0, AssignUnloadingLocationToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}];