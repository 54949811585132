export class CreateSchedule {
  static getAttributeTypeMap() {
    return CreateSchedule.attributeTypeMap;
  }
  constructor(e, t, a, r, m, i, s, n, o, b, d, f, N, u, c, p, y, h, g, D, O, l, P) {
    this.shipmentId = e, this.etdOriginPort = t, this.atdOriginPort = a, this.etaDestinationPort = r, this.ataDestinationPort = m, this.carrierBookingNumber = i, this.carrierSoNumber = s, this.carrierOrganizationId = n, this.transportMode = o, this.vesselName = b, this.imoNumber = d, this.voyageNumber = f, this.mblPaymentTerm = N, this.contractOwner = u, this.contractNumber = c, this.sideTerms = p, this.detDemDetails = y, this.detDetails = h, this.demDetails = g, this.vgmCutOff = D, this.cyCutOff = O, this.siCutOff = l, this.containerRates = P;
  }
}
CreateSchedule.discriminator = void 0, CreateSchedule.attributeTypeMap = [{
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "etdOriginPort",
  baseName: "etdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "atdOriginPort",
  baseName: "atdOriginPort",
  type: "Date",
  format: "date-time"
}, {
  name: "etaDestinationPort",
  baseName: "etaDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "ataDestinationPort",
  baseName: "ataDestinationPort",
  type: "Date",
  format: "date-time"
}, {
  name: "carrierBookingNumber",
  baseName: "carrierBookingNumber",
  type: "string",
  format: ""
}, {
  name: "carrierSoNumber",
  baseName: "carrierSoNumber",
  type: "string",
  format: ""
}, {
  name: "carrierOrganizationId",
  baseName: "carrierOrganizationId",
  type: "string",
  format: "uuid"
}, {
  name: "transportMode",
  baseName: "transportMode",
  type: "TransportMode",
  format: ""
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}, {
  name: "imoNumber",
  baseName: "imoNumber",
  type: "string",
  format: ""
}, {
  name: "voyageNumber",
  baseName: "voyageNumber",
  type: "string",
  format: ""
}, {
  name: "mblPaymentTerm",
  baseName: "mblPaymentTerm",
  type: "MBLPaymentTerm",
  format: ""
}, {
  name: "contractOwner",
  baseName: "contractOwner",
  type: "ContractOwner",
  format: ""
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "sideTerms",
  baseName: "sideTerms",
  type: "string",
  format: ""
}, {
  name: "detDemDetails",
  baseName: "detDemDetails",
  type: "number",
  format: "int32"
}, {
  name: "detDetails",
  baseName: "detDetails",
  type: "number",
  format: "int32"
}, {
  name: "demDetails",
  baseName: "demDetails",
  type: "number",
  format: "int32"
}, {
  name: "vgmCutOff",
  baseName: "vgmCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "cyCutOff",
  baseName: "cyCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "siCutOff",
  baseName: "siCutOff",
  type: "Date",
  format: "date-time"
}, {
  name: "containerRates",
  baseName: "containerRates",
  type: "Array<ContainerRate>",
  format: ""
}];