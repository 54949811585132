export class RestoreCarrierContract {
  static getAttributeTypeMap() {
    return RestoreCarrierContract.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
RestoreCarrierContract.discriminator = void 0, RestoreCarrierContract.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];