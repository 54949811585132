export class NvxUpdateAdditionalInformationLog {
  static getAttributeTypeMap() {
    return NvxUpdateAdditionalInformationLog.attributeTypeMap;
  }
  constructor(t, i) {
    this.isError = t, this.description = i;
  }
}
NvxUpdateAdditionalInformationLog.discriminator = void 0, NvxUpdateAdditionalInformationLog.attributeTypeMap = [{
  name: "isError",
  baseName: "isError",
  type: "string",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];