export class UserWithTenantRoleReadModel {
  static getAttributeTypeMap() {
    return UserWithTenantRoleReadModel.attributeTypeMap;
  }
  constructor(e, t, a, n, i, s) {
    this.sub = e, this.tenantId = t, this.name = a, this.email = n, this.role = i, this.id = s;
  }
}
UserWithTenantRoleReadModel.discriminator = void 0, UserWithTenantRoleReadModel.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];