export class RemoveFromTenant {
  static getAttributeTypeMap() {
    return RemoveFromTenant.attributeTypeMap;
  }
  constructor(e) {
    this.sub = e;
  }
}
RemoveFromTenant.discriminator = void 0, RemoveFromTenant.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}];