export class NvxUpdateAdditionalInformation {
  static getAttributeTypeMap() {
    return NvxUpdateAdditionalInformation.attributeTypeMap;
  }
  constructor(t, a) {
    this.internalNumber = t, this.logging = a;
  }
}
NvxUpdateAdditionalInformation.discriminator = void 0, NvxUpdateAdditionalInformation.attributeTypeMap = [{
  name: "internalNumber",
  baseName: "internalNumber",
  type: "string",
  format: ""
}, {
  name: "logging",
  baseName: "logging",
  type: "Array<NvxUpdateAdditionalInformationLog>",
  format: ""
}];