export class UpdateShipmentPriority {
  static getAttributeTypeMap() {
    return UpdateShipmentPriority.attributeTypeMap;
  }
  constructor(e, t, i, r, a) {
    this.id = e, this.shipmentReferenceId = t, this.shipmentType = i, this.priorityLevel = r, this.notes = a;
  }
}
UpdateShipmentPriority.discriminator = void 0, UpdateShipmentPriority.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentReferenceId",
  baseName: "shipmentReferenceId",
  type: "string",
  format: ""
}, {
  name: "shipmentType",
  baseName: "shipmentType",
  type: "string",
  format: ""
}, {
  name: "priorityLevel",
  baseName: "priorityLevel",
  type: "number",
  format: "int32"
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}];