export class AssignLoadingLocationToShipment {
  static getAttributeTypeMap() {
    return AssignLoadingLocationToShipment.attributeTypeMap;
  }
  constructor(t, i) {
    this.nvxId = t, this.locationId = i;
  }
}
AssignLoadingLocationToShipment.discriminator = void 0, AssignLoadingLocationToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}];