export class UserTableSortColumn {
  static getAttributeTypeMap() {
    return UserTableSortColumn.attributeTypeMap;
  }
  constructor(e, t, r) {
    this.columnName = e, this.sortOrder = t, this.isVisible = r;
  }
}
UserTableSortColumn.discriminator = void 0, UserTableSortColumn.attributeTypeMap = [{
  name: "columnName",
  baseName: "columnName",
  type: "string",
  format: ""
}, {
  name: "sortOrder",
  baseName: "sortOrder",
  type: "number",
  format: "int32"
}, {
  name: "isVisible",
  baseName: "isVisible",
  type: "boolean",
  format: ""
}];