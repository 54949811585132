export class DeleteAddress {
  static getAttributeTypeMap() {
    return DeleteAddress.attributeTypeMap;
  }
  constructor(e, t) {
    this.id = e, this.deleteReason = t;
  }
}
DeleteAddress.discriminator = void 0, DeleteAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "deleteReason",
  baseName: "deleteReason",
  type: "string",
  format: ""
}];