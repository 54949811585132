export class LocationExternalSystemReferences {
  static getAttributeTypeMap() {
    return LocationExternalSystemReferences.attributeTypeMap;
  }
  constructor(e, t) {
    this.locationId = e, this.externalSystemReferences = t;
  }
}
LocationExternalSystemReferences.discriminator = void 0, LocationExternalSystemReferences.attributeTypeMap = [{
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "externalSystemReferences",
  baseName: "externalSystemReferences",
  type: "Array<ExternalSystemReference>",
  format: ""
}];