import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { TenantDetails } from 'consistent-api-nvx-internal-sdk-dev';

import { withStorageSync } from '@/shared/lib/stores/features/storage-sync.feature';

type TenantState = {
  activeTenant: TenantDetails | null;
  userRoles: string[];
};

const initialState: TenantState = {
  activeTenant: null,
  userRoles: [],
};

export const TenantStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      activeTenantId: computed(() => store.activeTenant()?.tenantId),
      activeTenantName: computed(() => store.activeTenant()?.tenantName),
    };
  }),
  withMethods((store) => ({
    setActiveTenant(tenant: TenantDetails) {
      patchState(store, { activeTenant: tenant });
    },
    setUserRoles(tenantRoles?: Record<string, string[]>) {
      const activeTenantId = store.activeTenantId();
      let roles: string[] = [];
      if (activeTenantId && tenantRoles) {
        const activeTenantRoles = Object.entries(tenantRoles).find(([key]) => key === activeTenantId)?.[1];
        roles = activeTenantRoles ?? [];
      }
      patchState(store, { userRoles: roles });
    },
    hasUserRole(role: string) {
      return store.userRoles().includes(role);
    },
  })),
  withStorageSync({
    key: 'tenant-state',
    storage: () => sessionStorage,
  })
);
