export class OrganizationReadModel {
  static getAttributeTypeMap() {
    return OrganizationReadModel.attributeTypeMap;
  }
  constructor(t, a, e, n, i, o, r, s, m, y, p, b, g, c, d, h, f, u, N, l, C, P, A, z, I, T) {
    this.id = t, this.organizationId = a, this.name = e, this.organizationType = n, this.nameAlias = i, this.organizationAlias = o, this.email = r, this.eori = s, this.vatNumber = m, this.phoneCountryCode = y, this.phone = p, this.faxCountryCode = b, this.fax = g, this.website = c, this.contactPhoneCountryCode = d, this.contactPhone = h, this.legalEntityType = f, this.note = u, this.tags = N, this.creatorSub = l, this.contactPersonId = C, this.contactPerson = P, this.tenantId = A, this.isPublic = z, this.isActive = I, this.createdAt = T;
  }
}
OrganizationReadModel.discriminator = void 0, OrganizationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "organizationType",
  baseName: "organizationType",
  type: "Array<string>",
  format: ""
}, {
  name: "nameAlias",
  baseName: "nameAlias",
  type: "string",
  format: ""
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "eori",
  baseName: "eori",
  type: "string",
  format: ""
}, {
  name: "vatNumber",
  baseName: "vatNumber",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "faxCountryCode",
  baseName: "faxCountryCode",
  type: "string",
  format: ""
}, {
  name: "fax",
  baseName: "fax",
  type: "string",
  format: ""
}, {
  name: "website",
  baseName: "website",
  type: "string",
  format: ""
}, {
  name: "contactPhoneCountryCode",
  baseName: "contactPhoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "contactPhone",
  baseName: "contactPhone",
  type: "string",
  format: ""
}, {
  name: "legalEntityType",
  baseName: "legalEntityType",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}, {
  name: "creatorSub",
  baseName: "creatorSub",
  type: "string",
  format: ""
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}, {
  name: "contactPerson",
  baseName: "contactPerson",
  type: "OrganizationContactPerson",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "isPublic",
  baseName: "isPublic",
  type: "boolean",
  format: ""
}, {
  name: "isActive",
  baseName: "isActive",
  type: "boolean",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];