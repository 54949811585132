export class TenantWithCustomersReadModel {
  static getAttributeTypeMap() {
    return TenantWithCustomersReadModel.attributeTypeMap;
  }
  constructor(t, e, a) {
    this.id = t, this.tenantId = e, this.customers = a;
  }
}
TenantWithCustomersReadModel.discriminator = void 0, TenantWithCustomersReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "customers",
  baseName: "customers",
  type: "Array<string>",
  format: ""
}];