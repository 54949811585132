export class RemoveTypeFromOrganization {
  static getAttributeTypeMap() {
    return RemoveTypeFromOrganization.attributeTypeMap;
  }
  constructor(a, t) {
    this.organizationId = a, this.organizationType = t;
  }
}
RemoveTypeFromOrganization.discriminator = void 0, RemoveTypeFromOrganization.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationType",
  baseName: "organizationType",
  type: "string",
  format: ""
}];