export class RemoveCustomerFromTenant {
  static getAttributeTypeMap() {
    return RemoveCustomerFromTenant.attributeTypeMap;
  }
  constructor(e) {
    this.customerId = e;
  }
}
RemoveCustomerFromTenant.discriminator = void 0, RemoveCustomerFromTenant.attributeTypeMap = [{
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}];