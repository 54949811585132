export class RenameTenant {
  static getAttributeTypeMap() {
    return RenameTenant.attributeTypeMap;
  }
  constructor(e, t) {
    this.id = e, this.newName = t;
  }
}
RenameTenant.discriminator = void 0, RenameTenant.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "newName",
  baseName: "newName",
  type: "string",
  format: ""
}];