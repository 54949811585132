export class SuggestedAddress {
  static getAttributeTypeMap() {
    return SuggestedAddress.attributeTypeMap;
  }
  constructor(t, e, s, a, i, r, n) {
    this.id = t, this.address = e, this.city = s, this.state = a, this.zip = i, this.country = r, this.position = n;
  }
}
SuggestedAddress.discriminator = void 0, SuggestedAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "number",
  format: "int32"
}, {
  name: "address",
  baseName: "address",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "position",
  baseName: "position",
  type: "Position",
  format: ""
}];