export class LocationReadModel {
  static getAttributeTypeMap() {
    return LocationReadModel.attributeTypeMap;
  }
  constructor(a, t, e, i, o, n, d, s, r) {
    this.id = a, this.locationId = t, this.organizationId = e, this.name = i, this.locationType = o, this.isPublic = n, this.tenantId = d, this.addressId = s, this.createdAt = r;
  }
}
LocationReadModel.discriminator = void 0, LocationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "locationType",
  baseName: "locationType",
  type: "string",
  format: ""
}, {
  name: "isPublic",
  baseName: "isPublic",
  type: "boolean",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];