export class AddAddressToLocation {
  static getAttributeTypeMap() {
    return AddAddressToLocation.attributeTypeMap;
  }
  constructor(d, t) {
    this.id = d, this.addressId = t;
  }
}
AddAddressToLocation.discriminator = void 0, AddAddressToLocation.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}];