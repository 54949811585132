export class FailedNvxUpdateReadModel {
  static getAttributeTypeMap() {
    return FailedNvxUpdateReadModel.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.id = e, this.response = t, this.processedAt = a;
  }
}
FailedNvxUpdateReadModel.discriminator = void 0, FailedNvxUpdateReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "response",
  baseName: "response",
  type: "string",
  format: ""
}, {
  name: "processedAt",
  baseName: "processedAt",
  type: "Date",
  format: "date-time"
}];