export class ValidationRuleReadModel {
  static getAttributeTypeMap() {
    return ValidationRuleReadModel.attributeTypeMap;
  }
  constructor(a, e, t) {
    this.id = a, this.commandName = e, this.rules = t;
  }
}
ValidationRuleReadModel.discriminator = void 0, ValidationRuleReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "commandName",
  baseName: "commandName",
  type: "string",
  format: ""
}, {
  name: "rules",
  baseName: "rules",
  type: "Array<string>",
  format: ""
}];