export class CreateChatChannel {
  static getAttributeTypeMap() {
    return CreateChatChannel.attributeTypeMap;
  }
  constructor(t) {
    this.name = t;
  }
}
CreateChatChannel.discriminator = void 0, CreateChatChannel.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}];