export class ShipmentPriorityNoteHistoryEntry {
  static getAttributeTypeMap() {
    return ShipmentPriorityNoteHistoryEntry.attributeTypeMap;
  }
  constructor(t, e, r) {
    this.note = t, this.userSub = e, this.createdAt = r;
  }
}
ShipmentPriorityNoteHistoryEntry.discriminator = void 0, ShipmentPriorityNoteHistoryEntry.attributeTypeMap = [{
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];