import { ResponseContext } from "./http";
import { from } from "rxjs";
import "whatwg-fetch";
export class IsomorphicFetchHttpLibrary {
  send(t) {
    let e = t.getHttpMethod().toString(),
      o = t.getBody();
    const r = fetch(t.getUrl(), {
      method: e,
      body: o,
      headers: t.getHeaders(),
      credentials: "same-origin"
    }).then(t => {
      const e = {};
      t.headers.forEach((t, o) => {
        e[o] = t;
      });
      const o = {
        text: () => t.text(),
        binary: () => t.blob()
      };
      return new ResponseContext(t.status, e, o);
    });
    return from(r);
  }
}