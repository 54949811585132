export class TenantDetails {
  static getAttributeTypeMap() {
    return TenantDetails.attributeTypeMap;
  }
  constructor(t, e) {
    this.tenantId = t, this.tenantName = e;
  }
}
TenantDetails.discriminator = void 0, TenantDetails.attributeTypeMap = [{
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "tenantName",
  baseName: "tenantName",
  type: "string",
  format: ""
}];