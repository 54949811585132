export class ShipmentDirectoryReadModel {
  static getAttributeTypeMap() {
    return ShipmentDirectoryReadModel.attributeTypeMap;
  }
  constructor(t, e, i) {
    this.id = t, this.shipmentId = e, this.tenantId = i;
  }
}
ShipmentDirectoryReadModel.discriminator = void 0, ShipmentDirectoryReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentId",
  baseName: "shipmentId",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];