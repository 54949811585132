export class AssignContactPersonToOrganization {
  static getAttributeTypeMap() {
    return AssignContactPersonToOrganization.attributeTypeMap;
  }
  constructor(t, n) {
    this.organizationId = t, this.contactPersonId = n;
  }
}
AssignContactPersonToOrganization.discriminator = void 0, AssignContactPersonToOrganization.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "contactPersonId",
  baseName: "contactPersonId",
  type: "string",
  format: "uuid"
}];