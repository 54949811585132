var __awaiter = this && this.__awaiter || function (e, t, i, r) {
  function o(e) {
    return e instanceof i ? e : new i(function (t) {
      t(e);
    });
  }
  return new (i || (i = Promise))(function (i, n) {
    function a(e) {
      try {
        s(r.next(e));
      } catch (t) {
        n(t);
      }
    }
    function d(e) {
      try {
        s(r["throw"](e));
      } catch (t) {
        n(t);
      }
    }
    function s(e) {
      e.done ? i(e.value) : o(e.value).then(a, d);
    }
    s((r = r.apply(e, t || [])).next());
  });
};
import { BaseAPIRequestFactory, RequiredError } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { isCodeInRange } from "../util";
export class TenancyApiRequestFactory extends BaseAPIRequestFactory {
  enterInviteUser(e, t, i, r) {
    var o, n, a;
    return __awaiter(this, void 0, void 0, function* () {
      let d = r || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyApi", "enterInviteUser", "tenantId");
      if (null === t || void 0 === t) throw new RequiredError("TenancyApi", "enterInviteUser", "enterInviteUser");
      const s = "/tenant/{tenantId}/commands/enter-invite-user".replace("{tenantId}", encodeURIComponent(String(e))),
        c = d.baseServer.makeRequestContext(s, HttpMethod.POST);
      c.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== i && c.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(i, "string", ""));
      const p = ObjectSerializer.getPreferredMediaType(["application/json"]);
      c.setHeaderParam("Content-Type", p);
      const l = ObjectSerializer.stringify(ObjectSerializer.serialize(t, "EnterInviteUser", ""), p);
      let u;
      c.setBody(l), u = d.authMethods["Bearer"], (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(c));
      const h = (null === (o = null === r || void 0 === r ? void 0 : r.authMethods) || void 0 === o ? void 0 : o.default) || (null === (a = null === (n = this.configuration) || void 0 === n ? void 0 : n.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === h || void 0 === h ? void 0 : h.applySecurityAuthentication) && (yield null === h || void 0 === h ? void 0 : h.applySecurityAuthentication(c)), c;
    });
  }
  receiveNewSignup(e, t, i, r) {
    var o, n, a;
    return __awaiter(this, void 0, void 0, function* () {
      let d = r || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyApi", "receiveNewSignup", "apiKey");
      if (null === t || void 0 === t) throw new RequiredError("TenancyApi", "receiveNewSignup", "receiveNewSignup");
      const s = "/commands/receive-new-signup",
        c = d.baseServer.makeRequestContext(s, HttpMethod.POST);
      c.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== i && c.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(i, "string", "")), void 0 !== e && c.setHeaderParam("api_key", ObjectSerializer.serialize(e, "string", ""));
      const p = ObjectSerializer.getPreferredMediaType(["application/json"]);
      c.setHeaderParam("Content-Type", p);
      const l = ObjectSerializer.stringify(ObjectSerializer.serialize(t, "ReceiveNewSignup", ""), p);
      let u;
      c.setBody(l), u = d.authMethods["Bearer"], (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(c));
      const h = (null === (o = null === r || void 0 === r ? void 0 : r.authMethods) || void 0 === o ? void 0 : o.default) || (null === (a = null === (n = this.configuration) || void 0 === n ? void 0 : n.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === h || void 0 === h ? void 0 : h.applySecurityAuthentication) && (yield null === h || void 0 === h ? void 0 : h.applySecurityAuthentication(c)), c;
    });
  }
}
export class TenancyApiResponseProcessor {
  enterInviteUserWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  receiveNewSignupWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
}