export class SetShipmentDestination {
  static getAttributeTypeMap() {
    return SetShipmentDestination.attributeTypeMap;
  }
  constructor(t, e) {
    this.nvxId = t, this.destination = e;
  }
}
SetShipmentDestination.discriminator = void 0, SetShipmentDestination.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "destination",
  baseName: "destination",
  type: "string",
  format: ""
}];