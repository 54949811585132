export class CustomersReadModel {
  static getAttributeTypeMap() {
    return CustomersReadModel.attributeTypeMap;
  }
  constructor(t, e, a) {
    this.id = t, this.customerId = e, this.tenantId = a;
  }
}
CustomersReadModel.discriminator = void 0, CustomersReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];