export class ShipmentContact {
  static getAttributeTypeMap() {
    return ShipmentContact.attributeTypeMap;
  }
  constructor(t, a, e, n, i) {
    this.type = t, this.person = a, this.address = e, this.organization = n, this.location = i;
  }
}
ShipmentContact.discriminator = void 0, ShipmentContact.attributeTypeMap = [{
  name: "type",
  baseName: "type",
  type: "string",
  format: ""
}, {
  name: "person",
  baseName: "person",
  type: "ShipmentContactPerson",
  format: ""
}, {
  name: "address",
  baseName: "address",
  type: "ShipmentAddress",
  format: ""
}, {
  name: "organization",
  baseName: "organization",
  type: "ShipmentOrganization",
  format: ""
}, {
  name: "location",
  baseName: "location",
  type: "ShipmentLocation",
  format: ""
}];