export class SetShipmentRemarks {
  static getAttributeTypeMap() {
    return SetShipmentRemarks.attributeTypeMap;
  }
  constructor(t, e) {
    this.nvxId = t, this.remarks = e;
  }
}
SetShipmentRemarks.discriminator = void 0, SetShipmentRemarks.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "remarks",
  baseName: "remarks",
  type: "string",
  format: ""
}];