export class UserTableSortReadModelPageResult {
  static getAttributeTypeMap() {
    return UserTableSortReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
UserTableSortReadModelPageResult.discriminator = void 0, UserTableSortReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<UserTableSortReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];