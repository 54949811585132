export class ShipmentAssignedUser {
  static getAttributeTypeMap() {
    return ShipmentAssignedUser.attributeTypeMap;
  }
  constructor(t, e, n) {
    this.type = t, this.contactPerson = e, this.organization = n;
  }
}
ShipmentAssignedUser.discriminator = void 0, ShipmentAssignedUser.attributeTypeMap = [{
  name: "type",
  baseName: "type",
  type: "string",
  format: ""
}, {
  name: "contactPerson",
  baseName: "contactPerson",
  type: "ShipmentContactPerson",
  format: ""
}, {
  name: "organization",
  baseName: "organization",
  type: "ShipmentOrganization",
  format: ""
}];