export class NotificationMarkAsRead {
  static getAttributeTypeMap() {
    return NotificationMarkAsRead.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
NotificationMarkAsRead.discriminator = void 0, NotificationMarkAsRead.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];