export class CarrierOrganizationReadModelPageResult {
  static getAttributeTypeMap() {
    return CarrierOrganizationReadModelPageResult.attributeTypeMap;
  }
  constructor(e, a, t) {
    this.items = e, this.total = a, this.skipped = t;
  }
}
CarrierOrganizationReadModelPageResult.discriminator = void 0, CarrierOrganizationReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<CarrierOrganizationReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];