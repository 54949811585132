export class JourneyEvent {
  static getAttributeTypeMap() {
    return JourneyEvent.attributeTypeMap;
  }
  constructor(e, t, a, n, o, i) {
    this.name = e, this.eventTime = t, this.disposition = a, this.location = n, this.mode = o, this.vessel = i;
  }
}
JourneyEvent.discriminator = void 0, JourneyEvent.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "eventTime",
  baseName: "eventTime",
  type: "Date",
  format: "date-time"
}, {
  name: "disposition",
  baseName: "disposition",
  type: "JourneyEventDisposition",
  format: ""
}, {
  name: "location",
  baseName: "location",
  type: "JourneyEventLocationType",
  format: ""
}, {
  name: "mode",
  baseName: "mode",
  type: "TransportMode",
  format: ""
}, {
  name: "vessel",
  baseName: "vessel",
  type: "string",
  format: ""
}];