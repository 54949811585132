export class RemoveValidationRule {
  static getAttributeTypeMap() {
    return RemoveValidationRule.attributeTypeMap;
  }
  constructor(e, a) {
    this.commandName = e, this.rule = a;
  }
}
RemoveValidationRule.discriminator = void 0, RemoveValidationRule.attributeTypeMap = [{
  name: "commandName",
  baseName: "commandName",
  type: "string",
  format: ""
}, {
  name: "rule",
  baseName: "rule",
  type: "string",
  format: ""
}];