export class ContainerTypeReference {
  static getAttributeTypeMap() {
    return ContainerTypeReference.attributeTypeMap;
  }
  constructor(e, t) {
    this.id = e, this.containerType = t;
  }
}
ContainerTypeReference.discriminator = void 0, ContainerTypeReference.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}];