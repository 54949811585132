export class TerminalDetails {
  static getAttributeTypeMap() {
    return TerminalDetails.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.terminalCode = e, this.terminalCodeSource = t, this.terminalName = a;
  }
}
TerminalDetails.discriminator = void 0, TerminalDetails.attributeTypeMap = [{
  name: "terminalCode",
  baseName: "terminalCode",
  type: "string",
  format: ""
}, {
  name: "terminalCodeSource",
  baseName: "terminalCodeSource",
  type: "string",
  format: ""
}, {
  name: "terminalName",
  baseName: "terminalName",
  type: "string",
  format: ""
}];