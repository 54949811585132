export class UpdateOrganization {
  static getAttributeTypeMap() {
    return UpdateOrganization.attributeTypeMap;
  }
  constructor(t, e, a, n, i, o, r, s, m, p, y, g, h, b, f, N, d) {
    this.id = t, this.name = e, this.nameAlias = a, this.organizationAlias = n, this.email = i, this.eori = o, this.vatNumber = r, this.phoneCountryCode = s, this.phone = m, this.faxCountryCode = p, this.fax = y, this.website = g, this.contactPhoneCountryCode = h, this.contactPhone = b, this.legalEntityType = f, this.note = N, this.tags = d;
  }
}
UpdateOrganization.discriminator = void 0, UpdateOrganization.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "nameAlias",
  baseName: "nameAlias",
  type: "string",
  format: ""
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "eori",
  baseName: "eori",
  type: "string",
  format: ""
}, {
  name: "vatNumber",
  baseName: "vatNumber",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "faxCountryCode",
  baseName: "faxCountryCode",
  type: "string",
  format: ""
}, {
  name: "fax",
  baseName: "fax",
  type: "string",
  format: ""
}, {
  name: "website",
  baseName: "website",
  type: "string",
  format: ""
}, {
  name: "contactPhoneCountryCode",
  baseName: "contactPhoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "contactPhone",
  baseName: "contactPhone",
  type: "string",
  format: ""
}, {
  name: "legalEntityType",
  baseName: "legalEntityType",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}];