export class RegisterContainerJourney {
  static getAttributeTypeMap() {
    return RegisterContainerJourney.attributeTypeMap;
  }
  constructor(e, r, t) {
    this.blNo = e, this.carrierNo = r, this.cntrNo = t;
  }
}
RegisterContainerJourney.discriminator = void 0, RegisterContainerJourney.attributeTypeMap = [{
  name: "blNo",
  baseName: "blNo",
  type: "string",
  format: ""
}, {
  name: "carrierNo",
  baseName: "carrierNo",
  type: "string",
  format: ""
}, {
  name: "cntrNo",
  baseName: "cntrNo",
  type: "string",
  format: ""
}];