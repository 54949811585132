const t = Symbol.for("@ts-pattern/matcher"),
  e = Symbol.for("@ts-pattern/isVariadic"),
  n = "@ts-pattern/anonymous-select-key",
  r = t => Boolean(t && "object" == typeof t),
  i = e => e && !!e[t],
  s = (n, o, c) => {
    if (i(n)) {
      const e = n[t](),
        {
          matched: r,
          selections: i
        } = e.match(o);
      return r && i && Object.keys(i).forEach(t => c(t, i[t])), r;
    }
    if (r(n)) {
      if (!r(o)) return !1;
      if (Array.isArray(n)) {
        if (!Array.isArray(o)) return !1;
        let t = [],
          r = [],
          a = [];
        for (const s of n.keys()) {
          const o = n[s];
          i(o) && o[e] ? a.push(o) : a.length ? r.push(o) : t.push(o);
        }
        if (a.length) {
          if (a.length > 1) throw new Error("Pattern error: Using `...P.array(...)` several times in a single pattern is not allowed.");
          if (o.length < t.length + r.length) return !1;
          const e = o.slice(0, t.length),
            n = 0 === r.length ? [] : o.slice(-r.length),
            i = o.slice(t.length, 0 === r.length ? Infinity : -r.length);
          return t.every((t, n) => s(t, e[n], c)) && r.every((t, e) => s(t, n[e], c)) && (0 === a.length || s(a[0], i, c));
        }
        return n.length === o.length && n.every((t, e) => s(t, o[e], c));
      }
      return Reflect.ownKeys(n).every(e => {
        const r = n[e];
        return (e in o || i(a = r) && "optional" === a[t]().matcherType) && s(r, o[e], c);
        var a;
      });
    }
    return Object.is(o, n);
  },
  o = e => {
    var n, s, a;
    return r(e) ? i(e) ? null != (n = null == (s = (a = e[t]()).getSelectionKeys) ? void 0 : s.call(a)) ? n : [] : Array.isArray(e) ? c(e, o) : c(Object.values(e), o) : [];
  },
  c = (t, e) => t.reduce((t, n) => t.concat(e(n)), []);
function a(...t) {
  if (1 === t.length) {
    const [e] = t;
    return t => s(e, t, () => {});
  }
  if (2 === t.length) {
    const [e, n] = t;
    return s(e, n, () => {});
  }
  throw new Error(`isMatching wasn't given the right number of arguments: expected 1 or 2, received ${t.length}.`);
}
function u(t) {
  return Object.assign(t, {
    optional: () => h(t),
    and: e => m(t, e),
    or: e => d(t, e),
    select: e => void 0 === e ? y(t) : y(e, t)
  });
}
function l(t) {
  return Object.assign((t => Object.assign(t, {
    [Symbol.iterator]() {
      let n = 0;
      const r = [{
        value: Object.assign(t, {
          [e]: !0
        }),
        done: !1
      }, {
        done: !0,
        value: void 0
      }];
      return {
        next: () => {
          var t;
          return null != (t = r[n++]) ? t : r.at(-1);
        }
      };
    }
  }))(t), {
    optional: () => l(h(t)),
    select: e => l(void 0 === e ? y(t) : y(e, t))
  });
}
function h(e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return void 0 === t ? (o(e).forEach(t => r(t, void 0)), {
          matched: !0,
          selections: n
        }) : {
          matched: s(e, t, r),
          selections: n
        };
      },
      getSelectionKeys: () => o(e),
      matcherType: "optional"
    })
  });
}
const f = (t, e) => {
    for (const n of t) if (!e(n)) return !1;
    return !0;
  },
  g = (t, e) => {
    for (const [n, r] of t.entries()) if (!e(r, n)) return !1;
    return !0;
  };
function m(...e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return {
          matched: e.every(e => s(e, t, r)),
          selections: n
        };
      },
      getSelectionKeys: () => c(e, o),
      matcherType: "and"
    })
  });
}
function d(...e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return c(e, o).forEach(t => r(t, void 0)), {
          matched: e.some(e => s(e, t, r)),
          selections: n
        };
      },
      getSelectionKeys: () => c(e, o),
      matcherType: "or"
    })
  });
}
function p(e) {
  return {
    [t]: () => ({
      match: t => ({
        matched: Boolean(e(t))
      })
    })
  };
}
function y(...e) {
  const r = "string" == typeof e[0] ? e[0] : void 0,
    i = 2 === e.length ? e[1] : "string" == typeof e[0] ? void 0 : e[0];
  return u({
    [t]: () => ({
      match: t => {
        let e = {
          [null != r ? r : n]: t
        };
        return {
          matched: void 0 === i || s(i, t, (t, n) => {
            e[t] = n;
          }),
          selections: e
        };
      },
      getSelectionKeys: () => [null != r ? r : n].concat(void 0 === i ? [] : o(i))
    })
  });
}
function v(t) {
  return "number" == typeof t;
}
function b(t) {
  return "string" == typeof t;
}
function w(t) {
  return "bigint" == typeof t;
}
const S = u(p(function (t) {
    return !0;
  })),
  O = S,
  j = t => Object.assign(u(t), {
    startsWith: e => {
      return j(m(t, (n = e, p(t => b(t) && t.startsWith(n)))));
      var n;
    },
    endsWith: e => {
      return j(m(t, (n = e, p(t => b(t) && t.endsWith(n)))));
      var n;
    },
    minLength: e => j(m(t, (t => p(e => b(e) && e.length >= t))(e))),
    length: e => j(m(t, (t => p(e => b(e) && e.length === t))(e))),
    maxLength: e => j(m(t, (t => p(e => b(e) && e.length <= t))(e))),
    includes: e => {
      return j(m(t, (n = e, p(t => b(t) && t.includes(n)))));
      var n;
    },
    regex: e => {
      return j(m(t, (n = e, p(t => b(t) && Boolean(t.match(n))))));
      var n;
    }
  }),
  K = j(p(b)),
  x = t => Object.assign(u(t), {
    between: (e, n) => x(m(t, ((t, e) => p(n => v(n) && t <= n && e >= n))(e, n))),
    lt: e => x(m(t, (t => p(e => v(e) && e < t))(e))),
    gt: e => x(m(t, (t => p(e => v(e) && e > t))(e))),
    lte: e => x(m(t, (t => p(e => v(e) && e <= t))(e))),
    gte: e => x(m(t, (t => p(e => v(e) && e >= t))(e))),
    int: () => x(m(t, p(t => v(t) && Number.isInteger(t)))),
    finite: () => x(m(t, p(t => v(t) && Number.isFinite(t)))),
    positive: () => x(m(t, p(t => v(t) && t > 0))),
    negative: () => x(m(t, p(t => v(t) && t < 0)))
  }),
  E = x(p(v)),
  A = t => Object.assign(u(t), {
    between: (e, n) => A(m(t, ((t, e) => p(n => w(n) && t <= n && e >= n))(e, n))),
    lt: e => A(m(t, (t => p(e => w(e) && e < t))(e))),
    gt: e => A(m(t, (t => p(e => w(e) && e > t))(e))),
    lte: e => A(m(t, (t => p(e => w(e) && e <= t))(e))),
    gte: e => A(m(t, (t => p(e => w(e) && e >= t))(e))),
    positive: () => A(m(t, p(t => w(t) && t > 0))),
    negative: () => A(m(t, p(t => w(t) && t < 0)))
  }),
  P = A(p(w)),
  T = u(p(function (t) {
    return "boolean" == typeof t;
  })),
  B = u(p(function (t) {
    return "symbol" == typeof t;
  })),
  _ = u(p(function (t) {
    return null == t;
  })),
  k = u(p(function (t) {
    return null != t;
  }));
var N = {
  __proto__: null,
  matcher: t,
  optional: h,
  array: function (...e) {
    return l({
      [t]: () => ({
        match: t => {
          if (!Array.isArray(t)) return {
            matched: !1
          };
          if (0 === e.length) return {
            matched: !0
          };
          const n = e[0];
          let r = {};
          if (0 === t.length) return o(n).forEach(t => {
            r[t] = [];
          }), {
            matched: !0,
            selections: r
          };
          const i = (t, e) => {
            r[t] = (r[t] || []).concat([e]);
          };
          return {
            matched: t.every(t => s(n, t, i)),
            selections: r
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : o(e[0])
      })
    });
  },
  set: function (...e) {
    return u({
      [t]: () => ({
        match: t => {
          if (!(t instanceof Set)) return {
            matched: !1
          };
          let n = {};
          if (0 === t.size) return {
            matched: !0,
            selections: n
          };
          if (0 === e.length) return {
            matched: !0
          };
          const r = (t, e) => {
              n[t] = (n[t] || []).concat([e]);
            },
            i = e[0];
          return {
            matched: f(t, t => s(i, t, r)),
            selections: n
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : o(e[0])
      })
    });
  },
  map: function (...e) {
    return u({
      [t]: () => ({
        match: t => {
          if (!(t instanceof Map)) return {
            matched: !1
          };
          let n = {};
          if (0 === t.size) return {
            matched: !0,
            selections: n
          };
          const r = (t, e) => {
            n[t] = (n[t] || []).concat([e]);
          };
          if (0 === e.length) return {
            matched: !0
          };
          var i;
          if (1 === e.length) throw new Error(`\`P.map\` wasn't given enough arguments. Expected (key, value), received ${null == (i = e[0]) ? void 0 : i.toString()}`);
          const [o, c] = e;
          return {
            matched: g(t, (t, e) => {
              const n = s(o, e, r),
                i = s(c, t, r);
              return n && i;
            }),
            selections: n
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : [...o(e[0]), ...o(e[1])]
      })
    });
  },
  intersection: m,
  union: d,
  not: function (e) {
    return u({
      [t]: () => ({
        match: t => ({
          matched: !s(e, t, () => {})
        }),
        getSelectionKeys: () => [],
        matcherType: "not"
      })
    });
  },
  when: p,
  select: y,
  any: S,
  _: O,
  string: K,
  number: E,
  bigint: P,
  boolean: T,
  symbol: B,
  nullish: _,
  nonNullable: k,
  instanceOf: function (t) {
    return u(p(function (t) {
      return e => e instanceof t;
    }(t)));
  },
  shape: function (t) {
    return u(p(a(t)));
  }
};
class W extends Error {
  constructor(t) {
    let e;
    try {
      e = JSON.stringify(t);
    } catch (n) {
      e = t;
    }
    super(`Pattern matching error: no pattern matches value ${e}`), this.input = void 0, this.input = t;
  }
}
const $ = {
  matched: !1,
  value: void 0
};
function z(t) {
  return new I(t, $);
}
class I {
  constructor(t, e) {
    this.input = void 0, this.state = void 0, this.input = t, this.state = e;
  }
  with(...t) {
    if (this.state.matched) return this;
    const e = t[t.length - 1],
      r = [t[0]];
    let i;
    3 === t.length && "function" == typeof t[1] ? i = t[1] : t.length > 2 && r.push(...t.slice(1, t.length - 1));
    let o = !1,
      c = {};
    const a = (t, e) => {
        o = !0, c[t] = e;
      },
      u = !r.some(t => s(t, this.input, a)) || i && !Boolean(i(this.input)) ? $ : {
        matched: !0,
        value: e(o ? n in c ? c[n] : c : this.input, this.input)
      };
    return new I(this.input, u);
  }
  when(t, e) {
    if (this.state.matched) return this;
    const n = Boolean(t(this.input));
    return new I(this.input, n ? {
      matched: !0,
      value: e(this.input, this.input)
    } : $);
  }
  otherwise(t) {
    return this.state.matched ? this.state.value : t(this.input);
  }
  exhaustive() {
    if (this.state.matched) return this.state.value;
    throw new W(this.input);
  }
  run() {
    return this.exhaustive();
  }
  returnType() {
    return this;
  }
}
export { W as NonExhaustiveError, N as P, N as Pattern, a as isMatching, z as match };
