export class ScheduleChangeEntry {
  static getAttributeTypeMap() {
    return ScheduleChangeEntry.attributeTypeMap;
  }
  constructor(e, t, a, s, n, r, i) {
    this.updatedFields = e, this.changedAt = t, this.updatedBy = a, this.reason = s, this.explanation = n, this.responsibleParty = r, this.details = i;
  }
}
ScheduleChangeEntry.discriminator = void 0, ScheduleChangeEntry.attributeTypeMap = [{
  name: "updatedFields",
  baseName: "updatedFields",
  type: "Array<UpdatedField>",
  format: ""
}, {
  name: "changedAt",
  baseName: "changedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "updatedBy",
  baseName: "updatedBy",
  type: "string",
  format: ""
}, {
  name: "reason",
  baseName: "reason",
  type: "string",
  format: ""
}, {
  name: "explanation",
  baseName: "explanation",
  type: "string",
  format: ""
}, {
  name: "responsibleParty",
  baseName: "responsibleParty",
  type: "string",
  format: ""
}, {
  name: "details",
  baseName: "details",
  type: "string",
  format: ""
}];