export class ReceiveNewSignup {
  static getAttributeTypeMap() {
    return ReceiveNewSignup.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.userSub = e, this.role = t, this.tenantId = a;
  }
}
ReceiveNewSignup.discriminator = void 0, ReceiveNewSignup.attributeTypeMap = [{
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: ""
}];