export class UserWithRoleReadModel {
  static getAttributeTypeMap() {
    return UserWithRoleReadModel.attributeTypeMap;
  }
  constructor(e, t, a, i, r) {
    this.sub = e, this.name = t, this.email = a, this.role = i, this.id = r;
  }
}
UserWithRoleReadModel.discriminator = void 0, UserWithRoleReadModel.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];