export class UserBoundAddressReadModel {
  static getAttributeTypeMap() {
    return UserBoundAddressReadModel.attributeTypeMap;
  }
  constructor(e, a, t, s, r, i, d, n, o, m, p, b, y, u, c, f, g, h, N, l) {
    this.id = e, this.addressId = a, this.userSub = t, this.address1 = s, this.address2 = r, this.zip = i, this.city = d, this.area = n, this.country = o, this.state = m, this.notes = p, this.label = b, this.addressState = y, this.tenantId = u, this.validationError = c, this.coordinates = f, this.tmsReference = g, this.organizationId = h, this.locationId = N, this.createdAt = l;
  }
}
UserBoundAddressReadModel.discriminator = void 0, UserBoundAddressReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "address1",
  baseName: "address1",
  type: "string",
  format: ""
}, {
  name: "address2",
  baseName: "address2",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "area",
  baseName: "area",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}, {
  name: "label",
  baseName: "label",
  type: "string",
  format: ""
}, {
  name: "addressState",
  baseName: "addressState",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "validationError",
  baseName: "validationError",
  type: "ValidationDetails",
  format: ""
}, {
  name: "coordinates",
  baseName: "coordinates",
  type: "Position",
  format: ""
}, {
  name: "tmsReference",
  baseName: "tmsReference",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];