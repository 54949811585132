export class SetShipmentStackability {
  static getAttributeTypeMap() {
    return SetShipmentStackability.attributeTypeMap;
  }
  constructor(t, a) {
    this.nvxId = t, this.isStackable = a;
  }
}
SetShipmentStackability.discriminator = void 0, SetShipmentStackability.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "isStackable",
  baseName: "isStackable",
  type: "boolean",
  format: ""
}];