export class NVXShipmentJuncture {
  static getAttributeTypeMap() {
    return NVXShipmentJuncture.attributeTypeMap;
  }
  constructor(t, e, a, m, r, s, u, p) {
    this.datetime = t, this.type = e, this.stage = a, this.status = m, this.source = r, this.sourceId = s, this.portType = u, this.portLocode = p;
  }
}
NVXShipmentJuncture.discriminator = void 0, NVXShipmentJuncture.attributeTypeMap = [{
  name: "datetime",
  baseName: "datetime",
  type: "Date",
  format: "date-time"
}, {
  name: "type",
  baseName: "type",
  type: "NVXShipmentJunctureTypeEnum",
  format: ""
}, {
  name: "stage",
  baseName: "stage",
  type: "NVXShipmentJunctureStageEnum",
  format: ""
}, {
  name: "status",
  baseName: "status",
  type: "NVXShipmentJunctureStatusEnum",
  format: ""
}, {
  name: "source",
  baseName: "source",
  type: "NVXShipmentJunctureSourceEnum",
  format: ""
}, {
  name: "sourceId",
  baseName: "sourceId",
  type: "string",
  format: ""
}, {
  name: "portType",
  baseName: "portType",
  type: "NVXShipmentJuncturePortTypeEnum",
  format: ""
}, {
  name: "portLocode",
  baseName: "portLocode",
  type: "string",
  format: ""
}];