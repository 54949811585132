export class SendChatMessage {
  static getAttributeTypeMap() {
    return SendChatMessage.attributeTypeMap;
  }
  constructor(e, a) {
    this.channelId = e, this.message = a;
  }
}
SendChatMessage.discriminator = void 0, SendChatMessage.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "message",
  baseName: "message",
  type: "string",
  format: ""
}];