export class RemoveCustomerReferenceFromShipment {
  static getAttributeTypeMap() {
    return RemoveCustomerReferenceFromShipment.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.customerReference = t;
  }
}
RemoveCustomerReferenceFromShipment.discriminator = void 0, RemoveCustomerReferenceFromShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "customerReference",
  baseName: "customerReference",
  type: "string",
  format: ""
}];