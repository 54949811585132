export class SetShipmentOriginEta {
  static getAttributeTypeMap() {
    return SetShipmentOriginEta.attributeTypeMap;
  }
  constructor(t, i) {
    this.nvxId = t, this.originEta = i;
  }
}
SetShipmentOriginEta.discriminator = void 0, SetShipmentOriginEta.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "originEta",
  baseName: "originEta",
  type: "Date",
  format: "date-time"
}];