export class UserOrganizationReadModel {
  static getAttributeTypeMap() {
    return UserOrganizationReadModel.attributeTypeMap;
  }
  constructor(a, t, e, i) {
    this.id = a, this.tenantId = t, this.organizationFound = e, this.organizationId = i;
  }
}
UserOrganizationReadModel.discriminator = void 0, UserOrganizationReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationFound",
  baseName: "organizationFound",
  type: "boolean",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}];