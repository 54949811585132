export class Co2Emissions {
  static getAttributeTypeMap() {
    return Co2Emissions.attributeTypeMap;
  }
  constructor(t, s, i, e) {
    this.intensity = t, this.total = s, this.ttw = i, this.wtt = e;
  }
}
Co2Emissions.discriminator = void 0, Co2Emissions.attributeTypeMap = [{
  name: "intensity",
  baseName: "intensity",
  type: "string",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "string",
  format: ""
}, {
  name: "ttw",
  baseName: "ttw",
  type: "string",
  format: ""
}, {
  name: "wtt",
  baseName: "wtt",
  type: "string",
  format: ""
}];