export class UpdateShipmentDetails {
  static getAttributeTypeMap() {
    return UpdateShipmentDetails.attributeTypeMap;
  }
  constructor(e, t, a, s, i, r, m, l) {
    this.nvxId = e, this.masterBLNumber = t, this.houseBLNumber = a, this.houseBLIssuedAs = s, this.earliestArrival = i, this.earliestSailing = r, this.latestSailing = m, this.latestArrival = l;
  }
}
UpdateShipmentDetails.discriminator = void 0, UpdateShipmentDetails.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "masterBLNumber",
  baseName: "masterBLNumber",
  type: "string",
  format: ""
}, {
  name: "houseBLNumber",
  baseName: "houseBLNumber",
  type: "string",
  format: ""
}, {
  name: "houseBLIssuedAs",
  baseName: "houseBLIssuedAs",
  type: "string",
  format: ""
}, {
  name: "earliestArrival",
  baseName: "earliestArrival",
  type: "Date",
  format: "date-time"
}, {
  name: "earliestSailing",
  baseName: "earliestSailing",
  type: "Date",
  format: "date-time"
}, {
  name: "latestSailing",
  baseName: "latestSailing",
  type: "Date",
  format: "date-time"
}, {
  name: "latestArrival",
  baseName: "latestArrival",
  type: "Date",
  format: "date-time"
}];