export class AssignContactPersonToShipment {
  static getAttributeTypeMap() {
    return AssignContactPersonToShipment.attributeTypeMap;
  }
  constructor(t, a, e, n, i, s) {
    this.nvxId = t, this.type = a, this.organizationId = e, this.locationId = n, this.addressId = i, this.contactId = s;
  }
}
AssignContactPersonToShipment.discriminator = void 0, AssignContactPersonToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "type",
  baseName: "type",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "contactId",
  baseName: "contactId",
  type: "string",
  format: "uuid"
}];