export class NvxReceivedProcessedReadModel {
  static getAttributeTypeMap() {
    return NvxReceivedProcessedReadModel.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.id = e, this.nvxId = t, this.updates = a;
  }
}
NvxReceivedProcessedReadModel.discriminator = void 0, NvxReceivedProcessedReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "updates",
  baseName: "updates",
  type: "NvxUpdate",
  format: ""
}];