export class DeleteShipmentDocument {
  static getAttributeTypeMap() {
    return DeleteShipmentDocument.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.fileId = t;
  }
}
DeleteShipmentDocument.discriminator = void 0, DeleteShipmentDocument.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "fileId",
  baseName: "fileId",
  type: "string",
  format: "uuid"
}];