export class ContactPersonWithAssignedUserReadModelPageResult {
  static getAttributeTypeMap() {
    return ContactPersonWithAssignedUserReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, s) {
    this.items = e, this.total = t, this.skipped = s;
  }
}
ContactPersonWithAssignedUserReadModelPageResult.discriminator = void 0, ContactPersonWithAssignedUserReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<ContactPersonWithAssignedUserReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];