export class ShipmentNotificationSettingsEntry {
  static getAttributeTypeMap() {
    return ShipmentNotificationSettingsEntry.attributeTypeMap;
  }
  constructor(t, e, i) {
    this.key = t, this.setting = e, this.role = i;
  }
}
ShipmentNotificationSettingsEntry.discriminator = void 0, ShipmentNotificationSettingsEntry.attributeTypeMap = [{
  name: "key",
  baseName: "key",
  type: "string",
  format: ""
}, {
  name: "setting",
  baseName: "setting",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];