export class RemoveAdditionalRole {
  static getAttributeTypeMap() {
    return RemoveAdditionalRole.attributeTypeMap;
  }
  constructor(e) {
    this.name = e;
  }
}
RemoveAdditionalRole.discriminator = void 0, RemoveAdditionalRole.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}];