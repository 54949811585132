export class CreateAdditionalRole {
  static getAttributeTypeMap() {
    return CreateAdditionalRole.attributeTypeMap;
  }
  constructor(t, e) {
    this.name = t, this.description = e;
  }
}
CreateAdditionalRole.discriminator = void 0, CreateAdditionalRole.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];