export class RemoveExternalSystemReferenceFromDirectoryLocation {
  static getAttributeTypeMap() {
    return RemoveExternalSystemReferenceFromDirectoryLocation.attributeTypeMap;
  }
  constructor(e, t, r) {
    this.organizationId = e, this.locationId = t, this.referenceType = r;
  }
}
RemoveExternalSystemReferenceFromDirectoryLocation.discriminator = void 0, RemoveExternalSystemReferenceFromDirectoryLocation.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "referenceType",
  baseName: "referenceType",
  type: "string",
  format: ""
}];