import { inject, Injectable, untracked } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as sdk from 'consistent-api-nvx-internal-sdk-dev';
import {
  catchError,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  ObservedValueOf,
  of,
  OperatorFunction,
  pipe,
  tap,
  throwError,
  UnaryFunction,
} from 'rxjs';
import { match } from 'ts-pattern';

import { environment } from '@/shared/environments/environment';
import { LoggerService } from '@/shared/lib/services/logger.service';
import { TenantStore } from '@/shared/lib/stores/tenant.store';

@Injectable({
  providedIn: 'root',
})
export class ApiProviderService {
  public readonly sdk: Sdk;
  private readonly config: sdk.Configuration;
  public readonly tenantSdk: TenantInjectedSdk;

  constructor() {
    this.config = sdk.createConfiguration({
      baseServer: new sdk.ServerConfiguration(environment.eventSourceUrl, {}),
      authMethods: {
        Bearer: {
          tokenProvider: {
            getToken: getGetToken(inject(MsalService)),
          },
        },
      },
    });

    this.sdk = {
      addressBookApi: new sdk.AddressBookApi(this.config),
      authorizationApi: new sdk.AuthorizationApi(this.config),
      chatApi: new sdk.ChatApi(this.config),
      currentUserApi: new sdk.CurrentUserApi(this.config),
      filesApi: new sdk.FilesApi(this.config),
      frameworkManagementApi: new sdk.FrameworkManagementApi(this.config),
      invoicingApi: new sdk.InvoicingApi(this.config),
      notificationsApi: new sdk.NotificationsApi(this.config),
      shipmentApi: new sdk.ShipmentApi(this.config),
      tenancyApi: new sdk.TenancyApi(this.config),
      tenancyManagementApi: new sdk.TenancyManagementApi(this.config),
      userProfileApi: new sdk.UserProfileApi(this.config),
      validationRulesManagementApi: new sdk.ValidationRulesManagementApi(this.config),
    };

    this.tenantSdk = proxyTenantId(this.config);
  }
}

function getGetToken(authService: MsalService): () => Promise<string> {
  return () => {
    const accessTokenRequest = {
      scopes: environment.msalConfig.apiScopes,
      account: authService.instance.getActiveAccount() ?? undefined,
    };
    return firstValueFrom(authService.acquireTokenSilent(accessTokenRequest).pipe(map((r) => r.accessToken)));
  };
}

// Monkey patching the SDK to include the tenantId when available.
// There is no need to filter whether the method needs the tenantId, as the SDK ignores it if is not tenant bound.
function proxyTenantId(config: sdk.Configuration): TenantInjectedSdk {
  const tenantStore = inject(TenantStore);
  const proxiedSdk: { [key: string]: { [key: string]: unknown } } = {};
  const apiKeys = Object.keys(sdk).filter((key) => key.endsWith('Api'));
  for (let i = 0; i < apiKeys.length; i++) {
    const key = apiKeys[i];
    const lowerKey = key[0].toLowerCase() + key.slice(1, key.length - 3);
    proxiedSdk[lowerKey] = {};
    // This is a key obtained from the SDK, so it is guaranteed to be a valid key.
    // @ts-expect-error this bypasses the type system on purpose.
    const apiConstructor = sdk[key];
    if (typeof apiConstructor !== 'function') {
      continue;
    }
    const api = new apiConstructor(config);
    const apiPrototype = Object.getPrototypeOf(api);
    const apiMethodNames = Object.getOwnPropertyNames(apiPrototype);
    for (let methodIndex = 0; methodIndex < apiMethodNames.length; methodIndex++) {
      const methodName = apiMethodNames[methodIndex];
      const method = apiPrototype[methodName];
      if (typeof method === 'function') {
        proxiedSdk[lowerKey][methodName] = (params: object, config: object) => {
          const tenantId = untracked(() => tenantStore.activeTenant()?.tenantId);
          return api[methodName]({ tenantId, ...params }, config);
        };
      }
    }
  }
  // @ts-expect-error this bypasses the type system on purpose.
  return proxiedSdk;
}

// Filter out all that is not an API.
type Apis = Pick<
  typeof sdk,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { [K in keyof typeof sdk]: K extends string & `${infer _}Api` ? K : never }[keyof typeof sdk]
>;

// Change capitalization.
type Sdk = UncapitalizeObjectKeys<{ [K in keyof Apis]: InstanceType<Apis[K]> }>;
type UncapitalizeObjectKeys<T extends object> = {
  [key in Uncapitalize<keyof T & string>]: Capitalize<key> extends keyof T ? T[Capitalize<key>] : never;
};

// Remove the API suffix.
type RemoveApiSuffix<T extends string> = T extends `${infer U}Api` ? U : T;
type AddApiSuffix<T extends string> = `${T}Api`;
type RemoveApiSuffixFromKeys<T extends object> = {
  [key in RemoveApiSuffix<keyof T & string>]: AddApiSuffix<key> extends keyof T ? T[AddApiSuffix<key>] : never;
};
type SdkWithoutApiSuffix = RemoveApiSuffixFromKeys<Sdk>;

// Proxy the tenantId into the SDK functions.
type ProxyingTenantIdFunction<T> = {
  [K in keyof T]: T[K] extends (param: infer P extends { tenantId: string }, config?: sdk.Configuration) => infer R
    ? (param: Omit<P, 'tenantId'>, config?: sdk.Configuration) => R
    : T[K];
};

// Version of the SDK that injects the tenantId into every request.
export type TenantInjectedSdk = {
  [key in keyof SdkWithoutApiSuffix]: ProxyingTenantIdFunction<SdkWithoutApiSuffix[key]>;
};

export type ApiError =
  | { type: 'validation'; message: string; errors: string[] }
  | { type: 'not found'; message: string }
  | { type: 'server error'; message: string }
  | { type: 'conflict'; message: string }
  | { type: 'unauthorized'; message: string }
  | { type: 'forbidden'; message: string }
  | { type: 'corrupt stream'; message: string }
  | { type: 'unknown'; message: string };

export type OperationResult<T> = { type: 'success'; result: T } | { type: 'error'; error: ApiError; jsError: Error };

type ErrorContent = { message?: string; errors?: string[] };

type SdkError = { code: number; body: string | ErrorContent } & Error;

export function normalize<T>(): UnaryFunction<Observable<T>, Observable<OperationResult<T>>> {
  const mapper = map<T, OperationResult<T>>((r) => ({ type: 'success', result: r }));

  const errorHandler = catchError<OperationResult<T>, Observable<OperationResult<T>>>((e: SdkError) => {
    let body: { message?: string; errors?: string[] } = {};
    try {
      body = typeof e.body === 'string' ? JSON.parse(e.body) : e.body;
      // @ts-expect-error it's not letting me type the error otherwise
    } catch (e: unknown & { body?: string }) {
      body = !body.message ? { message: e?.body?.toString() } : body;
    }
    if (e.code === 400 && body.errors) {
      return of({
        type: 'error',
        error: {
          type: 'validation',
          message: body.message || '',
          errors: body.errors,
        },
        jsError: e,
      });
    }

    if (e.code === 500 && body.message && body.message.startsWith('Stream') && body.message.endsWith('is corrupt')) {
      return of({ type: 'error', error: { type: 'corrupt stream', message: body.message }, jsError: e });
    }

    if (e.code === 500) {
      return of({ type: 'error', error: { type: 'server error', message: body.message || '' }, jsError: e });
    }

    if (e.code === 404) {
      return of({ type: 'error', error: { type: 'not found', message: body.message || '' }, jsError: e });
    }

    if (e.code === 409) {
      return of({ type: 'error', error: { type: 'conflict', message: body.message || '' }, jsError: e });
    }

    if (e.code === 401) {
      return of({ type: 'error', error: { type: 'unauthorized', message: body.message || '' }, jsError: e });
    }

    if (e.code === 403) {
      return of({ type: 'error', error: { type: 'forbidden', message: body.message || '' }, jsError: e });
    }

    return of({ type: 'error', error: { type: 'unknown', message: 'unknown error, check jsError' }, jsError: e });
  });

  return pipe(mapper, errorHandler);
}

export function toNullable<T>(): OperatorFunction<T, ObservedValueOf<Observable<T | null>> | T> {
  const handler: OperatorFunction<T, ObservedValueOf<Observable<T | null>> | T> = catchError<T, Observable<T | null>>(
    (e) => {
      if (e instanceof Error && e.message === '404') {
        return of();
      }
      return of(null);
    }
  );
  return pipe(handler);
}

const logger = new LoggerService();

export function consoleOnError<T>(): UnaryFunction<Observable<OperationResult<T>>, Observable<OperationResult<T>>> {
  return tap<OperationResult<T>>((r) => {
    if (r.type === 'error' && r.error.type === 'validation') {
      return logger.error(
        'ApiProviderService - consoleOnError - ',
        r.error.message + '\n' + r.error.errors.map((e) => '  - ' + e).join('\n')
      );
    }
    if (r.type === 'error') {
      return logger.error('ApiProviderService - consoleOnError - ', r.error.message);
    }
  });
}

export function denormalize<T>(): UnaryFunction<Observable<OperationResult<T>>, Observable<T>> {
  return mergeMap<OperationResult<T>, Observable<T>>((r) =>
    match(r)
      .with({ type: 'error' }, (r) => throwError(() => r.jsError))
      .with({ type: 'success' }, (r) => of(r.result))
      .exhaustive()
  );
}
