export class SetShipmentPlaceOfReceipt {
  static getAttributeTypeMap() {
    return SetShipmentPlaceOfReceipt.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.placeOfReceipt = t;
  }
}
SetShipmentPlaceOfReceipt.discriminator = void 0, SetShipmentPlaceOfReceipt.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "placeOfReceipt",
  baseName: "placeOfReceipt",
  type: "string",
  format: ""
}];