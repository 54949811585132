export class ChatChannelMessage {
  static getAttributeTypeMap() {
    return ChatChannelMessage.attributeTypeMap;
  }
  constructor(e, t, a, s, i, m, n) {
    this.id = e, this.message = t, this.senderName = a, this.senderSub = s, this.sentAt = i, this.modifiedAt = m, this.valiForEditUntil = n;
  }
}
ChatChannelMessage.discriminator = void 0, ChatChannelMessage.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "message",
  baseName: "message",
  type: "string",
  format: ""
}, {
  name: "senderName",
  baseName: "senderName",
  type: "string",
  format: ""
}, {
  name: "senderSub",
  baseName: "senderSub",
  type: "string",
  format: ""
}, {
  name: "sentAt",
  baseName: "sentAt",
  type: "Date",
  format: "date-time"
}, {
  name: "modifiedAt",
  baseName: "modifiedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "valiForEditUntil",
  baseName: "valiForEditUntil",
  type: "Date",
  format: "date-time"
}];