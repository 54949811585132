export class AddCustomerToTenant {
  static getAttributeTypeMap() {
    return AddCustomerToTenant.attributeTypeMap;
  }
  constructor(t) {
    this.customerId = t;
  }
}
AddCustomerToTenant.discriminator = void 0, AddCustomerToTenant.attributeTypeMap = [{
  name: "customerId",
  baseName: "customerId",
  type: "string",
  format: ""
}];