export class ContainerEvent {
  static getAttributeTypeMap() {
    return ContainerEvent.attributeTypeMap;
  }
  constructor(e, t, a, m, n, i, s, o, r, p, d, y, N, v, T, l, b) {
    this.created = e, this.eventRaw = t, this.eventTime = a, this.eventTimeEstimated = m, this.eventTypeCode = n, this.eventTypeName = i, this.id = s, this.locationRaw = o, this.locationTypeCode = r, this.locationTypeName = p, this.modeOfTransport = d, this.portCode = y, this.portName = N, this.terminalDetails = v, this.updated = T, this.vesselImo = l, this.vesselName = b;
  }
}
ContainerEvent.discriminator = void 0, ContainerEvent.attributeTypeMap = [{
  name: "created",
  baseName: "created",
  type: "Date",
  format: "date-time"
}, {
  name: "eventRaw",
  baseName: "eventRaw",
  type: "string",
  format: ""
}, {
  name: "eventTime",
  baseName: "eventTime",
  type: "Date",
  format: "date-time"
}, {
  name: "eventTimeEstimated",
  baseName: "eventTimeEstimated",
  type: "Date",
  format: "date-time"
}, {
  name: "eventTypeCode",
  baseName: "eventTypeCode",
  type: "string",
  format: ""
}, {
  name: "eventTypeName",
  baseName: "eventTypeName",
  type: "string",
  format: ""
}, {
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "locationRaw",
  baseName: "locationRaw",
  type: "string",
  format: ""
}, {
  name: "locationTypeCode",
  baseName: "locationTypeCode",
  type: "string",
  format: ""
}, {
  name: "locationTypeName",
  baseName: "locationTypeName",
  type: "string",
  format: ""
}, {
  name: "modeOfTransport",
  baseName: "modeOfTransport",
  type: "string",
  format: ""
}, {
  name: "portCode",
  baseName: "portCode",
  type: "string",
  format: ""
}, {
  name: "portName",
  baseName: "portName",
  type: "string",
  format: ""
}, {
  name: "terminalDetails",
  baseName: "terminalDetails",
  type: "TerminalDetails",
  format: ""
}, {
  name: "updated",
  baseName: "updated",
  type: "Date",
  format: "date-time"
}, {
  name: "vesselImo",
  baseName: "vesselImo",
  type: "number",
  format: "int32"
}, {
  name: "vesselName",
  baseName: "vesselName",
  type: "string",
  format: ""
}];