export class ValidateAddress {
  static getAttributeTypeMap() {
    return ValidateAddress.attributeTypeMap;
  }
  constructor(t, e) {
    this.id = t, this.suggestionId = e;
  }
}
ValidateAddress.discriminator = void 0, ValidateAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "suggestionId",
  baseName: "suggestionId",
  type: "number",
  format: "int32"
}];