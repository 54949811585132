export class AssignTenantRole {
  static getAttributeTypeMap() {
    return AssignTenantRole.attributeTypeMap;
  }
  constructor(t, e) {
    this.sub = t, this.role = e;
  }
}
AssignTenantRole.discriminator = void 0, AssignTenantRole.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];