export class UpdateCarrierContract {
  static getAttributeTypeMap() {
    return UpdateCarrierContract.attributeTypeMap;
  }
  constructor(t, a, r, e, i, m) {
    this.carrierContractId = t, this.contractNumber = a, this.validFrom = r, this.validTo = e, this.priority = i, this.comment = m;
  }
}
UpdateCarrierContract.discriminator = void 0, UpdateCarrierContract.attributeTypeMap = [{
  name: "carrierContractId",
  baseName: "carrierContractId",
  type: "string",
  format: "uuid"
}, {
  name: "contractNumber",
  baseName: "contractNumber",
  type: "string",
  format: ""
}, {
  name: "validFrom",
  baseName: "validFrom",
  type: "Date",
  format: "date-time"
}, {
  name: "validTo",
  baseName: "validTo",
  type: "Date",
  format: "date-time"
}, {
  name: "priority",
  baseName: "priority",
  type: "number",
  format: "int32"
}, {
  name: "comment",
  baseName: "comment",
  type: "string",
  format: ""
}];