export class DeleteChatMessage {
  static getAttributeTypeMap() {
    return DeleteChatMessage.attributeTypeMap;
  }
  constructor(e, t) {
    this.channelId = e, this.messageId = t;
  }
}
DeleteChatMessage.discriminator = void 0, DeleteChatMessage.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}, {
  name: "messageId",
  baseName: "messageId",
  type: "string",
  format: "uuid"
}];