export class StoreUserTableSort {
  static getAttributeTypeMap() {
    return StoreUserTableSort.attributeTypeMap;
  }
  constructor(e, t) {
    this.tableName = e, this.columns = t;
  }
}
StoreUserTableSort.discriminator = void 0, StoreUserTableSort.attributeTypeMap = [{
  name: "tableName",
  baseName: "tableName",
  type: "string",
  format: ""
}, {
  name: "columns",
  baseName: "columns",
  type: "Array<UserTableSortColumn>",
  format: ""
}];