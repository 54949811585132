export class ReadModelShipmentDocument {
  static getAttributeTypeMap() {
    return ReadModelShipmentDocument.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.id = e, this.fileName = t, this.tags = a;
  }
}
ReadModelShipmentDocument.discriminator = void 0, ReadModelShipmentDocument.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "fileName",
  baseName: "fileName",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}];