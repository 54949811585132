export class OrganizationDirectoryReadModel {
  static getAttributeTypeMap() {
    return OrganizationDirectoryReadModel.attributeTypeMap;
  }
  constructor(e, t, a, i, n, r) {
    this.id = e, this.organizationId = t, this.tenantId = a, this.organizationAlias = i, this.note = n, this.locationExternalSystemReferences = r;
  }
}
OrganizationDirectoryReadModel.discriminator = void 0, OrganizationDirectoryReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "locationExternalSystemReferences",
  baseName: "locationExternalSystemReferences",
  type: "Array<LocationExternalSystemReferences>",
  format: ""
}];