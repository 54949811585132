export class ExternalSystemReference {
  static getAttributeTypeMap() {
    return ExternalSystemReference.attributeTypeMap;
  }
  constructor(e, r) {
    this.referenceType = e, this.referenceNumber = r;
  }
}
ExternalSystemReference.discriminator = void 0, ExternalSystemReference.attributeTypeMap = [{
  name: "referenceType",
  baseName: "referenceType",
  type: "string",
  format: ""
}, {
  name: "referenceNumber",
  baseName: "referenceNumber",
  type: "string",
  format: ""
}];