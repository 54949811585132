export class CreateShipmentGoods {
  static getAttributeTypeMap() {
    return CreateShipmentGoods.attributeTypeMap;
  }
  constructor(e, t, a, m, r, s, n, o, i, b, p, d, u, g, h) {
    this.nvxId = e, this.orderIndex = t, this.containerNumber = a, this.containerType = m, this.grossWeight = r, this.commodity = s, this.poNumbers = n, this.sealNumber = o, this.netWeight = i, this.hsCodes = b, this.numberOfPackages = p, this.packageCode = d, this.cbm = u, this.dangerousGoodsUnNumber = g, this.size = h;
  }
}
CreateShipmentGoods.discriminator = void 0, CreateShipmentGoods.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "orderIndex",
  baseName: "orderIndex",
  type: "number",
  format: "int32"
}, {
  name: "containerNumber",
  baseName: "containerNumber",
  type: "string",
  format: ""
}, {
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}, {
  name: "grossWeight",
  baseName: "grossWeight",
  type: "number",
  format: "float"
}, {
  name: "commodity",
  baseName: "commodity",
  type: "string",
  format: ""
}, {
  name: "poNumbers",
  baseName: "poNumbers",
  type: "Array<string>",
  format: ""
}, {
  name: "sealNumber",
  baseName: "sealNumber",
  type: "string",
  format: ""
}, {
  name: "netWeight",
  baseName: "netWeight",
  type: "number",
  format: "float"
}, {
  name: "hsCodes",
  baseName: "hsCodes",
  type: "string",
  format: ""
}, {
  name: "numberOfPackages",
  baseName: "numberOfPackages",
  type: "number",
  format: "int32"
}, {
  name: "packageCode",
  baseName: "packageCode",
  type: "string",
  format: ""
}, {
  name: "cbm",
  baseName: "cbm",
  type: "number",
  format: "int32"
}, {
  name: "dangerousGoodsUnNumber",
  baseName: "dangerousGoodsUnNumber",
  type: "string",
  format: ""
}, {
  name: "size",
  baseName: "size",
  type: "number",
  format: "int32"
}];