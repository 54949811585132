export class CompleteRoadShipmentDraft {
  static getAttributeTypeMap() {
    return CompleteRoadShipmentDraft.attributeTypeMap;
  }
  constructor(t) {
    this.nvxId = t;
  }
}
CompleteRoadShipmentDraft.discriminator = void 0, CompleteRoadShipmentDraft.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];