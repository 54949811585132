export class OrganizationReadModelPageResult {
  static getAttributeTypeMap() {
    return OrganizationReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.items = e, this.total = t, this.skipped = a;
  }
}
OrganizationReadModelPageResult.discriminator = void 0, OrganizationReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<OrganizationReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];