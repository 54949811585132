export class ContactPersonReadModel {
  static getAttributeTypeMap() {
    return ContactPersonReadModel.attributeTypeMap;
  }
  constructor(t, e, a, n, o, i, s, m, r, d, p, l) {
    this.id = t, this.contactId = e, this.organizationId = a, this.locationId = n, this.firstName = o, this.lastName = i, this.fullName = s, this.jobTitle = m, this.email = r, this.phoneCountryCode = d, this.phone = p, this.tenantId = l;
  }
}
ContactPersonReadModel.discriminator = void 0, ContactPersonReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "contactId",
  baseName: "contactId",
  type: "string",
  format: "uuid"
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "fullName",
  baseName: "fullName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}];