export class AssignedUserReference {
  static getAttributeTypeMap() {
    return AssignedUserReference.attributeTypeMap;
  }
  constructor(e, t, a, s, i, r, n, o, m, d, p, g) {
    this.id = e, this.organizationId = t, this.locationId = a, this.assignedUserId = s, this.firstName = i, this.lastName = r, this.jobTitle = n, this.email = o, this.phoneCountryCode = m, this.phone = d, this.creatorSub = p, this.role = g;
  }
}
AssignedUserReference.discriminator = void 0, AssignedUserReference.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "assignedUserId",
  baseName: "assignedUserId",
  type: "string",
  format: ""
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "creatorSub",
  baseName: "creatorSub",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];