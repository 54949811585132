export class UpdateContainerRate {
  static getAttributeTypeMap() {
    return UpdateContainerRate.attributeTypeMap;
  }
  constructor(e, t, a, n, i, p, r) {
    this.id = e, this.containerType = t, this.rate = a, this.updateReason = n, this.updateExplanation = i, this.updateResponsibleParty = p, this.updateDetails = r;
  }
}
UpdateContainerRate.discriminator = void 0, UpdateContainerRate.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}, {
  name: "rate",
  baseName: "rate",
  type: "number",
  format: "double"
}, {
  name: "updateReason",
  baseName: "updateReason",
  type: "string",
  format: ""
}, {
  name: "updateExplanation",
  baseName: "updateExplanation",
  type: "string",
  format: ""
}, {
  name: "updateResponsibleParty",
  baseName: "updateResponsibleParty",
  type: "string",
  format: ""
}, {
  name: "updateDetails",
  baseName: "updateDetails",
  type: "string",
  format: ""
}];