export class ActiveRoadShipmentDraftPageResult {
  static getAttributeTypeMap() {
    return ActiveRoadShipmentDraftPageResult.attributeTypeMap;
  }
  constructor(t, e, a) {
    this.items = t, this.total = e, this.skipped = a;
  }
}
ActiveRoadShipmentDraftPageResult.discriminator = void 0, ActiveRoadShipmentDraftPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<ActiveRoadShipmentDraft>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];