export class UserTableSortReadModel {
  static getAttributeTypeMap() {
    return UserTableSortReadModel.attributeTypeMap;
  }
  constructor(e, a, t, r) {
    this.id = e, this.userSub = a, this.tableName = t, this.columns = r;
  }
}
UserTableSortReadModel.discriminator = void 0, UserTableSortReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "tableName",
  baseName: "tableName",
  type: "string",
  format: ""
}, {
  name: "columns",
  baseName: "columns",
  type: "Array<UserTableSortColumn>",
  format: ""
}];