export class StatusInfo {
  static getAttributeTypeMap() {
    return StatusInfo.attributeTypeMap;
  }
  constructor(t, a) {
    this.code = t, this.metaData = a;
  }
}
StatusInfo.discriminator = void 0, StatusInfo.attributeTypeMap = [{
  name: "code",
  baseName: "code",
  type: "string",
  format: ""
}, {
  name: "metaData",
  baseName: "metaData",
  type: "Array<string>",
  format: ""
}];