export class ReadModelResetResult {
  static getAttributeTypeMap() {
    return ReadModelResetResult.attributeTypeMap;
  }
  constructor(e) {
    this.success = e;
  }
}
ReadModelResetResult.discriminator = void 0, ReadModelResetResult.attributeTypeMap = [{
  name: "success",
  baseName: "success",
  type: "boolean",
  format: ""
}];