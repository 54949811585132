var __awaiter = this && this.__awaiter || function (e, t, i, o) {
  function r(e) {
    return e instanceof i ? e : new i(function (t) {
      t(e);
    });
  }
  return new (i || (i = Promise))(function (i, n) {
    function d(e) {
      try {
        s(o.next(e));
      } catch (t) {
        n(t);
      }
    }
    function a(e) {
      try {
        s(o["throw"](e));
      } catch (t) {
        n(t);
      }
    }
    function s(e) {
      e.done ? i(e.value) : r(e.value).then(d, a);
    }
    s((o = o.apply(e, t || [])).next());
  });
};
import { BaseAPIRequestFactory } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { isCodeInRange } from "../util";
export class FrameworkManagementApiRequestFactory extends BaseAPIRequestFactory {
  rerunProjectionUserEmailReceivedEmailReceivedProfileProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/UserEmailReceived-EmailReceivedProfileProjection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddUserToChannelFromShipmentAssignedUser(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/add-user-to-channel-from-shipment-assigned-user",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/address-created-notification-to-all-address-book-admins-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddressRejectedByAdminNotificationProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/address-rejected-by-admin-notification-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/address-rejected-notification-to-all-address-book-admins-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddressValidatedByAdminNotificationProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/address-validated-by-admin-notification-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/address-validated-notification-to-all-address-book-admins-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectioncontactPersonAssignedToOrganizationProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/contact-person-assigned-to-organization-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectioncontactPersonDeletedToOrganizationProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/contact-person-deleted-to-organization-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectioncreateChatChannelFromShipmentChatChannel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/create-chat-channel-from-shipment-chat-channel",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealing(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/customer-added-to-tenant-to-invoice-tenant-id-received-self-healing",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequested(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/external-invoice-event-registered-to-invoice-tenant-id-requested",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionframeworkTodoProcessorSnapshotCompletedTasks(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/framework-todo-processor-snapshot-completed-tasks",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectiongoodsCreatedToShipmentGoodsAssigned(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/goods-created-to-shipment-goods-assigned",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectiongoodsRemovedToShipmentGotGoodsRemoved(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/goods-removed-to-shipment-got-goods-removed",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdate(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/goods-updated-to-shipment-received-goods-update",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceived(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/invoice-tenant-id-requested-to-invoice-tenant-id-received",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionlocationAssignedToAddressProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/location-assigned-to-address-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionmessageForAllUsersWithRoleInTenantProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/message-for-all-users-with-role-in-tenant-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnewSignupReceivedToTenantRoleAssigned(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/new-signup-received-to-tenant-role-assigned",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyChatMessageReceived(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-chat-message-received",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyContainerJourneyCompleted(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-container-journey-completed",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyExternalInvoiceCompleted(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-external-invoice-completed",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyShipmentDocumentUploadedProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-shipment-document-uploaded-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyShipmentDraftCompletedProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-shipment-draft-completed-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyShipmentRemarksUpdatedProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-shipment-remarks-updated-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionnotifyShipmentUserAssignedProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/notify-shipment-user-assigned-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectContinuationExternalInvoiceSync(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/project-continuation-external-invoice-sync",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectExternalSyncSegmentToInvoices(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/project-external-sync-segment-to-invoices",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionApplicationRoleAssignedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-application-role-assigned-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionApplicationRoleRevokedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-application-role-revoked-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantCreatedToTenantUsers(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-created-to-tenant-users",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantNameSetFeedbackToUserSecurity(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-name-set-feedback-to-user-security",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantNameUpdatedToTenantUsers(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-name-updated-to-tenant-users",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToAddedToTenant(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-assigned-to-added-to-tenant",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-assigned-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToSendNotification(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-assigned-to-send-notification",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleEmailReceivedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-email-received-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleNameReceivedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-name-received-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleRevokedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-revoked-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionTenantRoleRevokedToSendNotification(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-tenant-role-revoked-to-send-notification",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurity(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-user-added-to-tenant-feedback-to-user-security",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionUserAddedToTenantToTenantUsers(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-user-added-to-tenant-to-tenant-users",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionUserEmailReceivedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-user-email-received-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionUserNameReceivedToDenormalized(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-user-name-received-to-denormalized",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionprojectionUserRemovedFromTenantToTenantUsers(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/projection-user-removed-from-tenant-to-tenant-users",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionroadShipmentConfirmationProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/road-shipment-confirmation-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionroadShipmentSubscriptionProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/road-shipment-subscription-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionroadShipmentTenantIdProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/road-shipment-tenant-id-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/shipment-priority-entered-notification-to-all-shipment-priority-admins-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/shipment-priority-updated-notification-to-all-shipment-priority-admins-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectiontenantRoleAssignedToMessageCenterProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/tenant-role-assigned-to-message-center-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectiontenantRoleRevokedToMessageCenterProjection(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/tenant-role-revoked-to-message-center-projection",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  rerunProjectionuserOrganizationEmailFoundReceived(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/rerun-projection/user-organization-email-found-received",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetActiveRoadShipmentDraft(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/active-road-shipment-draft",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetAdditionalRolesReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/additional-roles",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetAdminAddressReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/admin-address",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetAdminOrganizationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/admin-organization",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetCarrierContractReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/carrier-contract",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetCarrierOrganizationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/carrier-organization",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetChatChannelReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/chat-channel",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetContactPersonReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/contact-person",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetContactPersonWithAssignedUserReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/contact-person-with-assigned-user",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetContainerJourneyReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/container-journey",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetCustomerReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/customer",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetCustomersReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/customers",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetExternalInvoiceReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/external-invoice",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetFailedNvxUpdateReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/failed-nvx-update",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetLocationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/location",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetMilestoneReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/milestone",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetMyUserProfileReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/my-user-profile",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetNvxReceivedProcessedReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/nvx-received-processed",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetOrganizationDirectoryReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/organization-directory",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetOrganizationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/organization",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetPublicAddressReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/public-address",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetPublicLocationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/public-location",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetPublicUserProfileReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/public-user-profile",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetRoadShipmentReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/road-shipment",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetScheduleReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/schedule",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetSeaShipmentReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/sea-shipment",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetShipmentDirectoryReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/shipment-directory",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetShipmentOverviewReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/shipment-overview",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetShipmentPriorityReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/shipment-priority",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetShipmentReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/shipment",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetSystemGeneratedCarrierOrganizationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/system-generated-carrier-organization",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetTenantReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/tenant",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetTenantUserReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/tenant-user",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetTenantWithCustomersReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/tenant-with-customers",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetTodoEventModelReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/todo-event-model",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetTransshipmentReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/transshipment",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserBoundAddressReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-bound-address",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserNotificationDeletedReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-notification-deleted",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserNotificationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-notification",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserNotificationSettingsReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-notification-settings",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserOrganizationReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-organization",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserProfileReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-profile",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserSecurityReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-security",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserTableSortReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-table-sort",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserValidAddressReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-valid-address",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserWithRoleReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-with-role",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetUserWithTenantRoleReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/user-with-tenant-role",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
  resetValidationRuleReadModel(e) {
    var t, i, o;
    return __awaiter(this, void 0, void 0, function* () {
      let r = e || this.configuration;
      const n = "/reset-read-model/validation-rule",
        d = r.baseServer.makeRequestContext(n, HttpMethod.GET);
      let a;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), a = r.authMethods["Bearer"], (null === a || void 0 === a ? void 0 : a.applySecurityAuthentication) && (yield null === a || void 0 === a ? void 0 : a.applySecurityAuthentication(d));
      const s = (null === (t = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === t ? void 0 : t.default) || (null === (o = null === (i = this.configuration) || void 0 === i ? void 0 : i.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d)), d;
    });
  }
}
export class FrameworkManagementApiResponseProcessor {
  rerunProjectionUserEmailReceivedEmailReceivedProfileProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddUserToChannelFromShipmentAssignedUserWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddressRejectedByAdminNotificationProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddressValidatedByAdminNotificationProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectioncontactPersonAssignedToOrganizationProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectioncontactPersonDeletedToOrganizationProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectioncreateChatChannelFromShipmentChatChannelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealingWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequestedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionframeworkTodoProcessorSnapshotCompletedTasksWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectiongoodsCreatedToShipmentGoodsAssignedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectiongoodsRemovedToShipmentGotGoodsRemovedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdateWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceivedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionlocationAssignedToAddressProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionmessageForAllUsersWithRoleInTenantProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnewSignupReceivedToTenantRoleAssignedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyChatMessageReceivedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyContainerJourneyCompletedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyExternalInvoiceCompletedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyShipmentDocumentUploadedProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyShipmentDraftCompletedProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyShipmentRemarksUpdatedProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionnotifyShipmentUserAssignedProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectContinuationExternalInvoiceSyncWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectExternalSyncSegmentToInvoicesWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionApplicationRoleAssignedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionApplicationRoleRevokedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantCreatedToTenantUsersWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantNameSetFeedbackToUserSecurityWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantNameUpdatedToTenantUsersWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToAddedToTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleAssignedToSendNotificationWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleEmailReceivedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleNameReceivedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleRevokedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionTenantRoleRevokedToSendNotificationWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurityWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionUserAddedToTenantToTenantUsersWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionUserEmailReceivedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionUserNameReceivedToDenormalizedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionprojectionUserRemovedFromTenantToTenantUsersWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionroadShipmentConfirmationProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionroadShipmentSubscriptionProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionroadShipmentTenantIdProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectiontenantRoleAssignedToMessageCenterProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectiontenantRoleRevokedToMessageCenterProjectionWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  rerunProjectionuserOrganizationEmailFoundReceivedWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "any", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetActiveRoadShipmentDraftWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetAdditionalRolesReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetAdminAddressReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetAdminOrganizationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetCarrierContractReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetCarrierOrganizationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetChatChannelReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetContactPersonReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetContactPersonWithAssignedUserReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetContainerJourneyReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetCustomerReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetCustomersReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetExternalInvoiceReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetFailedNvxUpdateReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetLocationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetMilestoneReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetMyUserProfileReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetNvxReceivedProcessedReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetOrganizationDirectoryReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetOrganizationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetPublicAddressReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetPublicLocationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetPublicUserProfileReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetRoadShipmentReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetScheduleReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetSeaShipmentReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetShipmentDirectoryReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetShipmentOverviewReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetShipmentPriorityReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetShipmentReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetSystemGeneratedCarrierOrganizationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetTenantReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetTenantUserReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetTenantWithCustomersReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetTodoEventModelReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetTransshipmentReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserBoundAddressReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserNotificationDeletedReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserNotificationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserNotificationSettingsReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserOrganizationReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserProfileReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserSecurityReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserTableSortReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserValidAddressReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserWithRoleReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetUserWithTenantRoleReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  resetValidationRuleReadModelWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ReadModelResetResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
}