export class UpdateAddress {
  static getAttributeTypeMap() {
    return UpdateAddress.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i, m, n, d, p, o) {
    this.id = e, this.address1 = t, this.address2 = a, this.zip = s, this.city = r, this.area = i, this.country = m, this.state = n, this.notes = d, this.label = p, this.tmsReference = o;
  }
}
UpdateAddress.discriminator = void 0, UpdateAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "address1",
  baseName: "address1",
  type: "string",
  format: ""
}, {
  name: "address2",
  baseName: "address2",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "area",
  baseName: "area",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}, {
  name: "label",
  baseName: "label",
  type: "string",
  format: ""
}, {
  name: "tmsReference",
  baseName: "tmsReference",
  type: "string",
  format: ""
}];