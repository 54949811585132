export class NotificationArchive {
  static getAttributeTypeMap() {
    return NotificationArchive.attributeTypeMap;
  }
  constructor(i) {
    this.id = i;
  }
}
NotificationArchive.discriminator = void 0, NotificationArchive.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];