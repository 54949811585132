export class RestoreOrganizationDirectory {
  static getAttributeTypeMap() {
    return RestoreOrganizationDirectory.attributeTypeMap;
  }
  constructor(t) {
    this.organizationId = t;
  }
}
RestoreOrganizationDirectory.discriminator = void 0, RestoreOrganizationDirectory.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}];