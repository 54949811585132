export class SetNotificationSetting {
  static getAttributeTypeMap() {
    return SetNotificationSetting.attributeTypeMap;
  }
  constructor(t, i, e) {
    this.key = t, this.role = i, this.notificationSetting = e;
  }
}
SetNotificationSetting.discriminator = void 0, SetNotificationSetting.attributeTypeMap = [{
  name: "key",
  baseName: "key",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}, {
  name: "notificationSetting",
  baseName: "notificationSetting",
  type: "string",
  format: ""
}];