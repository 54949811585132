var __awaiter = this && this.__awaiter || function (t, e, i, o) {
  function n(t) {
    return t instanceof i ? t : new i(function (e) {
      e(t);
    });
  }
  return new (i || (i = Promise))(function (i, a) {
    function d(t) {
      try {
        l(o.next(t));
      } catch (e) {
        a(e);
      }
    }
    function r(t) {
      try {
        l(o["throw"](t));
      } catch (e) {
        a(e);
      }
    }
    function l(t) {
      t.done ? i(t.value) : n(t.value).then(d, r);
    }
    l((o = o.apply(t, e || [])).next());
  });
};
import { BaseAPIRequestFactory, RequiredError } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { canConsumeForm, isCodeInRange } from "../util";
export class FilesApiRequestFactory extends BaseAPIRequestFactory {
  filesDownloadIdGet(t, e) {
    var i, o, n;
    return __awaiter(this, void 0, void 0, function* () {
      let a = e || this.configuration;
      if (null === t || void 0 === t) throw new RequiredError("FilesApi", "filesDownloadIdGet", "id");
      const d = "/files/download/{id}".replace("{id}", encodeURIComponent(String(t))),
        r = a.baseServer.makeRequestContext(d, HttpMethod.GET);
      let l;
      r.setHeaderParam("Accept", "application/json, */*;q=0.8"), l = a.authMethods["Bearer"], (null === l || void 0 === l ? void 0 : l.applySecurityAuthentication) && (yield null === l || void 0 === l ? void 0 : l.applySecurityAuthentication(r));
      const s = (null === (i = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === i ? void 0 : i.default) || (null === (n = null === (o = this.configuration) || void 0 === o ? void 0 : o.authMethods) || void 0 === n ? void 0 : n.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(r)), r;
    });
  }
  filesNameIdGet(t, e) {
    var i, o, n;
    return __awaiter(this, void 0, void 0, function* () {
      let a = e || this.configuration;
      if (null === t || void 0 === t) throw new RequiredError("FilesApi", "filesNameIdGet", "id");
      const d = "/files/name/{id}".replace("{id}", encodeURIComponent(String(t))),
        r = a.baseServer.makeRequestContext(d, HttpMethod.GET);
      let l;
      r.setHeaderParam("Accept", "application/json, */*;q=0.8"), l = a.authMethods["Bearer"], (null === l || void 0 === l ? void 0 : l.applySecurityAuthentication) && (yield null === l || void 0 === l ? void 0 : l.applySecurityAuthentication(r));
      const s = (null === (i = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === i ? void 0 : i.default) || (null === (n = null === (o = this.configuration) || void 0 === o ? void 0 : o.authMethods) || void 0 === n ? void 0 : n.default);
      return (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(r)), r;
    });
  }
  filesUploadPost(t, e) {
    var i, o, n;
    return __awaiter(this, void 0, void 0, function* () {
      let a = e || this.configuration;
      if (null === t || void 0 === t) throw new RequiredError("FilesApi", "filesUploadPost", "file");
      const d = "/files/upload",
        r = a.baseServer.makeRequestContext(d, HttpMethod.POST);
      r.setHeaderParam("Accept", "application/json, */*;q=0.8");
      const l = canConsumeForm(["multipart/form-data"]);
      let s, u;
      if (s = l ? new FormData() : new URLSearchParams(), void 0 !== t && s instanceof FormData && s.append("file", t, t.name), r.setBody(s), !l) {
        const t = ObjectSerializer.getPreferredMediaType(["multipart/form-data"]);
        r.setHeaderParam("Content-Type", t);
      }
      u = a.authMethods["Bearer"], (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(r));
      const p = (null === (i = null === e || void 0 === e ? void 0 : e.authMethods) || void 0 === i ? void 0 : i.default) || (null === (n = null === (o = this.configuration) || void 0 === o ? void 0 : o.authMethods) || void 0 === n ? void 0 : n.default);
      return (null === p || void 0 === p ? void 0 : p.applySecurityAuthentication) && (yield null === p || void 0 === p ? void 0 : p.applySecurityAuthentication(r)), r;
    });
  }
}
export class FilesApiResponseProcessor {
  filesDownloadIdGetWithHttpInfo(t) {
    return __awaiter(this, void 0, void 0, function* () {
      const e = ObjectSerializer.normalizeMediaType(t.headers["content-type"]);
      if (isCodeInRange("200", t.httpStatusCode)) return new HttpInfo(t.httpStatusCode, t.headers, t.body, void 0);
      if (t.httpStatusCode >= 200 && t.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield t.body.text(), e), "void", "");
        return new HttpInfo(t.httpStatusCode, t.headers, t.body, i);
      }
      throw new ApiException(t.httpStatusCode, "Unknown API Status Code!", yield t.getBodyAsAny(), t.headers);
    });
  }
  filesNameIdGetWithHttpInfo(t) {
    return __awaiter(this, void 0, void 0, function* () {
      const e = ObjectSerializer.normalizeMediaType(t.headers["content-type"]);
      if (isCodeInRange("200", t.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield t.body.text(), e), "FileName", "");
        return new HttpInfo(t.httpStatusCode, t.headers, t.body, i);
      }
      if (t.httpStatusCode >= 200 && t.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield t.body.text(), e), "FileName", "");
        return new HttpInfo(t.httpStatusCode, t.headers, t.body, i);
      }
      throw new ApiException(t.httpStatusCode, "Unknown API Status Code!", yield t.getBodyAsAny(), t.headers);
    });
  }
  filesUploadPostWithHttpInfo(t) {
    return __awaiter(this, void 0, void 0, function* () {
      const e = ObjectSerializer.normalizeMediaType(t.headers["content-type"]);
      if (isCodeInRange("200", t.httpStatusCode)) return new HttpInfo(t.httpStatusCode, t.headers, t.body, void 0);
      if (t.httpStatusCode >= 200 && t.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield t.body.text(), e), "void", "");
        return new HttpInfo(t.httpStatusCode, t.headers, t.body, i);
      }
      throw new ApiException(t.httpStatusCode, "Unknown API Status Code!", yield t.getBodyAsAny(), t.headers);
    });
  }
}