export class ShipmentDocument {
  static getAttributeTypeMap() {
    return ShipmentDocument.attributeTypeMap;
  }
  constructor(t, e, i, a) {
    this.id = t, this.fileName = e, this.types = i, this.description = a;
  }
}
ShipmentDocument.discriminator = void 0, ShipmentDocument.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "fileName",
  baseName: "fileName",
  type: "string",
  format: ""
}, {
  name: "types",
  baseName: "types",
  type: "Array<string>",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];