export class TenantUserReadModel {
  static getAttributeTypeMap() {
    return TenantUserReadModel.attributeTypeMap;
  }
  constructor(e, t, a) {
    this.id = e, this.tenantName = t, this.users = a;
  }
}
TenantUserReadModel.discriminator = void 0, TenantUserReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "tenantName",
  baseName: "tenantName",
  type: "string",
  format: ""
}, {
  name: "users",
  baseName: "users",
  type: "Array<string>",
  format: ""
}];