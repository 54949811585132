export class NvxUpdate {
  static getAttributeTypeMap() {
    return NvxUpdate.attributeTypeMap;
  }
  constructor(t, a) {
    this.additionalInformation = t, this.receivedAt = a;
  }
}
NvxUpdate.discriminator = void 0, NvxUpdate.attributeTypeMap = [{
  name: "additionalInformation",
  baseName: "additionalInformation",
  type: "NvxUpdateAdditionalInformation",
  format: ""
}, {
  name: "receivedAt",
  baseName: "receivedAt",
  type: "Date",
  format: "date-time"
}];