export class NotificationRestore {
  static getAttributeTypeMap() {
    return NotificationRestore.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
NotificationRestore.discriminator = void 0, NotificationRestore.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}];