export class DeleteLocation {
  static getAttributeTypeMap() {
    return DeleteLocation.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
DeleteLocation.discriminator = void 0, DeleteLocation.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];