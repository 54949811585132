export class AssignDocumentToShipment {
  static getAttributeTypeMap() {
    return AssignDocumentToShipment.attributeTypeMap;
  }
  constructor(t, e, n, i) {
    this.nvxId = t, this.document = e, this.types = n, this.description = i;
  }
}
AssignDocumentToShipment.discriminator = void 0, AssignDocumentToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "document",
  baseName: "document",
  type: "AttachedFile",
  format: ""
}, {
  name: "types",
  baseName: "types",
  type: "Array<string>",
  format: ""
}, {
  name: "description",
  baseName: "description",
  type: "string",
  format: ""
}];