export class PublicUserProfileReadModel {
  static getAttributeTypeMap() {
    return PublicUserProfileReadModel.attributeTypeMap;
  }
  constructor(e, t, a, i, r, s, o) {
    this.id = e, this.userSub = t, this.firstname = a, this.lastname = i, this.salutation = r, this.profilePictureId = s, this.jobTitle = o;
  }
}
PublicUserProfileReadModel.discriminator = void 0, PublicUserProfileReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "firstname",
  baseName: "firstname",
  type: "string",
  format: ""
}, {
  name: "lastname",
  baseName: "lastname",
  type: "string",
  format: ""
}, {
  name: "salutation",
  baseName: "salutation",
  type: "string",
  format: ""
}, {
  name: "profilePictureId",
  baseName: "profilePictureId",
  type: "string",
  format: "uuid"
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}];