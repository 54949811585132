export class ShipmentOverviewAddress {
  static getAttributeTypeMap() {
    return ShipmentOverviewAddress.attributeTypeMap;
  }
  constructor(e, t, a, s, r, i, m, d) {
    this.id = e, this.address1 = t, this.address2 = a, this.zip = s, this.city = r, this.area = i, this.country = m, this.state = d;
  }
}
ShipmentOverviewAddress.discriminator = void 0, ShipmentOverviewAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "address1",
  baseName: "address1",
  type: "string",
  format: ""
}, {
  name: "address2",
  baseName: "address2",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "area",
  baseName: "area",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}];