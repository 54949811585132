export class SetValidationRule {
  static getAttributeTypeMap() {
    return SetValidationRule.attributeTypeMap;
  }
  constructor(t, e) {
    this.commandName = t, this.rule = e;
  }
}
SetValidationRule.discriminator = void 0, SetValidationRule.attributeTypeMap = [{
  name: "commandName",
  baseName: "commandName",
  type: "string",
  format: ""
}, {
  name: "rule",
  baseName: "rule",
  type: "string",
  format: ""
}];