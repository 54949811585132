export class UserNotificationSettingsReadModel {
  static getAttributeTypeMap() {
    return UserNotificationSettingsReadModel.attributeTypeMap;
  }
  constructor(t, e, i) {
    this.id = t, this.userSub = e, this.shipment = i;
  }
}
UserNotificationSettingsReadModel.discriminator = void 0, UserNotificationSettingsReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "shipment",
  baseName: "shipment",
  type: "Array<ShipmentNotificationSettingsEntry>",
  format: ""
}];