export class ShipmentGoods {
  static getAttributeTypeMap() {
    return ShipmentGoods.attributeTypeMap;
  }
  constructor(e, t, a, m, s, r, o, n, i, b, p, d, g, u, h) {
    this.id = e, this.orderIndex = t, this.containerNumber = a, this.containerType = m, this.grossWeight = s, this.commodity = r, this.legs = o, this.poNumbers = n, this.sealNumber = i, this.netWeight = b, this.hsCodes = p, this.numberOfPackages = d, this.packageCode = g, this.cbm = u, this.dangerousGoodsUnNumber = h;
  }
}
ShipmentGoods.discriminator = void 0, ShipmentGoods.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "orderIndex",
  baseName: "orderIndex",
  type: "number",
  format: "int32"
}, {
  name: "containerNumber",
  baseName: "containerNumber",
  type: "string",
  format: ""
}, {
  name: "containerType",
  baseName: "containerType",
  type: "string",
  format: ""
}, {
  name: "grossWeight",
  baseName: "grossWeight",
  type: "number",
  format: "float"
}, {
  name: "commodity",
  baseName: "commodity",
  type: "string",
  format: ""
}, {
  name: "legs",
  baseName: "legs",
  type: "Array<ShipmentGoodsLeg>",
  format: ""
}, {
  name: "poNumbers",
  baseName: "poNumbers",
  type: "Array<string>",
  format: ""
}, {
  name: "sealNumber",
  baseName: "sealNumber",
  type: "string",
  format: ""
}, {
  name: "netWeight",
  baseName: "netWeight",
  type: "number",
  format: "float"
}, {
  name: "hsCodes",
  baseName: "hsCodes",
  type: "string",
  format: ""
}, {
  name: "numberOfPackages",
  baseName: "numberOfPackages",
  type: "number",
  format: "int32"
}, {
  name: "packageCode",
  baseName: "packageCode",
  type: "string",
  format: ""
}, {
  name: "cbm",
  baseName: "cbm",
  type: "number",
  format: "int32"
}, {
  name: "dangerousGoodsUnNumber",
  baseName: "dangerousGoodsUnNumber",
  type: "string",
  format: ""
}];