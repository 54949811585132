export class SetShipmentOrigin {
  static getAttributeTypeMap() {
    return SetShipmentOrigin.attributeTypeMap;
  }
  constructor(t, i) {
    this.nvxId = t, this.origin = i;
  }
}
SetShipmentOrigin.discriminator = void 0, SetShipmentOrigin.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "origin",
  baseName: "origin",
  type: "string",
  format: ""
}];