export class UserBoundAddressReadModelPageResult {
  static getAttributeTypeMap() {
    return UserBoundAddressReadModelPageResult.attributeTypeMap;
  }
  constructor(e, t, s) {
    this.items = e, this.total = t, this.skipped = s;
  }
}
UserBoundAddressReadModelPageResult.discriminator = void 0, UserBoundAddressReadModelPageResult.attributeTypeMap = [{
  name: "items",
  baseName: "items",
  type: "Array<UserBoundAddressReadModel>",
  format: ""
}, {
  name: "total",
  baseName: "total",
  type: "number",
  format: "int32"
}, {
  name: "skipped",
  baseName: "skipped",
  type: "number",
  format: "int32"
}];