var __awaiter = this && this.__awaiter || function (e, t, i, r) {
  function a(e) {
    return e instanceof i ? e : new i(function (t) {
      t(e);
    });
  }
  return new (i || (i = Promise))(function (i, o) {
    function n(e) {
      try {
        s(r.next(e));
      } catch (t) {
        o(t);
      }
    }
    function d(e) {
      try {
        s(r["throw"](e));
      } catch (t) {
        o(t);
      }
    }
    function s(e) {
      e.done ? i(e.value) : a(e.value).then(n, d);
    }
    s((r = r.apply(e, t || [])).next());
  });
};
import { BaseAPIRequestFactory, RequiredError } from "./baseapi";
import { HttpMethod, HttpInfo } from "../http/http";
import { ObjectSerializer } from "../models/ObjectSerializer";
import { ApiException } from "./exception";
import { isCodeInRange } from "../util";
export class TenancyManagementApiRequestFactory extends BaseAPIRequestFactory {
  createTenant(e, t, i) {
    var r, a, o;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "createTenant", "createTenant");
      const d = "/commands/create-tenant",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const p = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "CreateTenant", ""), l);
      let c;
      s.setBody(p), c = n.authMethods["Bearer"], (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s));
      const u = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (o = null === (a = this.configuration) || void 0 === a ? void 0 : a.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(s)), s;
    });
  }
  disableTenant(e, t, i) {
    var r, a, o;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "disableTenant", "disableTenant");
      const d = "/commands/disable-tenant",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const p = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "DisableTenant", ""), l);
      let c;
      s.setBody(p), c = n.authMethods["Bearer"], (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s));
      const u = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (o = null === (a = this.configuration) || void 0 === a ? void 0 : a.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(s)), s;
    });
  }
  enableTenant(e, t, i) {
    var r, a, o;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "enableTenant", "enableTenant");
      const d = "/commands/enable-tenant",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const p = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "EnableTenant", ""), l);
      let c;
      s.setBody(p), c = n.authMethods["Bearer"], (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s));
      const u = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (o = null === (a = this.configuration) || void 0 === a ? void 0 : a.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(s)), s;
    });
  }
  getTenant(e, t) {
    var i, r, a;
    return __awaiter(this, void 0, void 0, function* () {
      let o = t || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "getTenant", "id");
      const n = "/read-models/tenant/{id}".replace("{id}", encodeURIComponent(String(e))),
        d = o.baseServer.makeRequestContext(n, HttpMethod.GET);
      let s;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), s = o.authMethods["Bearer"], (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d));
      const l = (null === (i = null === t || void 0 === t ? void 0 : t.authMethods) || void 0 === i ? void 0 : i.default) || (null === (a = null === (r = this.configuration) || void 0 === r ? void 0 : r.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === l || void 0 === l ? void 0 : l.applySecurityAuthentication) && (yield null === l || void 0 === l ? void 0 : l.applySecurityAuthentication(d)), d;
    });
  }
  getTenantUser(e, t) {
    var i, r, a;
    return __awaiter(this, void 0, void 0, function* () {
      let o = t || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "getTenantUser", "id");
      const n = "/read-models/tenant-user/{id}".replace("{id}", encodeURIComponent(String(e))),
        d = o.baseServer.makeRequestContext(n, HttpMethod.GET);
      let s;
      d.setHeaderParam("Accept", "application/json, */*;q=0.8"), s = o.authMethods["Bearer"], (null === s || void 0 === s ? void 0 : s.applySecurityAuthentication) && (yield null === s || void 0 === s ? void 0 : s.applySecurityAuthentication(d));
      const l = (null === (i = null === t || void 0 === t ? void 0 : t.authMethods) || void 0 === i ? void 0 : i.default) || (null === (a = null === (r = this.configuration) || void 0 === r ? void 0 : r.authMethods) || void 0 === a ? void 0 : a.default);
      return (null === l || void 0 === l ? void 0 : l.applySecurityAuthentication) && (yield null === l || void 0 === l ? void 0 : l.applySecurityAuthentication(d)), d;
    });
  }
  listTenant(e, t, i, r, a, o, n, d, s, l, p) {
    var c, u, h;
    return __awaiter(this, void 0, void 0, function* () {
      let S = p || this.configuration;
      const y = "/read-models/tenant",
        b = S.baseServer.makeRequestContext(y, HttpMethod.GET);
      let z;
      b.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && b.setQueryParam("sortField", ObjectSerializer.serialize(t, "'Id' | 'Name' | 'Enabled'", "")), void 0 !== i && b.setQueryParam("sortDirection", ObjectSerializer.serialize(i, "'ascending' | 'descending'", "")), void 0 !== r && b.setQueryParam("pageNumber", ObjectSerializer.serialize(r, "number", "")), void 0 !== a && b.setQueryParam("pageSize", ObjectSerializer.serialize(a, "number", "")), void 0 !== o && b.setQueryParam("ts-Id", ObjectSerializer.serialize(o, "string", "")), void 0 !== n && b.setQueryParam("ts-Name", ObjectSerializer.serialize(n, "string", "")), void 0 !== d && b.setQueryParam("eq-Id", ObjectSerializer.serialize(d, "Array<string>", "")), void 0 !== s && b.setQueryParam("eq-Name", ObjectSerializer.serialize(s, "Array<string>", "")), void 0 !== l && b.setQueryParam("eq-Enabled", ObjectSerializer.serialize(l, "Array<boolean>", "")), void 0 !== e && b.setHeaderParam("If-None-Match", ObjectSerializer.serialize(e, "string", "")), z = S.authMethods["Bearer"], (null === z || void 0 === z ? void 0 : z.applySecurityAuthentication) && (yield null === z || void 0 === z ? void 0 : z.applySecurityAuthentication(b));
      const v = (null === (c = null === p || void 0 === p ? void 0 : p.authMethods) || void 0 === c ? void 0 : c.default) || (null === (h = null === (u = this.configuration) || void 0 === u ? void 0 : u.authMethods) || void 0 === h ? void 0 : h.default);
      return (null === v || void 0 === v ? void 0 : v.applySecurityAuthentication) && (yield null === v || void 0 === v ? void 0 : v.applySecurityAuthentication(b)), b;
    });
  }
  listTenantUser(e, t, i, r, a, o, n, d, s, l) {
    var p, c, u;
    return __awaiter(this, void 0, void 0, function* () {
      let h = l || this.configuration;
      const S = "/read-models/tenant-user",
        y = h.baseServer.makeRequestContext(S, HttpMethod.GET);
      let b;
      y.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && y.setQueryParam("sortField", ObjectSerializer.serialize(t, "'Id' | 'TenantName'", "")), void 0 !== i && y.setQueryParam("sortDirection", ObjectSerializer.serialize(i, "'ascending' | 'descending'", "")), void 0 !== r && y.setQueryParam("pageNumber", ObjectSerializer.serialize(r, "number", "")), void 0 !== a && y.setQueryParam("pageSize", ObjectSerializer.serialize(a, "number", "")), void 0 !== o && y.setQueryParam("ts-Id", ObjectSerializer.serialize(o, "string", "")), void 0 !== n && y.setQueryParam("ts-TenantName", ObjectSerializer.serialize(n, "string", "")), void 0 !== d && y.setQueryParam("eq-Id", ObjectSerializer.serialize(d, "Array<string>", "")), void 0 !== s && y.setQueryParam("eq-TenantName", ObjectSerializer.serialize(s, "Array<string>", "")), void 0 !== e && y.setHeaderParam("If-None-Match", ObjectSerializer.serialize(e, "string", "")), b = h.authMethods["Bearer"], (null === b || void 0 === b ? void 0 : b.applySecurityAuthentication) && (yield null === b || void 0 === b ? void 0 : b.applySecurityAuthentication(y));
      const z = (null === (p = null === l || void 0 === l ? void 0 : l.authMethods) || void 0 === p ? void 0 : p.default) || (null === (u = null === (c = this.configuration) || void 0 === c ? void 0 : c.authMethods) || void 0 === u ? void 0 : u.default);
      return (null === z || void 0 === z ? void 0 : z.applySecurityAuthentication) && (yield null === z || void 0 === z ? void 0 : z.applySecurityAuthentication(y)), y;
    });
  }
  renameTenant(e, t, i) {
    var r, a, o;
    return __awaiter(this, void 0, void 0, function* () {
      let n = i || this.configuration;
      if (null === e || void 0 === e) throw new RequiredError("TenancyManagementApi", "renameTenant", "renameTenant");
      const d = "/commands/rename-tenant",
        s = n.baseServer.makeRequestContext(d, HttpMethod.POST);
      s.setHeaderParam("Accept", "application/json, */*;q=0.8"), void 0 !== t && s.setHeaderParam("IdempotencyKey", ObjectSerializer.serialize(t, "string", ""));
      const l = ObjectSerializer.getPreferredMediaType(["application/json"]);
      s.setHeaderParam("Content-Type", l);
      const p = ObjectSerializer.stringify(ObjectSerializer.serialize(e, "RenameTenant", ""), l);
      let c;
      s.setBody(p), c = n.authMethods["Bearer"], (null === c || void 0 === c ? void 0 : c.applySecurityAuthentication) && (yield null === c || void 0 === c ? void 0 : c.applySecurityAuthentication(s));
      const u = (null === (r = null === i || void 0 === i ? void 0 : i.authMethods) || void 0 === r ? void 0 : r.default) || (null === (o = null === (a = this.configuration) || void 0 === a ? void 0 : a.authMethods) || void 0 === o ? void 0 : o.default);
      return (null === u || void 0 === u ? void 0 : u.applySecurityAuthentication) && (yield null === u || void 0 === u ? void 0 : u.applySecurityAuthentication(s)), s;
    });
  }
}
export class TenancyManagementApiResponseProcessor {
  createTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  disableTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  enableTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  getTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("404", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Not Found", i, e.headers);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  getTenantUserWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantUserReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("404", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Not Found", i, e.headers);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantUserReadModel", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  listTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  listTenantUserWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantUserReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "TenantUserReadModelPageResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
  renameTenantWithHttpInfo(e) {
    return __awaiter(this, void 0, void 0, function* () {
      const t = ObjectSerializer.normalizeMediaType(e.headers["content-type"]);
      if (isCodeInRange("200", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      if (isCodeInRange("500", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Internal Server Error", i, e.headers);
      }
      if (isCodeInRange("400", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Bad Request", i, e.headers);
      }
      if (isCodeInRange("409", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Conflict", i, e.headers);
      }
      if (isCodeInRange("401", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Unauthorized", i, e.headers);
      }
      if (isCodeInRange("403", e.httpStatusCode)) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "ErrorResponse", "");
        throw new ApiException(e.httpStatusCode, "Forbidden", i, e.headers);
      }
      if (e.httpStatusCode >= 200 && e.httpStatusCode <= 299) {
        const i = ObjectSerializer.deserialize(ObjectSerializer.parse(yield e.body.text(), t), "CommandAcceptedResult", "");
        return new HttpInfo(e.httpStatusCode, e.headers, e.body, i);
      }
      throw new ApiException(e.httpStatusCode, "Unknown API Status Code!", yield e.getBodyAsAny(), e.headers);
    });
  }
}