export class UpdateMilestoneDueDate {
  static getAttributeTypeMap() {
    return UpdateMilestoneDueDate.attributeTypeMap;
  }
  constructor(e, t) {
    this.id = e, this.dueDate = t;
  }
}
UpdateMilestoneDueDate.discriminator = void 0, UpdateMilestoneDueDate.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "dueDate",
  baseName: "dueDate",
  type: "Date",
  format: "date-time"
}];