import { of, from } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { AddressBookApiRequestFactory, AddressBookApiResponseProcessor } from "../apis/AddressBookApi";
export class ObservableAddressBookApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new AddressBookApiRequestFactory(e), this.responseProcessor = r || new AddressBookApiResponseProcessor();
  }
  addAddressToLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addAddressToLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addAddressToLocationWithHttpInfo(e)));
    }));
  }
  addAddressToLocation(e, t, r, i) {
    return this.addAddressToLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  addExternalSystemReferenceToDirectoryLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addExternalSystemReferenceToDirectoryLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addExternalSystemReferenceToDirectoryLocationWithHttpInfo(e)));
    }));
  }
  addExternalSystemReferenceToDirectoryLocation(e, t, r, i) {
    return this.addExternalSystemReferenceToDirectoryLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  addLocationExternalSystemReferencesWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addLocationExternalSystemReferences(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addLocationExternalSystemReferencesWithHttpInfo(e)));
    }));
  }
  addLocationExternalSystemReferences(e, t, r, i) {
    return this.addLocationExternalSystemReferencesWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  addOrganizationByEmailWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addOrganizationByEmail(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addOrganizationByEmailWithHttpInfo(e)));
    }));
  }
  addOrganizationByEmail(e, t, r, i) {
    return this.addOrganizationByEmailWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  addTypeToOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addTypeToOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addTypeToOrganizationWithHttpInfo(e)));
    }));
  }
  addTypeToOrganization(e, t, r, i) {
    return this.addTypeToOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignContactPersonToOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignContactPersonToOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignContactPersonToOrganizationWithHttpInfo(e)));
    }));
  }
  assignContactPersonToOrganization(e, t, r, i) {
    return this.assignContactPersonToOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignUserToContactPersonWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignUserToContactPerson(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignUserToContactPersonWithHttpInfo(e)));
    }));
  }
  assignUserToContactPerson(e, t, r, i) {
    return this.assignUserToContactPersonWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createAddressWithHttpInfo(e)));
    }));
  }
  createAddress(e, t, r, i) {
    return this.createAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createContactPersonWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createContactPerson(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createContactPersonWithHttpInfo(e)));
    }));
  }
  createContactPerson(e, t, r, i) {
    return this.createContactPersonWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createLocationWithHttpInfo(e)));
    }));
  }
  createLocation(e, t, r, i) {
    return this.createLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createOrganizationWithHttpInfo(e)));
    }));
  }
  createOrganization(e, t, r, i) {
    return this.createOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createOrganizationDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createOrganizationDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createOrganizationDirectoryWithHttpInfo(e)));
    }));
  }
  createOrganizationDirectory(e, t, r, i) {
    return this.createOrganizationDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteAddressWithHttpInfo(e)));
    }));
  }
  deleteAddress(e, t, r, i) {
    return this.deleteAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteContactPersonWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteContactPerson(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteContactPersonWithHttpInfo(e)));
    }));
  }
  deleteContactPerson(e, t, r, i) {
    return this.deleteContactPersonWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteLocationWithHttpInfo(e)));
    }));
  }
  deleteLocation(e, t, r, i) {
    return this.deleteLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteOrganizationWithHttpInfo(e)));
    }));
  }
  deleteOrganization(e, t, r, i) {
    return this.deleteOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteOrganizationDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteOrganizationDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteOrganizationDirectoryWithHttpInfo(e)));
    }));
  }
  deleteOrganizationDirectory(e, t, r, i) {
    return this.deleteOrganizationDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  getAdminAddressWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getAdminAddress(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getAdminAddressWithHttpInfo(e)));
    }));
  }
  getAdminAddress(e, t, r) {
    return this.getAdminAddressWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getAdminOrganizationWithHttpInfo(e, t) {
    return from(this.requestFactory.getAdminOrganization(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getAdminOrganizationWithHttpInfo(e)));
    }));
  }
  getAdminOrganization(e, t) {
    return this.getAdminOrganizationWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getCarrierOrganizationWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getCarrierOrganization(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getCarrierOrganizationWithHttpInfo(e)));
    }));
  }
  getCarrierOrganization(e, t, r) {
    return this.getCarrierOrganizationWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getContactPersonWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getContactPerson(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getContactPersonWithHttpInfo(e)));
    }));
  }
  getContactPerson(e, t, r) {
    return this.getContactPersonWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getContactPersonWithAssignedUserWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getContactPersonWithAssignedUser(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getContactPersonWithAssignedUserWithHttpInfo(e)));
    }));
  }
  getContactPersonWithAssignedUser(e, t, r) {
    return this.getContactPersonWithAssignedUserWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getLocationWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getLocation(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getLocationWithHttpInfo(e)));
    }));
  }
  getLocation(e, t, r) {
    return this.getLocationWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getOrganizationWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getOrganization(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getOrganizationWithHttpInfo(e)));
    }));
  }
  getOrganization(e, t, r) {
    return this.getOrganizationWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getOrganizationDirectoryWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getOrganizationDirectory(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getOrganizationDirectoryWithHttpInfo(e)));
    }));
  }
  getOrganizationDirectory(e, t, r) {
    return this.getOrganizationDirectoryWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getPublicAddressWithHttpInfo(e, t) {
    return from(this.requestFactory.getPublicAddress(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getPublicAddressWithHttpInfo(e)));
    }));
  }
  getPublicAddress(e, t) {
    return this.getPublicAddressWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getPublicLocationWithHttpInfo(e, t) {
    return from(this.requestFactory.getPublicLocation(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getPublicLocationWithHttpInfo(e)));
    }));
  }
  getPublicLocation(e, t) {
    return this.getPublicLocationWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getSystemGeneratedCarrierOrganizationWithHttpInfo(e, t) {
    return from(this.requestFactory.getSystemGeneratedCarrierOrganization(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getSystemGeneratedCarrierOrganizationWithHttpInfo(e)));
    }));
  }
  getSystemGeneratedCarrierOrganization(e, t) {
    return this.getSystemGeneratedCarrierOrganizationWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserBoundAddressWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getUserBoundAddress(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserBoundAddressWithHttpInfo(e)));
    }));
  }
  getUserBoundAddress(e, t, r) {
    return this.getUserBoundAddressWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getUserOrganizationWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getUserOrganization(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserOrganizationWithHttpInfo(e)));
    }));
  }
  getUserOrganization(e, t, r) {
    return this.getUserOrganizationWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getUserValidAddressWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getUserValidAddress(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserValidAddressWithHttpInfo(e)));
    }));
  }
  getUserValidAddress(e, t, r) {
    return this.getUserValidAddressWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  listAdminAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye) {
    return from(this.requestFactory.listAdminAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listAdminAddressWithHttpInfo(e)));
    }));
  }
  listAdminAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye) {
    return this.listAdminAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye).pipe(map(e => e.data));
  }
  listAdminOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return from(this.requestFactory.listAdminOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listAdminOrganizationWithHttpInfo(e)));
    }));
  }
  listAdminOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return this.listAdminOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we).pipe(map(e => e.data));
  }
  listCarrierOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe) {
    return from(this.requestFactory.listCarrierOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listCarrierOrganizationWithHttpInfo(e)));
    }));
  }
  listCarrierOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe) {
    return this.listCarrierOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe).pipe(map(e => e.data));
  }
  listContactPersonWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D) {
    return from(this.requestFactory.listContactPerson(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listContactPersonWithHttpInfo(e)));
    }));
  }
  listContactPerson(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D) {
    return this.listContactPersonWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D).pipe(map(e => e.data));
  }
  listContactPersonWithAssignedUserWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z) {
    return from(this.requestFactory.listContactPersonWithAssignedUser(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listContactPersonWithAssignedUserWithHttpInfo(e)));
    }));
  }
  listContactPersonWithAssignedUser(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z) {
    return this.listContactPersonWithAssignedUserWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z).pipe(map(e => e.data));
  }
  listLocationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S) {
    return from(this.requestFactory.listLocation(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listLocationWithHttpInfo(e)));
    }));
  }
  listLocation(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S) {
    return this.listLocationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S).pipe(map(e => e.data));
  }
  listOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve) {
    return from(this.requestFactory.listOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listOrganizationWithHttpInfo(e)));
    }));
  }
  listOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve) {
    return this.listOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve).pipe(map(e => e.data));
  }
  listOrganizationDirectoryWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W) {
    return from(this.requestFactory.listOrganizationDirectory(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listOrganizationDirectoryWithHttpInfo(e)));
    }));
  }
  listOrganizationDirectory(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W) {
    return this.listOrganizationDirectoryWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W).pipe(map(e => e.data));
  }
  listPublicAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k) {
    return from(this.requestFactory.listPublicAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listPublicAddressWithHttpInfo(e)));
    }));
  }
  listPublicAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k) {
    return this.listPublicAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k).pipe(map(e => e.data));
  }
  listPublicLocationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g) {
    return from(this.requestFactory.listPublicLocation(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listPublicLocationWithHttpInfo(e)));
    }));
  }
  listPublicLocation(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g) {
    return this.listPublicLocationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g).pipe(map(e => e.data));
  }
  listSystemGeneratedCarrierOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie) {
    return from(this.requestFactory.listSystemGeneratedCarrierOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listSystemGeneratedCarrierOrganizationWithHttpInfo(e)));
    }));
  }
  listSystemGeneratedCarrierOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie) {
    return this.listSystemGeneratedCarrierOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie).pipe(map(e => e.data));
  }
  listUserBoundAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he) {
    return from(this.requestFactory.listUserBoundAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserBoundAddressWithHttpInfo(e)));
    }));
  }
  listUserBoundAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he) {
    return this.listUserBoundAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he).pipe(map(e => e.data));
  }
  listUserOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u) {
    return from(this.requestFactory.listUserOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserOrganizationWithHttpInfo(e)));
    }));
  }
  listUserOrganization(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u) {
    return this.listUserOrganizationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u).pipe(map(e => e.data));
  }
  listUserValidAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return from(this.requestFactory.listUserValidAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserValidAddressWithHttpInfo(e)));
    }));
  }
  listUserValidAddress(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return this.listUserValidAddressWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L).pipe(map(e => e.data));
  }
  makeAddressPrivateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeAddressPrivate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeAddressPrivateWithHttpInfo(e)));
    }));
  }
  makeAddressPrivate(e, t, r, i) {
    return this.makeAddressPrivateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  makeAddressPublicWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeAddressPublic(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeAddressPublicWithHttpInfo(e)));
    }));
  }
  makeAddressPublic(e, t, r, i) {
    return this.makeAddressPublicWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  makeLocationPrivateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeLocationPrivate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeLocationPrivateWithHttpInfo(e)));
    }));
  }
  makeLocationPrivate(e, t, r, i) {
    return this.makeLocationPrivateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  makeLocationPublicWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeLocationPublic(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeLocationPublicWithHttpInfo(e)));
    }));
  }
  makeLocationPublic(e, t, r, i) {
    return this.makeLocationPublicWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  makeOrganizationPrivateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeOrganizationPrivate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeOrganizationPrivateWithHttpInfo(e)));
    }));
  }
  makeOrganizationPrivate(e, t, r, i) {
    return this.makeOrganizationPrivateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  makeOrganizationPublicWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.makeOrganizationPublic(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.makeOrganizationPublicWithHttpInfo(e)));
    }));
  }
  makeOrganizationPublic(e, t, r, i) {
    return this.makeOrganizationPublicWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  rejectAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.rejectAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rejectAddressWithHttpInfo(e)));
    }));
  }
  rejectAddress(e, t, r, i) {
    return this.rejectAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeExternalSystemReferenceFromDirectoryLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeExternalSystemReferenceFromDirectoryLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeExternalSystemReferenceFromDirectoryLocationWithHttpInfo(e)));
    }));
  }
  removeExternalSystemReferenceFromDirectoryLocation(e, t, r, i) {
    return this.removeExternalSystemReferenceFromDirectoryLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeLocationExternalSystemReferencesWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeLocationExternalSystemReferences(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeLocationExternalSystemReferencesWithHttpInfo(e)));
    }));
  }
  removeLocationExternalSystemReferences(e, t, r, i) {
    return this.removeLocationExternalSystemReferencesWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeTypeFromOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeTypeFromOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeTypeFromOrganizationWithHttpInfo(e)));
    }));
  }
  removeTypeFromOrganization(e, t, r, i) {
    return this.removeTypeFromOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreAddressWithHttpInfo(e)));
    }));
  }
  restoreAddress(e, t, r, i) {
    return this.restoreAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreLocationWithHttpInfo(e)));
    }));
  }
  restoreLocation(e, t, r, i) {
    return this.restoreLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreOrganizationWithHttpInfo(e)));
    }));
  }
  restoreOrganization(e, t, r, i) {
    return this.restoreOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreOrganizationDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreOrganizationDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreOrganizationDirectoryWithHttpInfo(e)));
    }));
  }
  restoreOrganizationDirectory(e, t, r, i) {
    return this.restoreOrganizationDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateAddressWithHttpInfo(e)));
    }));
  }
  updateAddress(e, t, r, i) {
    return this.updateAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateContactPersonWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateContactPerson(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateContactPersonWithHttpInfo(e)));
    }));
  }
  updateContactPerson(e, t, r, i) {
    return this.updateContactPersonWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateLocationWithHttpInfo(e)));
    }));
  }
  updateLocation(e, t, r, i) {
    return this.updateLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateOrganizationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateOrganization(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateOrganizationWithHttpInfo(e)));
    }));
  }
  updateOrganization(e, t, r, i) {
    return this.updateOrganizationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  validateAddressWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.validateAddress(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.validateAddressWithHttpInfo(e)));
    }));
  }
  validateAddress(e, t, r, i) {
    return this.validateAddressWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { AuthorizationApiRequestFactory, AuthorizationApiResponseProcessor } from "../apis/AuthorizationApi";
export class ObservableAuthorizationApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new AuthorizationApiRequestFactory(e), this.responseProcessor = r || new AuthorizationApiResponseProcessor();
  }
  addToTenantWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addToTenant(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addToTenantWithHttpInfo(e)));
    }));
  }
  addToTenant(e, t, r, i) {
    return this.addToTenantWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignApplicationRoleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.assignApplicationRole(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignApplicationRoleWithHttpInfo(e)));
    }));
  }
  assignApplicationRole(e, t, r) {
    return this.assignApplicationRoleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  assignTenantRoleWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignTenantRole(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignTenantRoleWithHttpInfo(e)));
    }));
  }
  assignTenantRole(e, t, r, i) {
    return this.assignTenantRoleWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createAdditionalRoleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.createAdditionalRole(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createAdditionalRoleWithHttpInfo(e)));
    }));
  }
  createAdditionalRole(e, t, r) {
    return this.createAdditionalRoleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getAdditionalRolesWithHttpInfo(e, t) {
    return from(this.requestFactory.getAdditionalRoles(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getAdditionalRolesWithHttpInfo(e)));
    }));
  }
  getAdditionalRoles(e, t) {
    return this.getAdditionalRolesWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserSecurityWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserSecurity(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserSecurityWithHttpInfo(e)));
    }));
  }
  getUserSecurity(e, t) {
    return this.getUserSecurityWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserWithRoleWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserWithRole(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserWithRoleWithHttpInfo(e)));
    }));
  }
  getUserWithRole(e, t) {
    return this.getUserWithRoleWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserWithTenantRoleWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserWithTenantRole(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserWithTenantRoleWithHttpInfo(e)));
    }));
  }
  getUserWithTenantRole(e, t) {
    return this.getUserWithTenantRoleWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  listAdditionalRolesWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f) {
    return from(this.requestFactory.listAdditionalRoles(e, t, r, i, o, p, n, s, a, h, d, f)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listAdditionalRolesWithHttpInfo(e)));
    }));
  }
  listAdditionalRoles(e, t, r, i, o, p, n, s, a, h, d, f) {
    return this.listAdditionalRolesWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f).pipe(map(e => e.data));
  }
  listUserSecurityWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l) {
    return from(this.requestFactory.listUserSecurity(e, t, r, i, o, p, n, s, a, h, d, f, m, l)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserSecurityWithHttpInfo(e)));
    }));
  }
  listUserSecurity(e, t, r, i, o, p, n, s, a, h, d, f, m, l) {
    return this.listUserSecurityWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l).pipe(map(e => e.data));
  }
  listUserWithRoleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I) {
    return from(this.requestFactory.listUserWithRole(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserWithRoleWithHttpInfo(e)));
    }));
  }
  listUserWithRole(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I) {
    return this.listUserWithRoleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I).pipe(map(e => e.data));
  }
  listUserWithTenantRoleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W) {
    return from(this.requestFactory.listUserWithTenantRole(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserWithTenantRoleWithHttpInfo(e)));
    }));
  }
  listUserWithTenantRole(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W) {
    return this.listUserWithTenantRoleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W).pipe(map(e => e.data));
  }
  removeAdditionalRoleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.removeAdditionalRole(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeAdditionalRoleWithHttpInfo(e)));
    }));
  }
  removeAdditionalRole(e, t, r) {
    return this.removeAdditionalRoleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  removeFromTenantWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeFromTenant(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeFromTenantWithHttpInfo(e)));
    }));
  }
  removeFromTenant(e, t, r, i) {
    return this.removeFromTenantWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  revokeApplicationRoleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.revokeApplicationRole(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.revokeApplicationRoleWithHttpInfo(e)));
    }));
  }
  revokeApplicationRole(e, t, r) {
    return this.revokeApplicationRoleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  revokeTenantRoleWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.revokeTenantRole(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.revokeTenantRoleWithHttpInfo(e)));
    }));
  }
  revokeTenantRole(e, t, r, i) {
    return this.revokeTenantRoleWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  rolesGetWithHttpInfo(e) {
    return from(this.requestFactory.rolesGet(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rolesGetWithHttpInfo(e)));
    }));
  }
  rolesGet(e) {
    return this.rolesGetWithHttpInfo(e).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { ChatApiRequestFactory, ChatApiResponseProcessor } from "../apis/ChatApi";
export class ObservableChatApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new ChatApiRequestFactory(e), this.responseProcessor = r || new ChatApiResponseProcessor();
  }
  addUserToChannelWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addUserToChannel(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addUserToChannelWithHttpInfo(e)));
    }));
  }
  addUserToChannel(e, t, r, i) {
    return this.addUserToChannelWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  archiveChatChannelWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.archiveChatChannel(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.archiveChatChannelWithHttpInfo(e)));
    }));
  }
  archiveChatChannel(e, t, r, i) {
    return this.archiveChatChannelWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createChatChannelWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createChatChannel(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createChatChannelWithHttpInfo(e)));
    }));
  }
  createChatChannel(e, t, r, i) {
    return this.createChatChannelWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteChatMessageWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteChatMessage(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteChatMessageWithHttpInfo(e)));
    }));
  }
  deleteChatMessage(e, t, r, i) {
    return this.deleteChatMessageWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  editChatMessageWithHttpInfo(e, t, r) {
    return from(this.requestFactory.editChatMessage(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.editChatMessageWithHttpInfo(e)));
    }));
  }
  editChatMessage(e, t, r) {
    return this.editChatMessageWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getChatChannelWithHttpInfo(e, t) {
    return from(this.requestFactory.getChatChannel(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getChatChannelWithHttpInfo(e)));
    }));
  }
  getChatChannel(e, t) {
    return this.getChatChannelWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  leaveChatChannelWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.leaveChatChannel(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.leaveChatChannelWithHttpInfo(e)));
    }));
  }
  leaveChatChannel(e, t, r, i) {
    return this.leaveChatChannelWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  listChatChannelWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I) {
    return from(this.requestFactory.listChatChannel(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listChatChannelWithHttpInfo(e)));
    }));
  }
  listChatChannel(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I) {
    return this.listChatChannelWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I).pipe(map(e => e.data));
  }
  markChatAsReadWithHttpInfo(e, t, r) {
    return from(this.requestFactory.markChatAsRead(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.markChatAsReadWithHttpInfo(e)));
    }));
  }
  markChatAsRead(e, t, r) {
    return this.markChatAsReadWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  markChatAsUnreadWithHttpInfo(e, t, r) {
    return from(this.requestFactory.markChatAsUnread(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.markChatAsUnreadWithHttpInfo(e)));
    }));
  }
  markChatAsUnread(e, t, r) {
    return this.markChatAsUnreadWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  sendChatMessageWithHttpInfo(e, t, r) {
    return from(this.requestFactory.sendChatMessage(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.sendChatMessageWithHttpInfo(e)));
    }));
  }
  sendChatMessage(e, t, r) {
    return this.sendChatMessageWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  unarchiveChatChannelWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.unarchiveChatChannel(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.unarchiveChatChannelWithHttpInfo(e)));
    }));
  }
  unarchiveChatChannel(e, t, r, i) {
    return this.unarchiveChatChannelWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { CurrentUserApiRequestFactory, CurrentUserApiResponseProcessor } from "../apis/CurrentUserApi";
export class ObservableCurrentUserApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new CurrentUserApiRequestFactory(e), this.responseProcessor = r || new CurrentUserApiResponseProcessor();
  }
  currentUserGetWithHttpInfo(e) {
    return from(this.requestFactory.currentUserGet(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.currentUserGetWithHttpInfo(e)));
    }));
  }
  currentUserGet(e) {
    return this.currentUserGetWithHttpInfo(e).pipe(map(e => e.data));
  }
  currentUserRolesGetWithHttpInfo(e) {
    return from(this.requestFactory.currentUserRolesGet(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.currentUserRolesGetWithHttpInfo(e)));
    }));
  }
  currentUserRolesGet(e) {
    return this.currentUserRolesGetWithHttpInfo(e).pipe(map(e => e.data));
  }
  tenantTenantIdCurrentUserRolesGetWithHttpInfo(e, t) {
    return from(this.requestFactory.tenantTenantIdCurrentUserRolesGet(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.tenantTenantIdCurrentUserRolesGetWithHttpInfo(e)));
    }));
  }
  tenantTenantIdCurrentUserRolesGet(e, t) {
    return this.tenantTenantIdCurrentUserRolesGetWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { FilesApiRequestFactory, FilesApiResponseProcessor } from "../apis/FilesApi";
export class ObservableFilesApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new FilesApiRequestFactory(e), this.responseProcessor = r || new FilesApiResponseProcessor();
  }
  filesDownloadIdGetWithHttpInfo(e, t) {
    return from(this.requestFactory.filesDownloadIdGet(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.filesDownloadIdGetWithHttpInfo(e)));
    }));
  }
  filesDownloadIdGet(e, t) {
    return this.filesDownloadIdGetWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  filesNameIdGetWithHttpInfo(e, t) {
    return from(this.requestFactory.filesNameIdGet(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.filesNameIdGetWithHttpInfo(e)));
    }));
  }
  filesNameIdGet(e, t) {
    return this.filesNameIdGetWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  filesUploadPostWithHttpInfo(e, t) {
    return from(this.requestFactory.filesUploadPost(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.filesUploadPostWithHttpInfo(e)));
    }));
  }
  filesUploadPost(e, t) {
    return this.filesUploadPostWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { FrameworkManagementApiRequestFactory, FrameworkManagementApiResponseProcessor } from "../apis/FrameworkManagementApi";
export class ObservableFrameworkManagementApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new FrameworkManagementApiRequestFactory(e), this.responseProcessor = r || new FrameworkManagementApiResponseProcessor();
  }
  rerunProjectionUserEmailReceivedEmailReceivedProfileProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionUserEmailReceivedEmailReceivedProfileProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionUserEmailReceivedEmailReceivedProfileProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionUserEmailReceivedEmailReceivedProfileProjection(e) {
    return this.rerunProjectionUserEmailReceivedEmailReceivedProfileProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddUserToChannelFromShipmentAssignedUserWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddUserToChannelFromShipmentAssignedUser(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddUserToChannelFromShipmentAssignedUserWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddUserToChannelFromShipmentAssignedUser(e) {
    return this.rerunProjectionaddUserToChannelFromShipmentAssignedUserWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjection(e) {
    return this.rerunProjectionaddressCreatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddressRejectedByAdminNotificationProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddressRejectedByAdminNotificationProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddressRejectedByAdminNotificationProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddressRejectedByAdminNotificationProjection(e) {
    return this.rerunProjectionaddressRejectedByAdminNotificationProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjection(e) {
    return this.rerunProjectionaddressRejectedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddressValidatedByAdminNotificationProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddressValidatedByAdminNotificationProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddressValidatedByAdminNotificationProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddressValidatedByAdminNotificationProjection(e) {
    return this.rerunProjectionaddressValidatedByAdminNotificationProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjection(e) {
    return this.rerunProjectionaddressValidatedNotificationToAllAddressBookAdminsProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectioncontactPersonAssignedToOrganizationProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectioncontactPersonAssignedToOrganizationProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectioncontactPersonAssignedToOrganizationProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectioncontactPersonAssignedToOrganizationProjection(e) {
    return this.rerunProjectioncontactPersonAssignedToOrganizationProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectioncontactPersonDeletedToOrganizationProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectioncontactPersonDeletedToOrganizationProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectioncontactPersonDeletedToOrganizationProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectioncontactPersonDeletedToOrganizationProjection(e) {
    return this.rerunProjectioncontactPersonDeletedToOrganizationProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectioncreateChatChannelFromShipmentChatChannelWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectioncreateChatChannelFromShipmentChatChannel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectioncreateChatChannelFromShipmentChatChannelWithHttpInfo(e)));
    }));
  }
  rerunProjectioncreateChatChannelFromShipmentChatChannel(e) {
    return this.rerunProjectioncreateChatChannelFromShipmentChatChannelWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealingWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealing(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealingWithHttpInfo(e)));
    }));
  }
  rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealing(e) {
    return this.rerunProjectioncustomerAddedToTenantToInvoiceTenantIdReceivedSelfHealingWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequestedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequested(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequestedWithHttpInfo(e)));
    }));
  }
  rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequested(e) {
    return this.rerunProjectionexternalInvoiceEventRegisteredToInvoiceTenantIdRequestedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionframeworkTodoProcessorSnapshotCompletedTasksWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionframeworkTodoProcessorSnapshotCompletedTasks(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionframeworkTodoProcessorSnapshotCompletedTasksWithHttpInfo(e)));
    }));
  }
  rerunProjectionframeworkTodoProcessorSnapshotCompletedTasks(e) {
    return this.rerunProjectionframeworkTodoProcessorSnapshotCompletedTasksWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectiongoodsCreatedToShipmentGoodsAssignedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectiongoodsCreatedToShipmentGoodsAssigned(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectiongoodsCreatedToShipmentGoodsAssignedWithHttpInfo(e)));
    }));
  }
  rerunProjectiongoodsCreatedToShipmentGoodsAssigned(e) {
    return this.rerunProjectiongoodsCreatedToShipmentGoodsAssignedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectiongoodsRemovedToShipmentGotGoodsRemovedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectiongoodsRemovedToShipmentGotGoodsRemoved(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectiongoodsRemovedToShipmentGotGoodsRemovedWithHttpInfo(e)));
    }));
  }
  rerunProjectiongoodsRemovedToShipmentGotGoodsRemoved(e) {
    return this.rerunProjectiongoodsRemovedToShipmentGotGoodsRemovedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdateWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdate(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdateWithHttpInfo(e)));
    }));
  }
  rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdate(e) {
    return this.rerunProjectiongoodsUpdatedToShipmentReceivedGoodsUpdateWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceivedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceived(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceivedWithHttpInfo(e)));
    }));
  }
  rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceived(e) {
    return this.rerunProjectioninvoiceTenantIdRequestedToInvoiceTenantIdReceivedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionlocationAssignedToAddressProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionlocationAssignedToAddressProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionlocationAssignedToAddressProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionlocationAssignedToAddressProjection(e) {
    return this.rerunProjectionlocationAssignedToAddressProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionmessageForAllUsersWithRoleInTenantProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionmessageForAllUsersWithRoleInTenantProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionmessageForAllUsersWithRoleInTenantProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionmessageForAllUsersWithRoleInTenantProjection(e) {
    return this.rerunProjectionmessageForAllUsersWithRoleInTenantProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnewSignupReceivedToTenantRoleAssignedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnewSignupReceivedToTenantRoleAssigned(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnewSignupReceivedToTenantRoleAssignedWithHttpInfo(e)));
    }));
  }
  rerunProjectionnewSignupReceivedToTenantRoleAssigned(e) {
    return this.rerunProjectionnewSignupReceivedToTenantRoleAssignedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyChatMessageReceivedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyChatMessageReceived(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyChatMessageReceivedWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyChatMessageReceived(e) {
    return this.rerunProjectionnotifyChatMessageReceivedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyContainerJourneyCompletedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyContainerJourneyCompleted(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyContainerJourneyCompletedWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyContainerJourneyCompleted(e) {
    return this.rerunProjectionnotifyContainerJourneyCompletedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyExternalInvoiceCompletedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyExternalInvoiceCompleted(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyExternalInvoiceCompletedWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyExternalInvoiceCompleted(e) {
    return this.rerunProjectionnotifyExternalInvoiceCompletedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyShipmentDocumentUploadedProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyShipmentDocumentUploadedProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyShipmentDocumentUploadedProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyShipmentDocumentUploadedProjection(e) {
    return this.rerunProjectionnotifyShipmentDocumentUploadedProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyShipmentDraftCompletedProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyShipmentDraftCompletedProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyShipmentDraftCompletedProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyShipmentDraftCompletedProjection(e) {
    return this.rerunProjectionnotifyShipmentDraftCompletedProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyShipmentRemarksUpdatedProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyShipmentRemarksUpdatedProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyShipmentRemarksUpdatedProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyShipmentRemarksUpdatedProjection(e) {
    return this.rerunProjectionnotifyShipmentRemarksUpdatedProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionnotifyShipmentUserAssignedProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionnotifyShipmentUserAssignedProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionnotifyShipmentUserAssignedProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionnotifyShipmentUserAssignedProjection(e) {
    return this.rerunProjectionnotifyShipmentUserAssignedProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectContinuationExternalInvoiceSyncWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectContinuationExternalInvoiceSync(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectContinuationExternalInvoiceSyncWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectContinuationExternalInvoiceSync(e) {
    return this.rerunProjectionprojectContinuationExternalInvoiceSyncWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectExternalSyncSegmentToInvoicesWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectExternalSyncSegmentToInvoices(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectExternalSyncSegmentToInvoicesWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectExternalSyncSegmentToInvoices(e) {
    return this.rerunProjectionprojectExternalSyncSegmentToInvoicesWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionApplicationRoleAssignedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionApplicationRoleAssignedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionApplicationRoleAssignedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionApplicationRoleAssignedToDenormalized(e) {
    return this.rerunProjectionprojectionApplicationRoleAssignedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionApplicationRoleRevokedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionApplicationRoleRevokedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionApplicationRoleRevokedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionApplicationRoleRevokedToDenormalized(e) {
    return this.rerunProjectionprojectionApplicationRoleRevokedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantCreatedToTenantUsersWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantCreatedToTenantUsers(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantCreatedToTenantUsersWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantCreatedToTenantUsers(e) {
    return this.rerunProjectionprojectionTenantCreatedToTenantUsersWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantNameSetFeedbackToUserSecurityWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantNameSetFeedbackToUserSecurity(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantNameSetFeedbackToUserSecurityWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantNameSetFeedbackToUserSecurity(e) {
    return this.rerunProjectionprojectionTenantNameSetFeedbackToUserSecurityWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantNameUpdatedToTenantUsersWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantNameUpdatedToTenantUsers(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantNameUpdatedToTenantUsersWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantNameUpdatedToTenantUsers(e) {
    return this.rerunProjectionprojectionTenantNameUpdatedToTenantUsersWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleAssignedToAddedToTenantWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleAssignedToAddedToTenant(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleAssignedToAddedToTenantWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleAssignedToAddedToTenant(e) {
    return this.rerunProjectionprojectionTenantRoleAssignedToAddedToTenantWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleAssignedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleAssignedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleAssignedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleAssignedToDenormalized(e) {
    return this.rerunProjectionprojectionTenantRoleAssignedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleAssignedToSendNotificationWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleAssignedToSendNotification(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleAssignedToSendNotificationWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleAssignedToSendNotification(e) {
    return this.rerunProjectionprojectionTenantRoleAssignedToSendNotificationWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleEmailReceivedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleEmailReceivedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleEmailReceivedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleEmailReceivedToDenormalized(e) {
    return this.rerunProjectionprojectionTenantRoleEmailReceivedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleNameReceivedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleNameReceivedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleNameReceivedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleNameReceivedToDenormalized(e) {
    return this.rerunProjectionprojectionTenantRoleNameReceivedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleRevokedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleRevokedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleRevokedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleRevokedToDenormalized(e) {
    return this.rerunProjectionprojectionTenantRoleRevokedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionTenantRoleRevokedToSendNotificationWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionTenantRoleRevokedToSendNotification(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionTenantRoleRevokedToSendNotificationWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionTenantRoleRevokedToSendNotification(e) {
    return this.rerunProjectionprojectionTenantRoleRevokedToSendNotificationWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurityWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurity(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurityWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurity(e) {
    return this.rerunProjectionprojectionUserAddedToTenantFeedbackToUserSecurityWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionUserAddedToTenantToTenantUsersWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionUserAddedToTenantToTenantUsers(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionUserAddedToTenantToTenantUsersWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionUserAddedToTenantToTenantUsers(e) {
    return this.rerunProjectionprojectionUserAddedToTenantToTenantUsersWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionUserEmailReceivedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionUserEmailReceivedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionUserEmailReceivedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionUserEmailReceivedToDenormalized(e) {
    return this.rerunProjectionprojectionUserEmailReceivedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionUserNameReceivedToDenormalizedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionUserNameReceivedToDenormalized(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionUserNameReceivedToDenormalizedWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionUserNameReceivedToDenormalized(e) {
    return this.rerunProjectionprojectionUserNameReceivedToDenormalizedWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionprojectionUserRemovedFromTenantToTenantUsersWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionprojectionUserRemovedFromTenantToTenantUsers(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionprojectionUserRemovedFromTenantToTenantUsersWithHttpInfo(e)));
    }));
  }
  rerunProjectionprojectionUserRemovedFromTenantToTenantUsers(e) {
    return this.rerunProjectionprojectionUserRemovedFromTenantToTenantUsersWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionroadShipmentConfirmationProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionroadShipmentConfirmationProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionroadShipmentConfirmationProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionroadShipmentConfirmationProjection(e) {
    return this.rerunProjectionroadShipmentConfirmationProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionroadShipmentSubscriptionProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionroadShipmentSubscriptionProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionroadShipmentSubscriptionProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionroadShipmentSubscriptionProjection(e) {
    return this.rerunProjectionroadShipmentSubscriptionProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionroadShipmentTenantIdProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionroadShipmentTenantIdProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionroadShipmentTenantIdProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionroadShipmentTenantIdProjection(e) {
    return this.rerunProjectionroadShipmentTenantIdProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjection(e) {
    return this.rerunProjectionshipmentPriorityEnteredNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjection(e) {
    return this.rerunProjectionshipmentPriorityUpdatedNotificationToAllShipmentPriorityAdminsProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectiontenantRoleAssignedToMessageCenterProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectiontenantRoleAssignedToMessageCenterProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectiontenantRoleAssignedToMessageCenterProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectiontenantRoleAssignedToMessageCenterProjection(e) {
    return this.rerunProjectiontenantRoleAssignedToMessageCenterProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectiontenantRoleRevokedToMessageCenterProjectionWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectiontenantRoleRevokedToMessageCenterProjection(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectiontenantRoleRevokedToMessageCenterProjectionWithHttpInfo(e)));
    }));
  }
  rerunProjectiontenantRoleRevokedToMessageCenterProjection(e) {
    return this.rerunProjectiontenantRoleRevokedToMessageCenterProjectionWithHttpInfo(e).pipe(map(e => e.data));
  }
  rerunProjectionuserOrganizationEmailFoundReceivedWithHttpInfo(e) {
    return from(this.requestFactory.rerunProjectionuserOrganizationEmailFoundReceived(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.rerunProjectionuserOrganizationEmailFoundReceivedWithHttpInfo(e)));
    }));
  }
  rerunProjectionuserOrganizationEmailFoundReceived(e) {
    return this.rerunProjectionuserOrganizationEmailFoundReceivedWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetActiveRoadShipmentDraftWithHttpInfo(e) {
    return from(this.requestFactory.resetActiveRoadShipmentDraft(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetActiveRoadShipmentDraftWithHttpInfo(e)));
    }));
  }
  resetActiveRoadShipmentDraft(e) {
    return this.resetActiveRoadShipmentDraftWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetAdditionalRolesReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetAdditionalRolesReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetAdditionalRolesReadModelWithHttpInfo(e)));
    }));
  }
  resetAdditionalRolesReadModel(e) {
    return this.resetAdditionalRolesReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetAdminAddressReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetAdminAddressReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetAdminAddressReadModelWithHttpInfo(e)));
    }));
  }
  resetAdminAddressReadModel(e) {
    return this.resetAdminAddressReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetAdminOrganizationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetAdminOrganizationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetAdminOrganizationReadModelWithHttpInfo(e)));
    }));
  }
  resetAdminOrganizationReadModel(e) {
    return this.resetAdminOrganizationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetCarrierContractReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetCarrierContractReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetCarrierContractReadModelWithHttpInfo(e)));
    }));
  }
  resetCarrierContractReadModel(e) {
    return this.resetCarrierContractReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetCarrierOrganizationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetCarrierOrganizationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetCarrierOrganizationReadModelWithHttpInfo(e)));
    }));
  }
  resetCarrierOrganizationReadModel(e) {
    return this.resetCarrierOrganizationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetChatChannelReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetChatChannelReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetChatChannelReadModelWithHttpInfo(e)));
    }));
  }
  resetChatChannelReadModel(e) {
    return this.resetChatChannelReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetContactPersonReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetContactPersonReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetContactPersonReadModelWithHttpInfo(e)));
    }));
  }
  resetContactPersonReadModel(e) {
    return this.resetContactPersonReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetContactPersonWithAssignedUserReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetContactPersonWithAssignedUserReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetContactPersonWithAssignedUserReadModelWithHttpInfo(e)));
    }));
  }
  resetContactPersonWithAssignedUserReadModel(e) {
    return this.resetContactPersonWithAssignedUserReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetContainerJourneyReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetContainerJourneyReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetContainerJourneyReadModelWithHttpInfo(e)));
    }));
  }
  resetContainerJourneyReadModel(e) {
    return this.resetContainerJourneyReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetCustomerReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetCustomerReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetCustomerReadModelWithHttpInfo(e)));
    }));
  }
  resetCustomerReadModel(e) {
    return this.resetCustomerReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetCustomersReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetCustomersReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetCustomersReadModelWithHttpInfo(e)));
    }));
  }
  resetCustomersReadModel(e) {
    return this.resetCustomersReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetExternalInvoiceReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetExternalInvoiceReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetExternalInvoiceReadModelWithHttpInfo(e)));
    }));
  }
  resetExternalInvoiceReadModel(e) {
    return this.resetExternalInvoiceReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetFailedNvxUpdateReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetFailedNvxUpdateReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetFailedNvxUpdateReadModelWithHttpInfo(e)));
    }));
  }
  resetFailedNvxUpdateReadModel(e) {
    return this.resetFailedNvxUpdateReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetLocationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetLocationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetLocationReadModelWithHttpInfo(e)));
    }));
  }
  resetLocationReadModel(e) {
    return this.resetLocationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetMilestoneReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetMilestoneReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetMilestoneReadModelWithHttpInfo(e)));
    }));
  }
  resetMilestoneReadModel(e) {
    return this.resetMilestoneReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetMyUserProfileReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetMyUserProfileReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetMyUserProfileReadModelWithHttpInfo(e)));
    }));
  }
  resetMyUserProfileReadModel(e) {
    return this.resetMyUserProfileReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetNvxReceivedProcessedReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetNvxReceivedProcessedReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetNvxReceivedProcessedReadModelWithHttpInfo(e)));
    }));
  }
  resetNvxReceivedProcessedReadModel(e) {
    return this.resetNvxReceivedProcessedReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetOrganizationDirectoryReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetOrganizationDirectoryReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetOrganizationDirectoryReadModelWithHttpInfo(e)));
    }));
  }
  resetOrganizationDirectoryReadModel(e) {
    return this.resetOrganizationDirectoryReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetOrganizationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetOrganizationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetOrganizationReadModelWithHttpInfo(e)));
    }));
  }
  resetOrganizationReadModel(e) {
    return this.resetOrganizationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetPublicAddressReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetPublicAddressReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetPublicAddressReadModelWithHttpInfo(e)));
    }));
  }
  resetPublicAddressReadModel(e) {
    return this.resetPublicAddressReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetPublicLocationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetPublicLocationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetPublicLocationReadModelWithHttpInfo(e)));
    }));
  }
  resetPublicLocationReadModel(e) {
    return this.resetPublicLocationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetPublicUserProfileReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetPublicUserProfileReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetPublicUserProfileReadModelWithHttpInfo(e)));
    }));
  }
  resetPublicUserProfileReadModel(e) {
    return this.resetPublicUserProfileReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetRoadShipmentReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetRoadShipmentReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetRoadShipmentReadModelWithHttpInfo(e)));
    }));
  }
  resetRoadShipmentReadModel(e) {
    return this.resetRoadShipmentReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetScheduleReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetScheduleReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetScheduleReadModelWithHttpInfo(e)));
    }));
  }
  resetScheduleReadModel(e) {
    return this.resetScheduleReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetSeaShipmentReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetSeaShipmentReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetSeaShipmentReadModelWithHttpInfo(e)));
    }));
  }
  resetSeaShipmentReadModel(e) {
    return this.resetSeaShipmentReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetShipmentDirectoryReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetShipmentDirectoryReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetShipmentDirectoryReadModelWithHttpInfo(e)));
    }));
  }
  resetShipmentDirectoryReadModel(e) {
    return this.resetShipmentDirectoryReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetShipmentOverviewReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetShipmentOverviewReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetShipmentOverviewReadModelWithHttpInfo(e)));
    }));
  }
  resetShipmentOverviewReadModel(e) {
    return this.resetShipmentOverviewReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetShipmentPriorityReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetShipmentPriorityReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetShipmentPriorityReadModelWithHttpInfo(e)));
    }));
  }
  resetShipmentPriorityReadModel(e) {
    return this.resetShipmentPriorityReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetShipmentReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetShipmentReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetShipmentReadModelWithHttpInfo(e)));
    }));
  }
  resetShipmentReadModel(e) {
    return this.resetShipmentReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetSystemGeneratedCarrierOrganizationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetSystemGeneratedCarrierOrganizationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetSystemGeneratedCarrierOrganizationReadModelWithHttpInfo(e)));
    }));
  }
  resetSystemGeneratedCarrierOrganizationReadModel(e) {
    return this.resetSystemGeneratedCarrierOrganizationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetTenantReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetTenantReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetTenantReadModelWithHttpInfo(e)));
    }));
  }
  resetTenantReadModel(e) {
    return this.resetTenantReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetTenantUserReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetTenantUserReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetTenantUserReadModelWithHttpInfo(e)));
    }));
  }
  resetTenantUserReadModel(e) {
    return this.resetTenantUserReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetTenantWithCustomersReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetTenantWithCustomersReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetTenantWithCustomersReadModelWithHttpInfo(e)));
    }));
  }
  resetTenantWithCustomersReadModel(e) {
    return this.resetTenantWithCustomersReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetTodoEventModelReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetTodoEventModelReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetTodoEventModelReadModelWithHttpInfo(e)));
    }));
  }
  resetTodoEventModelReadModel(e) {
    return this.resetTodoEventModelReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetTransshipmentReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetTransshipmentReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetTransshipmentReadModelWithHttpInfo(e)));
    }));
  }
  resetTransshipmentReadModel(e) {
    return this.resetTransshipmentReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserBoundAddressReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserBoundAddressReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserBoundAddressReadModelWithHttpInfo(e)));
    }));
  }
  resetUserBoundAddressReadModel(e) {
    return this.resetUserBoundAddressReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserNotificationDeletedReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserNotificationDeletedReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserNotificationDeletedReadModelWithHttpInfo(e)));
    }));
  }
  resetUserNotificationDeletedReadModel(e) {
    return this.resetUserNotificationDeletedReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserNotificationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserNotificationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserNotificationReadModelWithHttpInfo(e)));
    }));
  }
  resetUserNotificationReadModel(e) {
    return this.resetUserNotificationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserNotificationSettingsReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserNotificationSettingsReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserNotificationSettingsReadModelWithHttpInfo(e)));
    }));
  }
  resetUserNotificationSettingsReadModel(e) {
    return this.resetUserNotificationSettingsReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserOrganizationReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserOrganizationReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserOrganizationReadModelWithHttpInfo(e)));
    }));
  }
  resetUserOrganizationReadModel(e) {
    return this.resetUserOrganizationReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserProfileReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserProfileReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserProfileReadModelWithHttpInfo(e)));
    }));
  }
  resetUserProfileReadModel(e) {
    return this.resetUserProfileReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserSecurityReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserSecurityReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserSecurityReadModelWithHttpInfo(e)));
    }));
  }
  resetUserSecurityReadModel(e) {
    return this.resetUserSecurityReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserTableSortReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserTableSortReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserTableSortReadModelWithHttpInfo(e)));
    }));
  }
  resetUserTableSortReadModel(e) {
    return this.resetUserTableSortReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserValidAddressReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserValidAddressReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserValidAddressReadModelWithHttpInfo(e)));
    }));
  }
  resetUserValidAddressReadModel(e) {
    return this.resetUserValidAddressReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserWithRoleReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserWithRoleReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserWithRoleReadModelWithHttpInfo(e)));
    }));
  }
  resetUserWithRoleReadModel(e) {
    return this.resetUserWithRoleReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetUserWithTenantRoleReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetUserWithTenantRoleReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetUserWithTenantRoleReadModelWithHttpInfo(e)));
    }));
  }
  resetUserWithTenantRoleReadModel(e) {
    return this.resetUserWithTenantRoleReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  resetValidationRuleReadModelWithHttpInfo(e) {
    return from(this.requestFactory.resetValidationRuleReadModel(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.resetValidationRuleReadModelWithHttpInfo(e)));
    }));
  }
  resetValidationRuleReadModel(e) {
    return this.resetValidationRuleReadModelWithHttpInfo(e).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { InvoicingApiRequestFactory, InvoicingApiResponseProcessor } from "../apis/InvoicingApi";
export class ObservableInvoicingApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new InvoicingApiRequestFactory(e), this.responseProcessor = r || new InvoicingApiResponseProcessor();
  }
  addCustomerToTenantWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addCustomerToTenant(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addCustomerToTenantWithHttpInfo(e)));
    }));
  }
  addCustomerToTenant(e, t, r, i) {
    return this.addCustomerToTenantWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  getCustomerWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getCustomer(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getCustomerWithHttpInfo(e)));
    }));
  }
  getCustomer(e, t, r) {
    return this.getCustomerWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getCustomersWithHttpInfo(e, t) {
    return from(this.requestFactory.getCustomers(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getCustomersWithHttpInfo(e)));
    }));
  }
  getCustomers(e, t) {
    return this.getCustomersWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getExternalInvoiceWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getExternalInvoice(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getExternalInvoiceWithHttpInfo(e)));
    }));
  }
  getExternalInvoice(e, t, r) {
    return this.getExternalInvoiceWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getTenantWithCustomersWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getTenantWithCustomers(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getTenantWithCustomersWithHttpInfo(e)));
    }));
  }
  getTenantWithCustomers(e, t, r) {
    return this.getTenantWithCustomersWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  initiateCustomerFullSyncWithHttpInfo(e, t, r) {
    return from(this.requestFactory.initiateCustomerFullSync(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.initiateCustomerFullSyncWithHttpInfo(e)));
    }));
  }
  initiateCustomerFullSync(e, t, r) {
    return this.initiateCustomerFullSyncWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  listCustomerWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f) {
    return from(this.requestFactory.listCustomer(e, t, r, i, o, p, n, s, a, h, d, f)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listCustomerWithHttpInfo(e)));
    }));
  }
  listCustomer(e, t, r, i, o, p, n, s, a, h, d, f) {
    return this.listCustomerWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f).pipe(map(e => e.data));
  }
  listCustomersWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m) {
    return from(this.requestFactory.listCustomers(e, t, r, i, o, p, n, s, a, h, d, f, m)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listCustomersWithHttpInfo(e)));
    }));
  }
  listCustomers(e, t, r, i, o, p, n, s, a, h, d, f, m) {
    return this.listCustomersWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m).pipe(map(e => e.data));
  }
  listExternalInvoiceWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me) {
    return from(this.requestFactory.listExternalInvoice(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listExternalInvoiceWithHttpInfo(e)));
    }));
  }
  listExternalInvoice(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me) {
    return this.listExternalInvoiceWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me).pipe(map(e => e.data));
  }
  listTenantWithCustomersWithHttpInfo(e, t, r, i, o, p, n, s, a, h) {
    return from(this.requestFactory.listTenantWithCustomers(e, t, r, i, o, p, n, s, a, h)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listTenantWithCustomersWithHttpInfo(e)));
    }));
  }
  listTenantWithCustomers(e, t, r, i, o, p, n, s, a, h) {
    return this.listTenantWithCustomersWithHttpInfo(e, t, r, i, o, p, n, s, a, h).pipe(map(e => e.data));
  }
  registerExternalInvoiceWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.registerExternalInvoice(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.registerExternalInvoiceWithHttpInfo(e)));
    }));
  }
  registerExternalInvoice(e, t, r, i) {
    return this.registerExternalInvoiceWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeCustomerFromTenantWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeCustomerFromTenant(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeCustomerFromTenantWithHttpInfo(e)));
    }));
  }
  removeCustomerFromTenant(e, t, r, i) {
    return this.removeCustomerFromTenantWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { NotificationsApiRequestFactory, NotificationsApiResponseProcessor } from "../apis/NotificationsApi";
export class ObservableNotificationsApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new NotificationsApiRequestFactory(e), this.responseProcessor = r || new NotificationsApiResponseProcessor();
  }
  getUserNotificationWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserNotification(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserNotificationWithHttpInfo(e)));
    }));
  }
  getUserNotification(e, t) {
    return this.getUserNotificationWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserNotificationDeletedWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserNotificationDeleted(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserNotificationDeletedWithHttpInfo(e)));
    }));
  }
  getUserNotificationDeleted(e, t) {
    return this.getUserNotificationDeletedWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  listUserNotificationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return from(this.requestFactory.listUserNotification(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserNotificationWithHttpInfo(e)));
    }));
  }
  listUserNotification(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return this.listUserNotificationWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L).pipe(map(e => e.data));
  }
  listUserNotificationDeletedWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return from(this.requestFactory.listUserNotificationDeleted(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserNotificationDeletedWithHttpInfo(e)));
    }));
  }
  listUserNotificationDeleted(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L) {
    return this.listUserNotificationDeletedWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L).pipe(map(e => e.data));
  }
  notificationArchiveWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationArchive(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationArchiveWithHttpInfo(e)));
    }));
  }
  notificationArchive(e, t, r) {
    return this.notificationArchiveWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationDeleteWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationDelete(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationDeleteWithHttpInfo(e)));
    }));
  }
  notificationDelete(e, t, r) {
    return this.notificationDeleteWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationMarkAsFavoriteWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationMarkAsFavorite(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationMarkAsFavoriteWithHttpInfo(e)));
    }));
  }
  notificationMarkAsFavorite(e, t, r) {
    return this.notificationMarkAsFavoriteWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationMarkAsReadWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationMarkAsRead(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationMarkAsReadWithHttpInfo(e)));
    }));
  }
  notificationMarkAsRead(e, t, r) {
    return this.notificationMarkAsReadWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationMarkAsUnfavoriteWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationMarkAsUnfavorite(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationMarkAsUnfavoriteWithHttpInfo(e)));
    }));
  }
  notificationMarkAsUnfavorite(e, t, r) {
    return this.notificationMarkAsUnfavoriteWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationMarkAsUnreadWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationMarkAsUnread(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationMarkAsUnreadWithHttpInfo(e)));
    }));
  }
  notificationMarkAsUnread(e, t, r) {
    return this.notificationMarkAsUnreadWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  notificationRestoreWithHttpInfo(e, t, r) {
    return from(this.requestFactory.notificationRestore(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.notificationRestoreWithHttpInfo(e)));
    }));
  }
  notificationRestore(e, t, r) {
    return this.notificationRestoreWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { ShipmentApiRequestFactory, ShipmentApiResponseProcessor } from "../apis/ShipmentApi";
export class ObservableShipmentApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new ShipmentApiRequestFactory(e), this.responseProcessor = r || new ShipmentApiResponseProcessor();
  }
  addCustomerReferenceToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.addCustomerReferenceToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.addCustomerReferenceToShipmentWithHttpInfo(e)));
    }));
  }
  addCustomerReferenceToShipment(e, t, r, i) {
    return this.addCustomerReferenceToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignContactPersonToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignContactPersonToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignContactPersonToShipmentWithHttpInfo(e)));
    }));
  }
  assignContactPersonToShipment(e, t, r, i) {
    return this.assignContactPersonToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignDocumentToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignDocumentToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignDocumentToShipmentWithHttpInfo(e)));
    }));
  }
  assignDocumentToShipment(e, t, r, i) {
    return this.assignDocumentToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignLoadingLocationToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignLoadingLocationToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignLoadingLocationToShipmentWithHttpInfo(e)));
    }));
  }
  assignLoadingLocationToShipment(e, t, r, i) {
    return this.assignLoadingLocationToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignUnloadingLocationToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignUnloadingLocationToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignUnloadingLocationToShipmentWithHttpInfo(e)));
    }));
  }
  assignUnloadingLocationToShipment(e, t, r, i) {
    return this.assignUnloadingLocationToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  assignUserToShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.assignUserToShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.assignUserToShipmentWithHttpInfo(e)));
    }));
  }
  assignUserToShipment(e, t, r, i) {
    return this.assignUserToShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  changeScheduleStatusWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.changeScheduleStatus(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.changeScheduleStatusWithHttpInfo(e)));
    }));
  }
  changeScheduleStatus(e, t, r, i) {
    return this.changeScheduleStatusWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  completeRoadShipmentDraftWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.completeRoadShipmentDraft(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.completeRoadShipmentDraftWithHttpInfo(e)));
    }));
  }
  completeRoadShipmentDraft(e, t, r, i) {
    return this.completeRoadShipmentDraftWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  completeShipmentDraftWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.completeShipmentDraft(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.completeShipmentDraftWithHttpInfo(e)));
    }));
  }
  completeShipmentDraft(e, t, r, i) {
    return this.completeShipmentDraftWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createCarrierContractWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createCarrierContract(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createCarrierContractWithHttpInfo(e)));
    }));
  }
  createCarrierContract(e, t, r, i) {
    return this.createCarrierContractWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createMilestoneWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createMilestone(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createMilestoneWithHttpInfo(e)));
    }));
  }
  createMilestone(e, t, r, i) {
    return this.createMilestoneWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createScheduleWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createSchedule(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createScheduleWithHttpInfo(e)));
    }));
  }
  createSchedule(e, t, r, i) {
    return this.createScheduleWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createShipmentWithHttpInfo(e)));
    }));
  }
  createShipment(e, t, r, i) {
    return this.createShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createShipmentDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createShipmentDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createShipmentDirectoryWithHttpInfo(e)));
    }));
  }
  createShipmentDirectory(e, t, r, i) {
    return this.createShipmentDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createShipmentGoodsWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createShipmentGoods(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createShipmentGoodsWithHttpInfo(e)));
    }));
  }
  createShipmentGoods(e, t, r, i) {
    return this.createShipmentGoodsWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  createTransshipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.createTransshipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createTransshipmentWithHttpInfo(e)));
    }));
  }
  createTransshipment(e, t, r, i) {
    return this.createTransshipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteCarrierContractWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteCarrierContract(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteCarrierContractWithHttpInfo(e)));
    }));
  }
  deleteCarrierContract(e, t, r, i) {
    return this.deleteCarrierContractWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteMilestoneWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteMilestone(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteMilestoneWithHttpInfo(e)));
    }));
  }
  deleteMilestone(e, t, r, i) {
    return this.deleteMilestoneWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteScheduleWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteSchedule(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteScheduleWithHttpInfo(e)));
    }));
  }
  deleteSchedule(e, t, r, i) {
    return this.deleteScheduleWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteShipmentDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteShipmentDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteShipmentDirectoryWithHttpInfo(e)));
    }));
  }
  deleteShipmentDirectory(e, t, r, i) {
    return this.deleteShipmentDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteShipmentDocumentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteShipmentDocument(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteShipmentDocumentWithHttpInfo(e)));
    }));
  }
  deleteShipmentDocument(e, t, r, i) {
    return this.deleteShipmentDocumentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  deleteTransshipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.deleteTransshipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.deleteTransshipmentWithHttpInfo(e)));
    }));
  }
  deleteTransshipment(e, t, r, i) {
    return this.deleteTransshipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  enterShipmentPriorityWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.enterShipmentPriority(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.enterShipmentPriorityWithHttpInfo(e)));
    }));
  }
  enterShipmentPriority(e, t, r, i) {
    return this.enterShipmentPriorityWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  getActiveRoadShipmentDraftWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getActiveRoadShipmentDraft(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getActiveRoadShipmentDraftWithHttpInfo(e)));
    }));
  }
  getActiveRoadShipmentDraft(e, t, r) {
    return this.getActiveRoadShipmentDraftWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getCarrierContractWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getCarrierContract(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getCarrierContractWithHttpInfo(e)));
    }));
  }
  getCarrierContract(e, t, r) {
    return this.getCarrierContractWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getContainerJourneyWithHttpInfo(e, t) {
    return from(this.requestFactory.getContainerJourney(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getContainerJourneyWithHttpInfo(e)));
    }));
  }
  getContainerJourney(e, t) {
    return this.getContainerJourneyWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getFailedNvxUpdateWithHttpInfo(e, t) {
    return from(this.requestFactory.getFailedNvxUpdate(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getFailedNvxUpdateWithHttpInfo(e)));
    }));
  }
  getFailedNvxUpdate(e, t) {
    return this.getFailedNvxUpdateWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getMilestoneWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getMilestone(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getMilestoneWithHttpInfo(e)));
    }));
  }
  getMilestone(e, t, r) {
    return this.getMilestoneWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getNvxReceivedProcessedWithHttpInfo(e, t) {
    return from(this.requestFactory.getNvxReceivedProcessed(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getNvxReceivedProcessedWithHttpInfo(e)));
    }));
  }
  getNvxReceivedProcessed(e, t) {
    return this.getNvxReceivedProcessedWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getRoadShipmentWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getRoadShipment(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getRoadShipmentWithHttpInfo(e)));
    }));
  }
  getRoadShipment(e, t, r) {
    return this.getRoadShipmentWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getScheduleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getSchedule(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getScheduleWithHttpInfo(e)));
    }));
  }
  getSchedule(e, t, r) {
    return this.getScheduleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getSeaShipmentWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getSeaShipment(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getSeaShipmentWithHttpInfo(e)));
    }));
  }
  getSeaShipment(e, t, r) {
    return this.getSeaShipmentWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getShipmentWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getShipment(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getShipmentWithHttpInfo(e)));
    }));
  }
  getShipment(e, t, r) {
    return this.getShipmentWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getShipmentDirectoryWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getShipmentDirectory(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getShipmentDirectoryWithHttpInfo(e)));
    }));
  }
  getShipmentDirectory(e, t, r) {
    return this.getShipmentDirectoryWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getShipmentOverviewWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getShipmentOverview(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getShipmentOverviewWithHttpInfo(e)));
    }));
  }
  getShipmentOverview(e, t, r) {
    return this.getShipmentOverviewWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getShipmentPriorityWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getShipmentPriority(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getShipmentPriorityWithHttpInfo(e)));
    }));
  }
  getShipmentPriority(e, t, r) {
    return this.getShipmentPriorityWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getTransshipmentWithHttpInfo(e, t, r) {
    return from(this.requestFactory.getTransshipment(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getTransshipmentWithHttpInfo(e)));
    }));
  }
  getTransshipment(e, t, r) {
    return this.getTransshipmentWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  listActiveRoadShipmentDraftWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft) {
    return from(this.requestFactory.listActiveRoadShipmentDraft(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listActiveRoadShipmentDraftWithHttpInfo(e)));
    }));
  }
  listActiveRoadShipmentDraft(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft) {
    return this.listActiveRoadShipmentDraftWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft).pipe(map(e => e.data));
  }
  listCarrierContractWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X) {
    return from(this.requestFactory.listCarrierContract(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listCarrierContractWithHttpInfo(e)));
    }));
  }
  listCarrierContract(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X) {
    return this.listCarrierContractWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X).pipe(map(e => e.data));
  }
  listContainerJourneyWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H) {
    return from(this.requestFactory.listContainerJourney(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listContainerJourneyWithHttpInfo(e)));
    }));
  }
  listContainerJourney(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H) {
    return this.listContainerJourneyWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H).pipe(map(e => e.data));
  }
  listFailedNvxUpdateWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c) {
    return from(this.requestFactory.listFailedNvxUpdate(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listFailedNvxUpdateWithHttpInfo(e)));
    }));
  }
  listFailedNvxUpdate(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c) {
    return this.listFailedNvxUpdateWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c).pipe(map(e => e.data));
  }
  listMilestoneWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J) {
    return from(this.requestFactory.listMilestone(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listMilestoneWithHttpInfo(e)));
    }));
  }
  listMilestone(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J) {
    return this.listMilestoneWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J).pipe(map(e => e.data));
  }
  listNvxReceivedProcessedWithHttpInfo(e, t, r, i, o, p, n, s, a, h) {
    return from(this.requestFactory.listNvxReceivedProcessed(e, t, r, i, o, p, n, s, a, h)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listNvxReceivedProcessedWithHttpInfo(e)));
    }));
  }
  listNvxReceivedProcessed(e, t, r, i, o, p, n, s, a, h) {
    return this.listNvxReceivedProcessedWithHttpInfo(e, t, r, i, o, p, n, s, a, h).pipe(map(e => e.data));
  }
  listRoadShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je) {
    return from(this.requestFactory.listRoadShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listRoadShipmentWithHttpInfo(e)));
    }));
  }
  listRoadShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je) {
    return this.listRoadShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je).pipe(map(e => e.data));
  }
  listScheduleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt) {
    return from(this.requestFactory.listSchedule(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listScheduleWithHttpInfo(e)));
    }));
  }
  listSchedule(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt) {
    return this.listScheduleWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt).pipe(map(e => e.data));
  }
  listSeaShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be) {
    return from(this.requestFactory.listSeaShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listSeaShipmentWithHttpInfo(e)));
    }));
  }
  listSeaShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be) {
    return this.listSeaShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be).pipe(map(e => e.data));
  }
  listShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft, jt, Ct, Ut, vt, qt, Dt, Ot, zt, Nt, bt, kt, Lt, xt, Et, Gt, Vt, Bt, Jt, Kt, Qt, Xt, Yt, Zt, $t, _t, er, tr, rr, ir, or, pr, nr, sr, ar, hr, dr, fr, mr, lr, cr, ur, gr, Mr, Pr, Ir, Wr, yr, Hr, Ar, wr, Rr, Sr, Tr, Fr, jr, Cr) {
    return from(this.requestFactory.listShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft, jt, Ct, Ut, vt, qt, Dt, Ot, zt, Nt, bt, kt, Lt, xt, Et, Gt, Vt, Bt, Jt, Kt, Qt, Xt, Yt, Zt, $t, _t, er, tr, rr, ir, or, pr, nr, sr, ar, hr, dr, fr, mr, lr, cr, ur, gr, Mr, Pr, Ir, Wr, yr, Hr, Ar, wr, Rr, Sr, Tr, Fr, jr, Cr)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listShipmentWithHttpInfo(e)));
    }));
  }
  listShipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft, jt, Ct, Ut, vt, qt, Dt, Ot, zt, Nt, bt, kt, Lt, xt, Et, Gt, Vt, Bt, Jt, Kt, Qt, Xt, Yt, Zt, $t, _t, er, tr, rr, ir, or, pr, nr, sr, ar, hr, dr, fr, mr, lr, cr, ur, gr, Mr, Pr, Ir, Wr, yr, Hr, Ar, wr, Rr, Sr, Tr, Fr, jr, Cr) {
    return this.listShipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we, Re, Se, Te, Fe, je, Ce, Ue, ve, qe, De, Oe, ze, Ne, be, ke, Le, xe, Ee, Ge, Ve, Be, Je, Ke, Qe, Xe, Ye, Ze, $e, _e, et, tt, rt, it, ot, pt, nt, st, at, ht, dt, ft, mt, lt, ct, ut, gt, Mt, Pt, It, Wt, yt, Ht, At, wt, Rt, St, Tt, Ft, jt, Ct, Ut, vt, qt, Dt, Ot, zt, Nt, bt, kt, Lt, xt, Et, Gt, Vt, Bt, Jt, Kt, Qt, Xt, Yt, Zt, $t, _t, er, tr, rr, ir, or, pr, nr, sr, ar, hr, dr, fr, mr, lr, cr, ur, gr, Mr, Pr, Ir, Wr, yr, Hr, Ar, wr, Rr, Sr, Tr, Fr, jr, Cr).pipe(map(e => e.data));
  }
  listShipmentDirectoryWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f) {
    return from(this.requestFactory.listShipmentDirectory(e, t, r, i, o, p, n, s, a, h, d, f)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listShipmentDirectoryWithHttpInfo(e)));
    }));
  }
  listShipmentDirectory(e, t, r, i, o, p, n, s, a, h, d, f) {
    return this.listShipmentDirectoryWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f).pipe(map(e => e.data));
  }
  listShipmentOverviewWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue) {
    return from(this.requestFactory.listShipmentOverview(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listShipmentOverviewWithHttpInfo(e)));
    }));
  }
  listShipmentOverview(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue) {
    return this.listShipmentOverviewWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue).pipe(map(e => e.data));
  }
  listShipmentPriorityWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S) {
    return from(this.requestFactory.listShipmentPriority(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listShipmentPriorityWithHttpInfo(e)));
    }));
  }
  listShipmentPriority(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S) {
    return this.listShipmentPriorityWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S).pipe(map(e => e.data));
  }
  listTransshipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X) {
    return from(this.requestFactory.listTransshipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listTransshipmentWithHttpInfo(e)));
    }));
  }
  listTransshipment(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X) {
    return this.listTransshipmentWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X).pipe(map(e => e.data));
  }
  registerContainerJourneyWithHttpInfo(e, t, r) {
    return from(this.requestFactory.registerContainerJourney(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.registerContainerJourneyWithHttpInfo(e)));
    }));
  }
  registerContainerJourney(e, t, r) {
    return this.registerContainerJourneyWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  removeContactPersonFromShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeContactPersonFromShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeContactPersonFromShipmentWithHttpInfo(e)));
    }));
  }
  removeContactPersonFromShipment(e, t, r, i) {
    return this.removeContactPersonFromShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeCustomerReferenceFromShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeCustomerReferenceFromShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeCustomerReferenceFromShipmentWithHttpInfo(e)));
    }));
  }
  removeCustomerReferenceFromShipment(e, t, r, i) {
    return this.removeCustomerReferenceFromShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeGoodsFromShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeGoodsFromShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeGoodsFromShipmentWithHttpInfo(e)));
    }));
  }
  removeGoodsFromShipment(e, t, r, i) {
    return this.removeGoodsFromShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeShipmentLoadingLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeShipmentLoadingLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeShipmentLoadingLocationWithHttpInfo(e)));
    }));
  }
  removeShipmentLoadingLocation(e, t, r, i) {
    return this.removeShipmentLoadingLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeShipmentUnloadingLocationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeShipmentUnloadingLocation(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeShipmentUnloadingLocationWithHttpInfo(e)));
    }));
  }
  removeShipmentUnloadingLocation(e, t, r, i) {
    return this.removeShipmentUnloadingLocationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  removeUserFromShipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.removeUserFromShipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeUserFromShipmentWithHttpInfo(e)));
    }));
  }
  removeUserFromShipment(e, t, r, i) {
    return this.removeUserFromShipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreCarrierContractWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreCarrierContract(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreCarrierContractWithHttpInfo(e)));
    }));
  }
  restoreCarrierContract(e, t, r, i) {
    return this.restoreCarrierContractWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  restoreShipmentDirectoryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.restoreShipmentDirectory(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.restoreShipmentDirectoryWithHttpInfo(e)));
    }));
  }
  restoreShipmentDirectory(e, t, r, i) {
    return this.restoreShipmentDirectoryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  roadShipmentDraftSubscribeWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.roadShipmentDraftSubscribe(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.roadShipmentDraftSubscribeWithHttpInfo(e)));
    }));
  }
  roadShipmentDraftSubscribe(e, t, r, i) {
    return this.roadShipmentDraftSubscribeWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  roadShipmentSubscribeWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.roadShipmentSubscribe(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.roadShipmentSubscribeWithHttpInfo(e)));
    }));
  }
  roadShipmentSubscribe(e, t, r, i) {
    return this.roadShipmentSubscribeWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  saveRoadShipmentDraftWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.saveRoadShipmentDraft(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.saveRoadShipmentDraftWithHttpInfo(e)));
    }));
  }
  saveRoadShipmentDraft(e, t, r, i) {
    return this.saveRoadShipmentDraftWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentCargoReadyDateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentCargoReadyDate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentCargoReadyDateWithHttpInfo(e)));
    }));
  }
  setShipmentCargoReadyDate(e, t, r, i) {
    return this.setShipmentCargoReadyDateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentDestinationWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentDestination(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentDestinationWithHttpInfo(e)));
    }));
  }
  setShipmentDestination(e, t, r, i) {
    return this.setShipmentDestinationWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentImportanceWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentImportance(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentImportanceWithHttpInfo(e)));
    }));
  }
  setShipmentImportance(e, t, r, i) {
    return this.setShipmentImportanceWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentOriginWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentOrigin(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentOriginWithHttpInfo(e)));
    }));
  }
  setShipmentOrigin(e, t, r, i) {
    return this.setShipmentOriginWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentOriginEtaWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentOriginEta(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentOriginEtaWithHttpInfo(e)));
    }));
  }
  setShipmentOriginEta(e, t, r, i) {
    return this.setShipmentOriginEtaWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentPlaceOfDeliveryWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentPlaceOfDelivery(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentPlaceOfDeliveryWithHttpInfo(e)));
    }));
  }
  setShipmentPlaceOfDelivery(e, t, r, i) {
    return this.setShipmentPlaceOfDeliveryWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentPlaceOfReceiptWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentPlaceOfReceipt(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentPlaceOfReceiptWithHttpInfo(e)));
    }));
  }
  setShipmentPlaceOfReceipt(e, t, r, i) {
    return this.setShipmentPlaceOfReceiptWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentRemarksWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentRemarks(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentRemarksWithHttpInfo(e)));
    }));
  }
  setShipmentRemarks(e, t, r, i) {
    return this.setShipmentRemarksWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentStackabilityWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentStackability(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentStackabilityWithHttpInfo(e)));
    }));
  }
  setShipmentStackability(e, t, r, i) {
    return this.setShipmentStackabilityWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  setShipmentStatusWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.setShipmentStatus(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setShipmentStatusWithHttpInfo(e)));
    }));
  }
  setShipmentStatus(e, t, r, i) {
    return this.setShipmentStatusWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateCarrierContractWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateCarrierContract(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateCarrierContractWithHttpInfo(e)));
    }));
  }
  updateCarrierContract(e, t, r, i) {
    return this.updateCarrierContractWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateContainerRateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateContainerRate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateContainerRateWithHttpInfo(e)));
    }));
  }
  updateContainerRate(e, t, r, i) {
    return this.updateContainerRateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateMilestoneWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateMilestone(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateMilestoneWithHttpInfo(e)));
    }));
  }
  updateMilestone(e, t, r, i) {
    return this.updateMilestoneWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateMilestoneDueDateWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateMilestoneDueDate(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateMilestoneDueDateWithHttpInfo(e)));
    }));
  }
  updateMilestoneDueDate(e, t, r, i) {
    return this.updateMilestoneDueDateWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateMilestoneStatusWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateMilestoneStatus(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateMilestoneStatusWithHttpInfo(e)));
    }));
  }
  updateMilestoneStatus(e, t, r, i) {
    return this.updateMilestoneStatusWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateScheduleWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateSchedule(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateScheduleWithHttpInfo(e)));
    }));
  }
  updateSchedule(e, t, r, i) {
    return this.updateScheduleWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateShipmentDetailsWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateShipmentDetails(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateShipmentDetailsWithHttpInfo(e)));
    }));
  }
  updateShipmentDetails(e, t, r, i) {
    return this.updateShipmentDetailsWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateShipmentGoodsWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateShipmentGoods(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateShipmentGoodsWithHttpInfo(e)));
    }));
  }
  updateShipmentGoods(e, t, r, i) {
    return this.updateShipmentGoodsWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateShipmentPriorityWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateShipmentPriority(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateShipmentPriorityWithHttpInfo(e)));
    }));
  }
  updateShipmentPriority(e, t, r, i) {
    return this.updateShipmentPriorityWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  updateTransshipmentWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.updateTransshipment(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateTransshipmentWithHttpInfo(e)));
    }));
  }
  updateTransshipment(e, t, r, i) {
    return this.updateTransshipmentWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { TenancyApiRequestFactory, TenancyApiResponseProcessor } from "../apis/TenancyApi";
export class ObservableTenancyApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new TenancyApiRequestFactory(e), this.responseProcessor = r || new TenancyApiResponseProcessor();
  }
  enterInviteUserWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.enterInviteUser(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.enterInviteUserWithHttpInfo(e)));
    }));
  }
  enterInviteUser(e, t, r, i) {
    return this.enterInviteUserWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  receiveNewSignupWithHttpInfo(e, t, r, i) {
    return from(this.requestFactory.receiveNewSignup(e, t, r, i)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.receiveNewSignupWithHttpInfo(e)));
    }));
  }
  receiveNewSignup(e, t, r, i) {
    return this.receiveNewSignupWithHttpInfo(e, t, r, i).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { TenancyManagementApiRequestFactory, TenancyManagementApiResponseProcessor } from "../apis/TenancyManagementApi";
export class ObservableTenancyManagementApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new TenancyManagementApiRequestFactory(e), this.responseProcessor = r || new TenancyManagementApiResponseProcessor();
  }
  createTenantWithHttpInfo(e, t, r) {
    return from(this.requestFactory.createTenant(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.createTenantWithHttpInfo(e)));
    }));
  }
  createTenant(e, t, r) {
    return this.createTenantWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  disableTenantWithHttpInfo(e, t, r) {
    return from(this.requestFactory.disableTenant(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.disableTenantWithHttpInfo(e)));
    }));
  }
  disableTenant(e, t, r) {
    return this.disableTenantWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  enableTenantWithHttpInfo(e, t, r) {
    return from(this.requestFactory.enableTenant(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.enableTenantWithHttpInfo(e)));
    }));
  }
  enableTenant(e, t, r) {
    return this.enableTenantWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  getTenantWithHttpInfo(e, t) {
    return from(this.requestFactory.getTenant(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getTenantWithHttpInfo(e)));
    }));
  }
  getTenant(e, t) {
    return this.getTenantWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getTenantUserWithHttpInfo(e, t) {
    return from(this.requestFactory.getTenantUser(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getTenantUserWithHttpInfo(e)));
    }));
  }
  getTenantUser(e, t) {
    return this.getTenantUserWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  listTenantWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d) {
    return from(this.requestFactory.listTenant(e, t, r, i, o, p, n, s, a, h, d)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listTenantWithHttpInfo(e)));
    }));
  }
  listTenant(e, t, r, i, o, p, n, s, a, h, d) {
    return this.listTenantWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d).pipe(map(e => e.data));
  }
  listTenantUserWithHttpInfo(e, t, r, i, o, p, n, s, a, h) {
    return from(this.requestFactory.listTenantUser(e, t, r, i, o, p, n, s, a, h)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listTenantUserWithHttpInfo(e)));
    }));
  }
  listTenantUser(e, t, r, i, o, p, n, s, a, h) {
    return this.listTenantUserWithHttpInfo(e, t, r, i, o, p, n, s, a, h).pipe(map(e => e.data));
  }
  renameTenantWithHttpInfo(e, t, r) {
    return from(this.requestFactory.renameTenant(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.renameTenantWithHttpInfo(e)));
    }));
  }
  renameTenant(e, t, r) {
    return this.renameTenantWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { UserProfileApiRequestFactory, UserProfileApiResponseProcessor } from "../apis/UserProfileApi";
export class ObservableUserProfileApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new UserProfileApiRequestFactory(e), this.responseProcessor = r || new UserProfileApiResponseProcessor();
  }
  getMyUserProfileWithHttpInfo(e, t) {
    return from(this.requestFactory.getMyUserProfile(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getMyUserProfileWithHttpInfo(e)));
    }));
  }
  getMyUserProfile(e, t) {
    return this.getMyUserProfileWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getPublicUserProfileWithHttpInfo(e, t) {
    return from(this.requestFactory.getPublicUserProfile(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getPublicUserProfileWithHttpInfo(e)));
    }));
  }
  getPublicUserProfile(e, t) {
    return this.getPublicUserProfileWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getShipmentNotificationSettingsGroupsWithHttpInfo(e) {
    return from(this.requestFactory.getShipmentNotificationSettingsGroups(e)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getShipmentNotificationSettingsGroupsWithHttpInfo(e)));
    }));
  }
  getShipmentNotificationSettingsGroups(e) {
    return this.getShipmentNotificationSettingsGroupsWithHttpInfo(e).pipe(map(e => e.data));
  }
  getUserNotificationSettingsWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserNotificationSettings(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserNotificationSettingsWithHttpInfo(e)));
    }));
  }
  getUserNotificationSettings(e, t) {
    return this.getUserNotificationSettingsWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserProfileWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserProfile(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserProfileWithHttpInfo(e)));
    }));
  }
  getUserProfile(e, t) {
    return this.getUserProfileWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  getUserTableSortWithHttpInfo(e, t) {
    return from(this.requestFactory.getUserTableSort(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getUserTableSortWithHttpInfo(e)));
    }));
  }
  getUserTableSort(e, t) {
    return this.getUserTableSortWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  listMyUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return from(this.requestFactory.listMyUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listMyUserProfileWithHttpInfo(e)));
    }));
  }
  listMyUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return this.listMyUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we).pipe(map(e => e.data));
  }
  listPublicUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F) {
    return from(this.requestFactory.listPublicUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listPublicUserProfileWithHttpInfo(e)));
    }));
  }
  listPublicUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F) {
    return this.listPublicUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F).pipe(map(e => e.data));
  }
  listUserNotificationSettingsWithHttpInfo(e, t, r, i, o, p, n, s, a, h) {
    return from(this.requestFactory.listUserNotificationSettings(e, t, r, i, o, p, n, s, a, h)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserNotificationSettingsWithHttpInfo(e)));
    }));
  }
  listUserNotificationSettings(e, t, r, i, o, p, n, s, a, h) {
    return this.listUserNotificationSettingsWithHttpInfo(e, t, r, i, o, p, n, s, a, h).pipe(map(e => e.data));
  }
  listUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return from(this.requestFactory.listUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserProfileWithHttpInfo(e)));
    }));
  }
  listUserProfile(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we) {
    return this.listUserProfileWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l, c, u, g, M, P, I, W, y, H, A, w, R, S, T, F, j, C, U, v, q, D, O, z, N, b, k, L, x, E, G, V, B, J, K, Q, X, Y, Z, $, _, ee, te, re, ie, oe, pe, ne, se, ae, he, de, fe, me, le, ce, ue, ge, Me, Pe, Ie, We, ye, He, Ae, we).pipe(map(e => e.data));
  }
  listUserTableSortWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l) {
    return from(this.requestFactory.listUserTableSort(e, t, r, i, o, p, n, s, a, h, d, f, m, l)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listUserTableSortWithHttpInfo(e)));
    }));
  }
  listUserTableSort(e, t, r, i, o, p, n, s, a, h, d, f, m, l) {
    return this.listUserTableSortWithHttpInfo(e, t, r, i, o, p, n, s, a, h, d, f, m, l).pipe(map(e => e.data));
  }
  setNotificationSettingWithHttpInfo(e, t, r) {
    return from(this.requestFactory.setNotificationSetting(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setNotificationSettingWithHttpInfo(e)));
    }));
  }
  setNotificationSetting(e, t, r) {
    return this.setNotificationSettingWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  storeUserTableSortWithHttpInfo(e, t, r) {
    return from(this.requestFactory.storeUserTableSort(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.storeUserTableSortWithHttpInfo(e)));
    }));
  }
  storeUserTableSort(e, t, r) {
    return this.storeUserTableSortWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  updateMyProfileWithHttpInfo(e, t, r) {
    return from(this.requestFactory.updateMyProfile(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateMyProfileWithHttpInfo(e)));
    }));
  }
  updateMyProfile(e, t, r) {
    return this.updateMyProfileWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  updateMyProfilePictureWithHttpInfo(e, t, r) {
    return from(this.requestFactory.updateMyProfilePicture(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateMyProfilePictureWithHttpInfo(e)));
    }));
  }
  updateMyProfilePicture(e, t, r) {
    return this.updateMyProfilePictureWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  updateUserProfileWithHttpInfo(e, t, r) {
    return from(this.requestFactory.updateUserProfile(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateUserProfileWithHttpInfo(e)));
    }));
  }
  updateUserProfile(e, t, r) {
    return this.updateUserProfileWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  updateUserProfilePictureWithHttpInfo(e, t, r) {
    return from(this.requestFactory.updateUserProfilePicture(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.updateUserProfilePictureWithHttpInfo(e)));
    }));
  }
  updateUserProfilePicture(e, t, r) {
    return this.updateUserProfilePictureWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}
import { ValidationRulesManagementApiRequestFactory, ValidationRulesManagementApiResponseProcessor } from "../apis/ValidationRulesManagementApi";
export class ObservableValidationRulesManagementApi {
  constructor(e, t, r) {
    this.configuration = e, this.requestFactory = t || new ValidationRulesManagementApiRequestFactory(e), this.responseProcessor = r || new ValidationRulesManagementApiResponseProcessor();
  }
  getValidationRuleWithHttpInfo(e, t) {
    return from(this.requestFactory.getValidationRule(e, t)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.getValidationRuleWithHttpInfo(e)));
    }));
  }
  getValidationRule(e, t) {
    return this.getValidationRuleWithHttpInfo(e, t).pipe(map(e => e.data));
  }
  listValidationRuleWithHttpInfo(e, t, r, i, o, p, n, s, a, h) {
    return from(this.requestFactory.listValidationRule(e, t, r, i, o, p, n, s, a, h)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.listValidationRuleWithHttpInfo(e)));
    }));
  }
  listValidationRule(e, t, r, i, o, p, n, s, a, h) {
    return this.listValidationRuleWithHttpInfo(e, t, r, i, o, p, n, s, a, h).pipe(map(e => e.data));
  }
  removeValidationRuleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.removeValidationRule(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.removeValidationRuleWithHttpInfo(e)));
    }));
  }
  removeValidationRule(e, t, r) {
    return this.removeValidationRuleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  setValidationRuleWithHttpInfo(e, t, r) {
    return from(this.requestFactory.setValidationRule(e, t, r)).pipe(this.applyPreMiddlewares(), mergeMap(e => this.configuration.httpApi.send(e)), mergeMap(e => {
      let t = of(e);
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.post(e)));
      return t.pipe(mergeMap(e => this.responseProcessor.setValidationRuleWithHttpInfo(e)));
    }));
  }
  setValidationRule(e, t, r) {
    return this.setValidationRuleWithHttpInfo(e, t, r).pipe(map(e => e.data));
  }
  applyPreMiddlewares() {
    return e => {
      let t = e;
      for (let r of this.configuration.middleware) t = t.pipe(mergeMap(e => r.pre(e)));
      return t;
    };
  }
}