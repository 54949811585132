export class AddExternalSystemReferenceToDirectoryLocation {
  static getAttributeTypeMap() {
    return AddExternalSystemReferenceToDirectoryLocation.attributeTypeMap;
  }
  constructor(e, t, r, a) {
    this.organizationId = e, this.locationId = t, this.referenceType = r, this.referenceNumber = a;
  }
}
AddExternalSystemReferenceToDirectoryLocation.discriminator = void 0, AddExternalSystemReferenceToDirectoryLocation.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "referenceType",
  baseName: "referenceType",
  type: "string",
  format: ""
}, {
  name: "referenceNumber",
  baseName: "referenceNumber",
  type: "string",
  format: ""
}];