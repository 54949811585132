export class ShipmentContactPerson {
  static getAttributeTypeMap() {
    return ShipmentContactPerson.attributeTypeMap;
  }
  constructor(e, t, a, s, i, m, n) {
    this.id = e, this.assignedUserId = t, this.firstName = a, this.lastName = s, this.jobTitle = i, this.email = m, this.phone = n;
  }
}
ShipmentContactPerson.discriminator = void 0, ShipmentContactPerson.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "assignedUserId",
  baseName: "assignedUserId",
  type: "string",
  format: ""
}, {
  name: "firstName",
  baseName: "firstName",
  type: "string",
  format: ""
}, {
  name: "lastName",
  baseName: "lastName",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}];