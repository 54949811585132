export class RemoveShipmentLoadingLocation {
  static getAttributeTypeMap() {
    return RemoveShipmentLoadingLocation.attributeTypeMap;
  }
  constructor(t) {
    this.nvxId = t;
  }
}
RemoveShipmentLoadingLocation.discriminator = void 0, RemoveShipmentLoadingLocation.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];