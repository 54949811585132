export class AssignApplicationRole {
  static getAttributeTypeMap() {
    return AssignApplicationRole.attributeTypeMap;
  }
  constructor(t, e) {
    this.sub = t, this.role = e;
  }
}
AssignApplicationRole.discriminator = void 0, AssignApplicationRole.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}, {
  name: "role",
  baseName: "role",
  type: "string",
  format: ""
}];