export class AdminAddressReadModel {
  static getAttributeTypeMap() {
    return AdminAddressReadModel.attributeTypeMap;
  }
  constructor(e, t, a, s, r, d, i, n, m, o, b, p, y, g, f, c, l, u, h, N, A, I, R, v) {
    this.id = e, this.addressId = t, this.userSub = a, this.address1 = s, this.address2 = r, this.zip = d, this.city = i, this.area = n, this.country = m, this.state = o, this.notes = b, this.label = p, this.addressState = y, this.isActive = g, this.deletedAt = f, this.deleteReason = c, this.tenantId = l, this.validationError = u, this.coordinates = h, this.suggestedAddresses = N, this.tmsReference = A, this.organizationId = I, this.locationId = R, this.createdAt = v;
  }
}
AdminAddressReadModel.discriminator = void 0, AdminAddressReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "addressId",
  baseName: "addressId",
  type: "string",
  format: "uuid"
}, {
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "address1",
  baseName: "address1",
  type: "string",
  format: ""
}, {
  name: "address2",
  baseName: "address2",
  type: "string",
  format: ""
}, {
  name: "zip",
  baseName: "zip",
  type: "string",
  format: ""
}, {
  name: "city",
  baseName: "city",
  type: "string",
  format: ""
}, {
  name: "area",
  baseName: "area",
  type: "string",
  format: ""
}, {
  name: "country",
  baseName: "country",
  type: "string",
  format: ""
}, {
  name: "state",
  baseName: "state",
  type: "string",
  format: ""
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}, {
  name: "label",
  baseName: "label",
  type: "string",
  format: ""
}, {
  name: "addressState",
  baseName: "addressState",
  type: "string",
  format: ""
}, {
  name: "isActive",
  baseName: "isActive",
  type: "boolean",
  format: ""
}, {
  name: "deletedAt",
  baseName: "deletedAt",
  type: "Date",
  format: "date-time"
}, {
  name: "deleteReason",
  baseName: "deleteReason",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "validationError",
  baseName: "validationError",
  type: "ValidationDetails",
  format: ""
}, {
  name: "coordinates",
  baseName: "coordinates",
  type: "Position",
  format: ""
}, {
  name: "suggestedAddresses",
  baseName: "suggestedAddresses",
  type: "Array<SuggestedAddress>",
  format: ""
}, {
  name: "tmsReference",
  baseName: "tmsReference",
  type: "string",
  format: ""
}, {
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}, {
  name: "createdAt",
  baseName: "createdAt",
  type: "Date",
  format: "date-time"
}];