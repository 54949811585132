export class ShipmentPriorityReadModel {
  static getAttributeTypeMap() {
    return ShipmentPriorityReadModel.attributeTypeMap;
  }
  constructor(e, t, i, r, a, n, s) {
    this.id = e, this.shipmentReferenceId = t, this.shipmentType = i, this.priorityLevel = r, this.notes = a, this.tenantId = n, this.notesHistory = s;
  }
}
ShipmentPriorityReadModel.discriminator = void 0, ShipmentPriorityReadModel.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: ""
}, {
  name: "shipmentReferenceId",
  baseName: "shipmentReferenceId",
  type: "string",
  format: ""
}, {
  name: "shipmentType",
  baseName: "shipmentType",
  type: "string",
  format: ""
}, {
  name: "priorityLevel",
  baseName: "priorityLevel",
  type: "number",
  format: "int32"
}, {
  name: "notes",
  baseName: "notes",
  type: "string",
  format: ""
}, {
  name: "tenantId",
  baseName: "tenantId",
  type: "string",
  format: "uuid"
}, {
  name: "notesHistory",
  baseName: "notesHistory",
  type: "Array<ShipmentPriorityNoteHistoryEntry>",
  format: ""
}];