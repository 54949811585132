export class RestoreOrganization {
  static getAttributeTypeMap() {
    return RestoreOrganization.attributeTypeMap;
  }
  constructor(t) {
    this.id = t;
  }
}
RestoreOrganization.discriminator = void 0, RestoreOrganization.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}];