export class CompleteShipmentDraft {
  static getAttributeTypeMap() {
    return CompleteShipmentDraft.attributeTypeMap;
  }
  constructor(t) {
    this.nvxId = t;
  }
}
CompleteShipmentDraft.discriminator = void 0, CompleteShipmentDraft.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}];