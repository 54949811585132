export class AddCustomerReferenceToShipment {
  static getAttributeTypeMap() {
    return AddCustomerReferenceToShipment.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.customerReference = t;
  }
}
AddCustomerReferenceToShipment.discriminator = void 0, AddCustomerReferenceToShipment.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "customerReference",
  baseName: "customerReference",
  type: "string",
  format: ""
}];