export class LeaveChatChannel {
  static getAttributeTypeMap() {
    return LeaveChatChannel.attributeTypeMap;
  }
  constructor(e) {
    this.channelId = e;
  }
}
LeaveChatChannel.discriminator = void 0, LeaveChatChannel.attributeTypeMap = [{
  name: "channelId",
  baseName: "channelId",
  type: "string",
  format: "uuid"
}];