export class RejectAddress {
  static getAttributeTypeMap() {
    return RejectAddress.attributeTypeMap;
  }
  constructor(e, t, s) {
    this.id = e, this.reason = t, this.rejectionReasons = s;
  }
}
RejectAddress.discriminator = void 0, RejectAddress.attributeTypeMap = [{
  name: "id",
  baseName: "id",
  type: "string",
  format: "uuid"
}, {
  name: "reason",
  baseName: "reason",
  type: "string",
  format: ""
}, {
  name: "rejectionReasons",
  baseName: "rejectionReasons",
  type: "Array<string>",
  format: ""
}];