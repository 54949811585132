export class SetShipmentCargoReadyDate {
  static getAttributeTypeMap() {
    return SetShipmentCargoReadyDate.attributeTypeMap;
  }
  constructor(e, t) {
    this.nvxId = e, this.cargoReadyDate = t;
  }
}
SetShipmentCargoReadyDate.discriminator = void 0, SetShipmentCargoReadyDate.attributeTypeMap = [{
  name: "nvxId",
  baseName: "nvxId",
  type: "string",
  format: ""
}, {
  name: "cargoReadyDate",
  baseName: "cargoReadyDate",
  type: "Date",
  format: "date-time"
}];