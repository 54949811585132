var __awaiter = this && this.__awaiter || function (t, e, r, s) {
  function o(t) {
    return t instanceof r ? t : new r(function (e) {
      e(t);
    });
  }
  return new (r || (r = Promise))(function (r, i) {
    function n(t) {
      try {
        h(s.next(t));
      } catch (e) {
        i(e);
      }
    }
    function a(t) {
      try {
        h(s["throw"](t));
      } catch (e) {
        i(e);
      }
    }
    function h(t) {
      t.done ? r(t.value) : o(t.value).then(n, a);
    }
    h((s = s.apply(t, e || [])).next());
  });
};
import { from } from "rxjs";
export * from "./isomorphic-fetch";
export var HttpMethod;
(function (t) {
  t["GET"] = "GET", t["HEAD"] = "HEAD", t["POST"] = "POST", t["PUT"] = "PUT", t["DELETE"] = "DELETE", t["CONNECT"] = "CONNECT", t["OPTIONS"] = "OPTIONS", t["TRACE"] = "TRACE", t["PATCH"] = "PATCH";
})(HttpMethod || (HttpMethod = {}));
export class HttpException extends Error {
  constructor(t) {
    super(t);
  }
}
function ensureAbsoluteUrl(t) {
  return t.startsWith("http://") || t.startsWith("https://") ? t : window.location.origin + t;
}
export class RequestContext {
  constructor(t, e) {
    this.httpMethod = e, this.headers = {}, this.body = void 0, this.url = new URL(ensureAbsoluteUrl(t));
  }
  getUrl() {
    return this.url.toString().endsWith("/") ? this.url.toString().slice(0, -1) : this.url.toString();
  }
  setUrl(t) {
    this.url = new URL(ensureAbsoluteUrl(t));
  }
  setBody(t) {
    this.body = t;
  }
  getHttpMethod() {
    return this.httpMethod;
  }
  getHeaders() {
    return this.headers;
  }
  getBody() {
    return this.body;
  }
  setQueryParam(t, e) {
    this.url.searchParams.set(t, e);
  }
  addCookie(t, e) {
    this.headers["Cookie"] || (this.headers["Cookie"] = ""), this.headers["Cookie"] += t + "=" + e + "; ";
  }
  setHeaderParam(t, e) {
    this.headers[t] = e;
  }
}
export class SelfDecodingBody {
  constructor(t) {
    this.dataSource = t;
  }
  binary() {
    return this.dataSource;
  }
  text() {
    return __awaiter(this, void 0, void 0, function* () {
      const t = yield this.dataSource;
      return t.text ? t.text() : new Promise((e, r) => {
        const s = new FileReader();
        s.addEventListener("load", () => e(s.result)), s.addEventListener("error", () => r(s.error)), s.readAsText(t);
      });
    });
  }
}
export class ResponseContext {
  constructor(t, e, r) {
    this.httpStatusCode = t, this.headers = e, this.body = r;
  }
  getParsedHeader(t) {
    const e = {};
    if (!this.headers[t]) return e;
    const r = this.headers[t].split(";");
    for (const s of r) {
      let [t, r] = s.split("=", 2);
      t = t.toLowerCase().trim(), void 0 === r ? e[""] = t : (r = r.trim(), r.startsWith('"') && r.endsWith('"') && (r = r.substring(1, r.length - 1)), e[t] = r);
    }
    return e;
  }
  getBodyAsFile() {
    return __awaiter(this, void 0, void 0, function* () {
      const t = yield this.body.binary(),
        e = this.getParsedHeader("content-disposition")["filename"] || "",
        r = this.headers["content-type"] || "";
      try {
        return new File([t], e, {
          type: r
        });
      } catch (s) {
        return Object.assign(t, {
          name: e,
          type: r
        });
      }
    });
  }
  getBodyAsAny() {
    try {
      return this.body.text();
    } catch (t) {}
    try {
      return this.body.binary();
    } catch (e) {}
    return Promise.resolve(void 0);
  }
}
export function wrapHttpLibrary(t) {
  return {
    send(e) {
      return from(t.send(e));
    }
  };
}
export class HttpInfo extends ResponseContext {
  constructor(t, e, r, s) {
    super(t, e, r), this.httpStatusCode = t, this.headers = e, this.body = r, this.data = s;
  }
}