export class UpdateUserProfile {
  static getAttributeTypeMap() {
    return UpdateUserProfile.attributeTypeMap;
  }
  constructor(e, a, t, i, r, n, s, m, o, p, f, b, d, l, u, y, h, g) {
    this.userSub = e, this.firstname = a, this.lastname = t, this.salutation = i, this.jobTitle = r, this.email = n, this.mobilePhone = s, this.officePhone = m, this.primaryWorkLocation = o, this.linkedIn = p, this.timeZone = f, this.language = b, this.birthday = d, this.about = l, this.primaryTWMUserId = u, this.secondaryTWMUserId = y, this.defaultApplicationTenantId = h, this.defaultCustomerTenantId = g;
  }
}
UpdateUserProfile.discriminator = void 0, UpdateUserProfile.attributeTypeMap = [{
  name: "userSub",
  baseName: "userSub",
  type: "string",
  format: ""
}, {
  name: "firstname",
  baseName: "firstname",
  type: "string",
  format: ""
}, {
  name: "lastname",
  baseName: "lastname",
  type: "string",
  format: ""
}, {
  name: "salutation",
  baseName: "salutation",
  type: "string",
  format: ""
}, {
  name: "jobTitle",
  baseName: "jobTitle",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "mobilePhone",
  baseName: "mobilePhone",
  type: "string",
  format: ""
}, {
  name: "officePhone",
  baseName: "officePhone",
  type: "string",
  format: ""
}, {
  name: "primaryWorkLocation",
  baseName: "primaryWorkLocation",
  type: "string",
  format: ""
}, {
  name: "linkedIn",
  baseName: "linkedIn",
  type: "string",
  format: ""
}, {
  name: "timeZone",
  baseName: "timeZone",
  type: "string",
  format: ""
}, {
  name: "language",
  baseName: "language",
  type: "string",
  format: ""
}, {
  name: "birthday",
  baseName: "birthday",
  type: "Date",
  format: "date-time"
}, {
  name: "about",
  baseName: "about",
  type: "string",
  format: ""
}, {
  name: "primaryTWMUserId",
  baseName: "primaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "secondaryTWMUserId",
  baseName: "secondaryTWMUserId",
  type: "string",
  format: ""
}, {
  name: "defaultApplicationTenantId",
  baseName: "defaultApplicationTenantId",
  type: "string",
  format: "uuid"
}, {
  name: "defaultCustomerTenantId",
  baseName: "defaultCustomerTenantId",
  type: "string",
  format: "uuid"
}];