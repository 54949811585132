export class RemoveLocationExternalSystemReferences {
  static getAttributeTypeMap() {
    return RemoveLocationExternalSystemReferences.attributeTypeMap;
  }
  constructor(e, t) {
    this.organizationId = e, this.locationId = t;
  }
}
RemoveLocationExternalSystemReferences.discriminator = void 0, RemoveLocationExternalSystemReferences.attributeTypeMap = [{
  name: "organizationId",
  baseName: "organizationId",
  type: "string",
  format: "uuid"
}, {
  name: "locationId",
  baseName: "locationId",
  type: "string",
  format: "uuid"
}];