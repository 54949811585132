export class AddToTenant {
  static getAttributeTypeMap() {
    return AddToTenant.attributeTypeMap;
  }
  constructor(t) {
    this.sub = t;
  }
}
AddToTenant.discriminator = void 0, AddToTenant.attributeTypeMap = [{
  name: "sub",
  baseName: "sub",
  type: "string",
  format: ""
}];