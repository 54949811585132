import { PromiseMiddlewareWrapper } from "./middleware";
import { IsomorphicFetchHttpLibrary as DefaultHttpLibrary } from "./http/isomorphic-fetch";
import { server1 } from "./servers";
import { configureAuthMethods } from "./auth/auth";
export function createConfiguration(e = {}) {
  const r = {
    baseServer: void 0 !== e.baseServer ? e.baseServer : server1,
    httpApi: e.httpApi || new DefaultHttpLibrary(),
    middleware: e.middleware || [],
    authMethods: configureAuthMethods(e.authMethods)
  };
  return e.promiseMiddleware && e.promiseMiddleware.forEach(e => r.middleware.push(new PromiseMiddlewareWrapper(e))), r;
}