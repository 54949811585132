import { Injectable } from '@angular/core';

import { environment } from '@/shared/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  log(message?: unknown, ...optionalParams: unknown[]) {
    if (!environment.production) {
      console.log(message, optionalParams);
    }
  }

  warn(message?: unknown, ...optionalParams: unknown[]) {
    if (!environment.production) {
      console.warn(message, optionalParams);
    }
  }

  error(message?: unknown, ...optionalParams: unknown[]) {
    console.error(message, optionalParams);
  }

  table(tabularData?: unknown) {
    if (!environment.production) {
      console.table(tabularData);
    }
  }
}
